<template>
  <div class="city-gdp">
    <p class="title">主要海洋产业产值区域分布</p>
    <swiper ref="mySwiper"
            :options="swiperOptions"
            class="swiperbox swiper-no-swiping">
      <swiper-slide v-for="(item,index) in years"
                    :key="index">
        <div :id="'myChartclass' + item.value"
             class="myChartclass"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import api from "@/util/api";
import Highcharts from "highcharts/highstock";
import Highcharts3D from "highcharts/highcharts-3d";
Highcharts3D(Highcharts);
export default {
  name: "classify_of_type",
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper;
    }
  },
  props: {
    year: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    years: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    years (val) {
      this.$nextTick(() => {
        this.loadchartdata();
      })
    },
    index (val) {
      this.chartLoop()
    },
    year (val) {
      this.$refs.mySwiper.swiper.slideTo(this.index)
    }
  },
  data () {
    return {
      chartData: [],
      timer2: null,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination"
        },
        loop: false,
        initialSlide: 0,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true //修改swiper的父元素时，自动初始化swiper
        // autoplay: true, //可设置数值来指定播放速度
        // speed: 900 // 切换图片速度
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true
        // }
      }
    };
  },
  mounted () {

  },
  methods: {
    chartLoop () {
      this.$refs.mySwiper.swiper.slideTo(this.index)
    },
    initchart (value) {
      var that = this;
      var options = {
        chart: {
          type: "pie",
          backgroundColor: "rgba(0,0,0,0)",
          options3d: {
            enabled: true,
            alpha: 45
          },
          margin: [0, 200, 10, 0]     //!!!!!
        },
        legend: {
          enabled: true,
          align: "right",
          verticalAlign: "center",
          layout: "vertical",
          x: -10,
          y: 0,
          itemStyle: {
            color: "#fff",
            fontSize: '18px',
            fontWeight: ''
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "",
          x: -100,
          y: 55,
          style: { color: "#ffffff", fontSize: "26px" },
          verticalAlign: 'bottom',//!!!
        },
        // subtitle: {
        //   text: "Highcharts 中的3D环形图"
        // },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
            dataLabels: {
              enabled: false
            },
            showInLegend: true, //重点图例显示
            colors: [
              "#00ffff",
              "#deab8a",
              "#006ced",
              "#ffe000",
              "#afb4db",
              "#65c294",
              "#ef5b9c",
              "#BDB76B",
              "#00D9FF",
              "#e0861a",
              "#DD5526",
              "#FFBB00",
              "#FFA6A6",
              "#A7FFA6",
              "#8D85B6",
              "#6464FF",
              "#90EE90",
              "#FF7F50",
              "#BDB76B",
              "#DA70D6",
              "#7B68EE"
            ]
          },
          series: {
            events: {
              //控制图标的图例legend不允许切换
              legendItemClick: function (event) {
                return false; //return  true 则表示允许切换
              }
            }
          }
          // series: {
          //   events: {
          //     //控制图标的图例legend不允许切换
          //     legendItemClick: function(event) {
          //       return false; //return  true 则表示允许切换
          //     }
          //   }
          // }
        },
        tooltip: {
          headerFormat: "{point.key}: <br/>",
          pointFormat: "{point.y} 亿元({point.percentage:.2f}%)",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          style: {
            color: "#fff",
            fontSize: '20px'
          },
          borderwidth: 0
        },
        series: [
          {
            name: "",

            // data: [
            //   ["香蕉", 8],
            //   ["猕猴桃", 3],
            //   ["桃子", 1],
            //   ["橘子", 6],
            //   ["苹果", 8],
            //   ["梨", 4],
            //   ["柑橘", 4],
            //   ["橙子", 1],
            //   ["葡萄 (串)", 1]
            // ]
            data: null
          }
        ]
      };
      const promise = new Promise((resolve, reject) => {
        this.chartData.forEach(chart => {
          setTimeout(() => {
            this['highchart' + chart[0].year] = Highcharts.chart("myChartclass" + chart[0].year, options);
            this['highchart' + chart[0].year].update({
              title: {
                text: chart[0].year + "年"
              },
              series: [
                {
                  data: chart
                }
              ]
            });
            resolve(true)
          }, 0)
        })
      })
      promise.then(res => {
        if (res) {
          var i = 0;
          setInterval(() => {
            if (i < 14) {
              this.chartData.forEach(chart => {
                this['highchart' + chart[0].year].tooltip.refresh(this['highchart' + chart[0].year].series[0].data[i]);
              })
              i++;
            } else {
              i = 0;
            }
          }, 4000);
        }

      })


    },
    loadchartdata () {
      this.$axios.post(api.cantonal).then(res => {
        const resultData = res.data.result
        const citys = resultData.city
        delete resultData.city
        this.chartData = []
        for (let i in resultData) {
          const chartData = citys.map((c, idx) => {
            return {
              name: c,
              year: i.replace('value', ''),
              y: Number(resultData[i][idx])
            }
          })
          this.chartData.push(chartData)
        }

        this.initchart();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// #myChartclass16 {
//   width: 100%;
//   height: 100%;
// }
.myChartclass {
  width: 100%;
  height: 100%;
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
.city-gdp {
  height: 32%;
  width: 100%;
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  // height: calc(31% - 85px);
}
.swiperbox {
  height: 300px;
}
</style>
