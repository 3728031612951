<!-- 企业统计信息一览一览 -->
<template>
  <div class="mainbox">
    <transition
      leave-active-class="animate__animated animate__fadeOutLeft"
      enter-active-class="animate__animated animate__fadeInLeft"
      mode="out-in"
    >
      <!-- 渔船详情 -->

      <!-- 渔港详情 -->

      <!-- 企业详情 -->
      <div class="main" v-if="project_show">
        <!-- 标题 -->
        <div class="header">
          涉海企业统计信息一览
          <!-- <div class="close">x</div> -->
        </div>
        <div class="header-bottom">
          全市规上企业 <em class="em">{{ tabledatalen }}</em
          >家
        </div>
        <!-- 图表标题 -->
        <div class="chartbox">
          <div class="title">
            <i class="iconfont icon-fangkuang"></i>
            各区市规上企业数量
          </div>
          <!-- 图表 -->
          <div
            class="Echartbox"
            ref="Echartbox3"
            id="Echartbox3"
            v-if="project_show"
          ></div>
        </div>
        <div class="chartbottom">
          <div class="title">
            <i class="iconfont icon-fangkuang"></i>
            各产业规上企业数量
          </div>
          <div class="bottom-nav">
            <!-- <el-table
              height="408px"
              :header-cell-style="{
                backgroundColor: 'transparent',
                color: '#fff',
                fontSize: '15px',
                textAlign: 'center'
              }"
              :cell-style="{
                color: '#fff',
                backgroundColor: 'transparent',
                fontSize: '13px',
                textAlign: 'center'
              }"
              :row-style="{
                color: '#fff',
                backgroundColor: 'transparent',
                fontSize: '13px',
                textAlign: 'center'
              }"
              :data="tableData"
              style="width: 100%"
            >
              <el-table-column prop="name" label="企业名称"> </el-table-column>
              <el-table-column prop="location" label="企业位置">
              </el-table-column>
              <el-table-column prop="createTime" label="成立时间">
              </el-table-column>
            </el-table> -->
            <AES></AES>
          </div>
        </div>
      </div>
      <!-- 科研机构详情 -->
    </transition>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
// import "./all_month.js";
import { ClusterLayer } from "maptalks.markercluster";
import "maptalks.animatemarker/dist/maptalks.animatemarker.min.js";
import { AnimateMarkerLayer } from "maptalks.animatemarker";
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";
import api from "@/util/api";
import aes from "../chart-right/annual-economic-share/annual-economic-share";
export default {
  components: {
    AES: aes
  },
  data() {
    return {
      // 企业图层
      enterpriselayer: null,
      // 渔港图层
      fishingpoatlayer: null,
      // 渔船图层
      fishingboatlayer: null,
      // 图表配置
      options: {
        legend: {
          show: true,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          data: this.shipdataname,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)"
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        series: [
          {
            name: "2021",
            data: this.shipdatavalue,
            type: "bar",
            barWidth: 13
          }
        ]
      },

      // table列表配置
      tableData: [
        {
          createTime: "2021-11-27 14:36:30",
          name: "山东中交航务工程有限公司",
          location: "芝罘区"
        },
        {
          createTime: "2021-11-27 14:36:30",
          name: "蓬莱中柏京鲁船业有限公司",
          location: "蓬莱市"
        },
        {
          createTime: "2021-11-27 14:36:30",
          name: "烟台交运集团莱阳运输有限公司",
          location: "莱阳市"
        }
      ],
      shipdata: [
        {
          createTime: "2016-05-02",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-04",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-03",
          name: "鲁烟渔22441",
          location: "烟台市海里"
        }
      ],
      shipport: [
        {
          createTime: "2016-05-02",
          name: "1号渔港",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-04",
          name: "2号渔港",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "3号渔港",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "4号渔港",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-01",
          name: "5号渔港",
          location: "烟台市海里"
        },
        {
          createTime: "2016-05-03",
          name: "6号渔港",
          location: "烟台市海里"
        }
      ],
      postableData: [],
      tabledatalen: "",
      // isShow: ["enterprise"],
      isShow: ["enterprise", "port_show", "project_show", "scientific"],
      project_show: false,
      port_show: false,
      // 渔船
      enterprise: false,
      scientific: false,
      //科研机构
      scientificlist: [],
      // 渔船城市
      shipdataname: [],
      // 渔船产值
      shipdatavalue: [],
      // 科研机构城市
      scientificname: [],
      // 科研机构产值
      scientificvalue: [],
      // 渔港城市
      fishingportname: [],
      // 渔港产值
      fishingportvalue: [],
      // 企业信息城市
      enterprisename: [],
      // 企业信息产值
      enterprisevalue: [],
      markerposition: [],
      clusterLayer: "",
      markers: []
    };
  },
  mounted() {
    this.getEnterpriseinfomation();
    this.addFishingport();
    this.addFishingboat();
    this.addscientific();
    // var that = this;
    // this.$nextTick(() => {
    //   that.drawLine();
    // });
    this.$nextTick(() => {
      this.drawLine();
      this.drawLine2();
      this.drawLine3();
      this.drawLine4();
    });
  },
  destroyed() {},
  watch: {
    project_show(newval, val) {
      if (newval) {
        this.$nextTick(() => {
          this.drawLine3();
        });
      }
    },
    port_show(newval, val) {
      if (newval) {
        this.$nextTick(() => {
          this.drawLine2();
        });
      }
    },
    enterprise(newval, val) {
      if (newval) {
        this.$nextTick(() => {
          this.drawLine();
        });
      }
    },
    scientific(newval, val) {
      if (newval) {
        this.$nextTick(() => {
          this.drawLine4();
        });
      }
    }
  },
  updated() {
    // this.$nextTick(function() {
    //   this.drawLine();
    // })
    // this.drawLine2();
    // this.drawLine3();
    // this.drawLine4();
  },
  methods: {
    // 获取企业信息
    getEnterpriseinfomation(param, value) {
      if (window.$app.layers.base_enterprise) {
        window.$app.layers.base_enterprise.clear();
      }

      const params = new FormData();
      if (param) {
        params.append("types", param.toString());
      }
      // 顺着坐下去，创建一个formdate，请求接口，来控制，bottom-label的逻辑重新写，，
      this.$axios.post(api.query_enterprise, params).then(res => {
        let _r = res.data.result;
        this.tableData = _r.list;
        if (!this.tabledatalen) {
          this.tabledatalen = _r.list.length;
        }
        this.postableData = _r.list;
        this.addEnterprise(param);
        this.enterprisename = _r.name;
        this.enterprisevalue = _r.value;
      });
    },
    // 渲染企业图层到地图
    addEnterprise(param) {
      this.enterpriselayer = window.$app.initVectorLayer("enterprise", "1");
      const postableData = this.postableData;
      (this.markerposition = []),
        postableData.forEach(item => {
          let x = item.longitude;
          let y = item.latitude;
          // const layer = window.$app.initVectorLayer("enterprise", 1)
          if (x != null && y != null) {
            // var point = new maptalks.Marker([x, y], {
            // visible: true,
            // editable: true,
            // cursor: "pointer",
            // shadowBlur: 0,
            // shadowColor: "black",
            // draggable: false,
            // dragShadow: false, // display a shadow during dragging
            // drawOnAxis: null, // force dragging stick on a axis, can be: x, y
            // symbol: [
            //   {
            //     textFaceName: "sans-serif",
            //     textName: item.name, //value from name in geometry's properties
            //     textWeight: "normal", //'bold', 'bolder'
            //     textStyle: "normal", //'italic', 'oblique'
            //     textSize: 16,
            //     textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
            //     textFill: "#34495e",
            //     textOpacity: 1,
            //     textHaloFill: "#fff",
            //     textHaloRadius: 2,
            //     textWrapWidth: null,
            //     textWrapCharacter: "\n",
            //     textLineSpacing: 0,
            //     textDx: 0,
            //     textDy: 0,
            //     textHorizontalAlignment: "middle", //left | middle | right | auto
            //     textVerticalAlignment: "middle", // top | middle | bottom | auto
            //     textAlign: "center" //left | right | center | auto
            //   },
            //   {
            //     markerFile: require("./img/Enterprise.png"),
            //     markerWidth: 28,
            //     markerHeight: 40
            //   }
            // ]
            // }).addTo(this.enterpriselayer);
            // point.onclick = function() {
            //   alert("这是第一种点击方式");
            // };
            // point.setInfoWindow({
            //   title: "企业信息",
            //   content: `<div class='pop_dept'><p><span>企业名称:</span>${item.name}</p></div>
            //   <div class='pop_dept'><p><span>企业类型:</span>${item.type}</p></div>
            //   <div class='pop_dept'><p><span>企业位置:</span>${item.location}</p></div><div class='pop_dept'><p><span>经营范围:</span>${item.operationalActivities}</p></div>`
            // });
          }
          if ((x, y)) {
            let position = {
              latitude: x,
              longitude: y,
              name: item.name,
              type: item.type,
              location: item.location,
              type: item.type,
              operationalActivities: item.operationalActivities
            };

            this.markerposition.push(position);
          }
        });
      // this.enterpriselayer.hide()
      // console.log(this.markerposition,'position');
      this.markInfo(this.markerposition, param);
      // this.markeranimate(this.markerposition);
    },

    // 图标
    drawLine() {
      var chartdom = this.$refs.Echartbox;
      var options = {
        legend: {
          show: false,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          data: this.shipdataname,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)",
            textStyle: {
              fontSize: 20,
              color: "#C6E0F2"
            }
          }
          // axisLabel: {
          //     margin: 24,
          //     show: true,
          //     // interval: 0, //显示所有X轴信息
          //     rotate: 0, //倾斜角度
          //     textStyle: {
          //       fontSize: 16,
          //       color: "#C6E0F2",
          //     }
          //   }
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "rgba(255, 255, 255, 1)" }
        },
        series: [
          {
            name: "2021",
            data: this.shipdatavalue,
            type: "bar",
            barWidth: 13
          }
        ]
      };
      if (chartdom) {
        var myChart = this.$echarts.init(chartdom);

        myChart.setOption(options);
      }
    },
    // 科研机构表格

    drawLine2() {
      var chartdom = this.$refs.Echartbox2;
      var options = {
        legend: {
          show: true,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          data: this.fishingportname,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)",
            textStyle: {
              fontSize: 20,
              color: "#C6E0F2"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "rgba(255, 255, 255, 1)" }
        },
        series: [
          {
            name: "2021",
            data: this.fishingportvalue,
            type: "bar",
            barWidth: 13
          }
        ]
      };
      if (chartdom) {
        var myChart = this.$echarts.init(chartdom);

        myChart.setOption(options);
      }
    },
    // 渔港表格

    drawLine3() {
      var chartdom = this.$refs.Echartbox3;
      var options = {
        tooltip: {
          trigger: "axis",
          formatter: "{b}: <br/>{c}家",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          show: false,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          top:10,
          data: this.enterprisename,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)"
          }
        },
        yAxis: {
          name: "家",
          nameTextStyle: {
            color: "#fff",
            fontSize: "12"
          },
          type: "value",
          axisLabel: { color: "rgba(255, 255, 255, 1)" }
        },
        series: [
          {
            name: "2021",
            data: this.enterprisevalue,
            type: "bar",
            barWidth: 13
          }
        ]
      };
      if (chartdom) {
        var myChart = this.$echarts.init(chartdom);

        myChart.setOption(options);
      }
    },
    // 企业图表

    drawLine4() {
      var chartdom = this.$refs.Echartbox4;
      var options = {
        legend: {
          show: true,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          data: this.scientificname,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)"
          }
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "rgba(255, 255, 255, 1)" }
        },
        series: [
          {
            name: "2021",
            data: this.scientificvalue,
            type: "bar",
            barWidth: 13
          }
        ]
      };
      if (chartdom) {
        var myChart = this.$echarts.init(chartdom);

        myChart.setOption(options);
      }
    },

    // 渲染渔港到地图
    addFishingport() {
      this.$axios.post(api.fishing_port).then(res => {
        this.fishingpoatlayer = window.$app.initVectorLayer("FP", 1);
        this.shipport = res.data.result.list;
        this.shipport.forEach(item => {
          let x = item.longitude;
          let y = item.latitude;
          let pos = item.location;
          let name = item.name;
          let createT = item.createTime;
          if (x && y) {
            var point = new maptalks.Marker([x, y], {
              visible: true,
              editable: true,
              cursor: "pointer",
              shadowBlur: 0,
              shadowColor: "black",
              draggable: false,
              dragShadow: false, // display a shadow during dragging
              drawOnAxis: null, // force dragging stick on a axis, can be: x, y
              symbol: [
                {
                  textFaceName: "sans-serif",
                  textName: item.name, //value from name in geometry's properties
                  textWeight: "normal", //'bold', 'bolder'
                  textStyle: "normal", //'italic', 'oblique'
                  textSize: 16,
                  textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
                  textFill: "#34495e",
                  textOpacity: 1,
                  textHaloFill: "#fff",
                  textHaloRadius: 2,
                  textWrapWidth: null,
                  textWrapCharacter: "\n",
                  textLineSpacing: 0,
                  textDx: 0,
                  textDy: 0,
                  textHorizontalAlignment: "middle", //left | middle | right | auto
                  textVerticalAlignment: "middle", // top | middle | bottom | auto
                  textAlign: "center" //left | right | center | auto
                },
                {
                  markerFile: require("./img/FishPort.png"),
                  markerWidth: 28,
                  markerHeight: 40
                }
              ]
            }).addTo(this.fishingpoatlayer);
            point.setInfoWindow({
              title: "渔港信息",
              content: `<div class=''><p><span>名称</em>:</span><em class='pop_d'>${name}</em></p></div>
            <div class='pop_dept'><p><span>地址:</span><em class='pop_d'>${pos}</em></p></div>
            <div class='pop_dept'><p><span>创建时间:</span><em class='pop_d'>${createT}</em></p></div>`
            });
          }
        });
        this.fishingportname = res.data.result.name;
        this.fishingportvalue = res.data.result.value;
        this.fishingpoatlayer.hide();
      });
    },
    // 渲染渔船到地图
    addFishingboat() {
      this.$axios.post(api.fishing_boat).then(res => {
        this.fishingboatlayer = window.$app.initVectorLayer("FB", 1);
        this.shipdata = res.data.result.list;
        this.shipdataname = res.data.result.name;
        this.shipdatavalue = res.data.result.value;
        // setTimeout(() => {
        //   this.drawLine()
        // }, 10000);
        this.shipdata.forEach(item => {
          let x = item.longitude;
          let y = item.latitude;
          //var point = new maptalks.Marker([x, y], {
          //   visible: true,
          //   editable: true,
          //   cursor: "pointer",
          //   shadowBlur: 0,
          //   shadowColor: "black",
          //   draggable: false,
          //   dragShadow: false, // display a shadow during dragging
          //   drawOnAxis: null, // force dragging stick on a axis, can be: x, y
          //   symbol: [
          //     {
          //       textFaceName: "sans-serif",
          //       textName: item.name, //value from name in geometry's properties
          //       textWeight: "normal", //'bold', 'bolder'
          //       textStyle: "normal", //'italic', 'oblique'
          //       textSize: 16,
          //       textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
          //       textFill: "#34495e",
          //       textOpacity: 1,
          //       textHaloFill: "#fff",
          //       textHaloRadius: 2,
          //       textWrapWidth: null,
          //       textWrapCharacter: "\n",
          //       textLineSpacing: 0,
          //       textDx: 0,
          //       textDy: 0,
          //       textHorizontalAlignment: "middle", //left | middle | right | auto
          //       textVerticalAlignment: "middle", // top | middle | bottom | auto
          //       textAlign: "center" //left | right | center | auto
          //     },
          //     {
          //       markerFile: require("./img/Enterprise.png"),
          //       markerWidth: 28,
          //       markerHeight: 40
          //     }
          //   ]
          // }).addTo(this.fishingboatlayer);
        });
        this.fishingboatlayer.hide();
        // this.drawLine();
      });
    },
    // 科研机构
    addscientific() {
      this.$axios.post(api.scientific).then(res => {
        this.scientificlist = res.data.result.list;
        this.scientificname = res.data.result.name;
        this.scientificvalue = res.data.result.value;
      });
    },

    markInfo(position, param) {
      window.$app.map._layers.forEach(item => {});

      // window.$app.map.removeLayer(this.enterpriselayer);
      this.markers = [];
      for (let i = 0; i < position.length; i++) {
        let a = position[i];
        let markerP = new maptalks.Marker([a.latitude, a.longitude], {
          properties: {
            name: a.name,
            type: a.type,
            location: a.location,
            type: a.type,
            longitude: a.longitude,
            latitude: a.latitude,
            operationalActivities: a.operationalActivities
          },
          symbol: [
            {
              markerFile: a.onSale
                ? require("./img/Enterprise.png")
                : require("./img/Enterprise.png"), //标注点图标????
              markerWidth: 28,
              markerHeight: 40
            },
            {
              textFaceName: "sans-serif",
              textName: a.name, //value from name in geometry's properties
              textWeight: "normal", //'bold', 'bolder'
              textStyle: "normal", //'italic', 'oblique'
              textSize: 16,
              textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
              textFill: "#34495e",
              textOpacity: 1,
              textHaloFill: "#fff",
              textHaloRadius: 2,
              textWrapWidth: null,
              textWrapCharacter: "\n",
              textLineSpacing: 0,
              textDx: 0,
              textDy: 0,
              textHorizontalAlignment: "middle", //left | middle | right | auto
              textVerticalAlignment: "middle", // top | middle | bottom | auto
              textAlign: "center" //left | right | center | auto
            }
          ]
        }).on("mousedown", onClick);
        this.markers.push(markerP);
        // markerP.setInfoWindow({
        //   title: "企业信息",
        //   content: `<div class='pop_dept_ep'><p><span>企业名称:</span>${a.name}</p></div>
        //     <div class='pop_dept_ep'><p><span>企业类型:</span>${a.type}</p></div>
        //     <div class='pop_dept_ep'><p><span>企业位置:</span>${a.location}</p></div><div class='pop_dept_ep'><p><span>经营范围:</span>${a.operationalActivities}</p></div>`
        // });
      }
      let clusterLayer = new ClusterLayer("cluster", this.markers, {
        noClusterWithOneMarker: true,
        noClusterWithHowMany: 8, //聚合的最小个数
        maxClusterZoom: 15,
        // 聚合的样式
        // symbol:[
        //   {
        //     'markerType': 'ellipse',
        //     'markerFill': {
        //       'type': 'radial',
        //       'colorStops': [
        //         [0.00, 'rgba(22, 165, 255, 0.7)'],
        //         [0.2, 'rgba(22, 165, 255, 0.4)'],
        //         [0.5, 'rgba(22, 165, 255, 0)'],
        //         [1.00, 'rgba(22, 165, 255, 0)']
        //       ]
        //     },
        //     'markerWidth': 70,
        //     'markerHeight': 70,
        //     'markerLineWidth': 0
        //   },
        //   {
        //     'markerType': 'ellipse',
        //     'markerFill': {
        //       'type': 'radial',
        //       'colorStops': [
        //         [0.00, 'rgba(22, 165, 255, 0.7)'],
        //         [0.2, 'rgba(22, 165, 255, 0.4)'],
        //         [0.5, 'rgba(22, 165, 255, 0)'],
        //         [1.00, 'rgba(22, 165, 255, 0)']
        //       ]
        //     },
        //     'markerWidth': 40,
        //     'markerHeight': 40,
        //     'markerLineWidth': 0
        //   },
        //   {
        //     'markerType': 'ellipse',
        //     'markerFill': {
        //       'type': 'radial',
        //       'colorStops': [
        //         [0.00, 'rgba(22, 165, 255, 0.7)'],
        //         [0.2, 'rgba(22, 165, 255, 0.4)'],
        //         [0.5, 'rgba(22, 165, 255, 0)'],
        //         [1.00, 'rgba(22, 165, 255, 0)']
        //       ]
        //     },
        //     'markerWidth': 20,
        //     'markerHeight': 20,
        //     'markerLineWidth': 0
        //   },
        //   {
        //     'markerType': 'ellipse',
        //     'markerFill': {
        //       'type': 'radial',
        //       'colorStops': [
        //         [0.00, 'rgba(122, 65, 55, 0.7)'],
        //         [0.2, 'rgba(232, 16, 25, 0.4)'],
        //         [0.5, 'rgba(232, 165, 255, 0)'],
        //         [1.00, 'rgba(22, 165, 255, 0)']
        //       ]
        //     },
        //     'markerWidth': 0,
        //     'markerHeight': 0,
        //     'markerLineWidth': 0
        //   },
        //   // {
        //   //   'markerDx': 10,
        //   //   'markerDy': -12,
        //   //   'markerType': 'rectangle',
        //   //   'markerFill': 'rgba(255, 255, 255, 1)',
        //   //   'markerWidth': { stops: [[7, 70], [8, 0]] },
        //   //   'markerHeight': { stops: [[7, 24], [8, 0]] },
        //   //   'markerLineWidth': 0
        //   // },
        //   // {
        //   //   'textDx': 7,
        //   //   'textDy': 10,
        //   //   'textName': '◤',
        //   //   'textFill': 'rgba(255, 255, 255, 1)',
        //   //   'textSize': { stops: [[7, 30], [8, 0]] },
        //   //   'textRotation': 45
        //   // },
        //   // {
        //   //   'markerDx': 7,
        //   //   'markerType': 'ellipse',
        //   //   'markerFill': 'rgba(22, 119, 255, 1)',
        //   //   'markerWidth': { stops: [[7, 5], [8, 0]] },
        //   //   'markerHeight': { stops: [[7, 5], [8, 0]] },
        //   //   'markerLineWidth': 0
        //   // },
        //   // {
        //   //   'textDx': 40,
        //   //   'textDy': 0,
        //   //   'textName': '',
        //   //   'textFill': 'rgba(37, 43, 58, 1)',
        //   //   'textSize': { stops: [[7, 14], [8, 0]] }
        //   // },
        //   // {
        //   //   'markerDx': 76,
        //   //   'markerDy': 0,
        //   //   'markerType': 'ellipse',
        //   //   'markerFill': 'rgba(22, 119, 255, 1)',
        //   //   'markerWidth': { stops: [[7, 26], [8, 0]] },
        //   //   'markerHeight': { stops: [[7, 26], [8, 0]] },
        //   //   'markerLineWidth': 0
        //   // },
        //   // {
        //   //   'textDx': 76,
        //   //   'textDy': 0,
        //   //   'textName': 333,
        //   //   'textFill': 'rgba(255, 255, 255, .6)',
        //   //   'textSize': { stops: [[7, 14], [8, 0]] }
        //   // },
        // ],
        symbol: [
          {
            markerType: "ellipse",
            markerFill: {
              property: "count",
              type: "interval",
              stops: [
                [0, "rgb(135, 196, 240)"],
                [9, "#1bbc9b"],
                [50, "rgb(116, 115, 149)"],
                [99, "rgb(216, 115, 149)"]
              ]
            },
            markerFillOpacity: 0.7,
            markerLineOpacity: 1,
            markerLineWidth: 1,
            markerLineColor: "#fff",
            markerWidth: {
              property: "count",
              type: "interval",
              stops: [
                [0, 40],
                [9, 60],
                [50, 70],
                [99, 80]
              ]
            },
            markerHeight: {
              property: "count",
              type: "interval",
              stops: [
                [0, 40],
                [9, 60],
                [50, 70],
                [99, 80]
              ]
            }
          },
          {
            markerType: "ellipse",
            markerFill: {
              type: "radial",
              colorStops: [
                [0.0, "rgba(22, 165, 255, 0.7)"],
                [0.2, "rgba(22, 165, 255, 0.4)"],
                [0.5, "rgba(22, 165, 255, 0)"],
                [1.0, "rgba(22, 165, 255, 0)"]
              ]
            },
            markerWidth: 70,
            markerHeight: 70,
            markerLineWidth: 0
          }
        ],

        drawClusterText: true,
        geometryEvents: true,
        single: true
      });
      clusterLayer.config({
        textSymbol: {
          textFaceName: "monospace",
          textSize: 22,
          textSize: 16,
          textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
          textFill: "#fff"
          // 'textOpacity': 1,
          // 'textHaloFill': "#fff",
          // 'textHaloRadius': 2,
          // 'textWrapWidth': null,
          // 'textWrapCharacter': "\n",
          // 'textLineSpacing': 0,
          // 'textDx': 0,
          // 'textDy': 0,
          // 'textHorizontalAlignment': "middle", //left | middle | right | auto
          // 'textVerticalAlignment': "middle", // top | middle | bottom | auto
          // 'textAlign': "center" //left | right | center | auto
        }
      });
      // // 执行mark的变形动画
      // setInterval(() => {
      //   this.replay();
      // }, 1500);

      // function replay() {
      //   animate();
      //   setTimeout(() => {
      //     this.reset();
      //   }, 600);
      // }

      // // mark的变形动画
      // function animate() {
      //   let mark = this.markers;
      //   mark.map(item => {
      //     item.animate(
      //       {
      //         symbol: [
      //           {
      //             markerWidth: 40,
      //             markerHeight: 40,
      //             markerFillOpacity: 0.2,
      //             markerLineWidth: 1
      //           },
      //           {
      //             markerWidth: 20,
      //             markerHeight: 20,
      //             markerFillOpacity: 0.4
      //           }
      //         ]
      //       },
      //       {
      //         duration: 600
      //       }
      //     );
      //   });
      // }

      // // mark的变形动画
      // function reset() {
      //   let mark = that.markers;
      //   mark.map(item => {
      //     item.animate(
      //       {
      //         symbol: [
      //           {
      //             markerWidth: 20,
      //             markerHeight: 20,
      //             markerFillOpacity: 0.7,
      //             markerLineWidth: 3
      //           },
      //           {
      //             markerWidth: 10,
      //             markerHeight: 10,
      //             markerFillOpacity: 0.7
      //           }
      //         ]
      //       },
      //       {
      //         duration: 600
      //       }
      //     );
      //   });
      // }

      // /*maptalks的Mark的变形动画-----------------结束*/

      // /*maptalks.animatemarker插件------------------开始*/
      // function getGradient(colors) {
      //   return {
      //     type: "radial",
      //     colorStops: [
      //       [0.7, "rgba(" + colors.join() + ", 0.5)"],
      //       [0.3, "rgba(" + colors.join() + ", 1)"],
      //       [0.2, "rgba(" + colors.join() + ", 1)"],
      //       [0.0, "rgba(" + colors.join() + ", 0)"]
      //     ]
      //   };
      // }

      if (param === "") {
        window.$app.map._layers.forEach((item, index) => {
          if (item._id === "cluster") {
            window.$app.map.removeLayer(window.$app.map._layers[index]);
          }
        });
      } else {
        window.$app.map._layers.forEach((item, index) => {
          if (item._id === "cluster") {
            window.$app.map.removeLayer(window.$app.map._layers[index]);
          }
        });
        window.$app.map.addLayer(clusterLayer);
      }

      this.clusterLayer = clusterLayer;

      function onClick(e) {
        e.target.setInfoWindow({
          content: `<div class="popbox" style="text-indent:5px"> <div class='pop_dept_ep'><p><span>企业名称:</span>${e.target.properties.name}</p></div>
            <div class='pop_dept_ep'><p><span>企业类型:</span>${e.target.properties.type}</p></div>
            <div class='pop_dept_ep'><p><span>企业位置:</span>${e.target.properties.location}</p></div><div class='pop_dept_ep'><p><span>经营范围:</span>${e.target.properties.operationalActivities}</p><div class='pop_dept_ep'><p><span>企业经度:</span>${e.target.properties.longitude}</p></div><div class='pop_dept_ep'><p><span>企业纬度:</span>${e.target.properties.latitude}</p></div></div></div>`,
          // "" + e.target.properties.name + "",
          width: 150,
          dy: 5,
          autoPan: true,
          custom: false,
          autoOpenOn: "click", //set to null if not to open when clicking on marker
          autoCloseOn: "click"
        });
      }
      // if ((position.length = 0)) {
      //   window.$app.map.removeLayer(window.$app.map._layers[a]);
      // }
    },

    markeranimate(position) {
      // var animMarkerLayer = new AnimateMarkerLayer('anim-markers', this.markers).addTo(window.$app.map);

      var geometries = new maptalks.GeoJSON.toGeometry(position);
      var layer = new AnimateMarkerLayer("animatemarker", geometries, {
        animation: "scale,fade",
        randomAnimation: true,
        geometryEvents: false
      })
        .setStyle([
          {
            filter: ["<=", "mag", 2],
            symbol: {
              markerType: "ellipse",
              markerLineWidth: 0,
              markerFill: this.getGradient([135, 196, 240]),
              markerFillOpacity: 0.8,
              markerWidth: 5,
              markerHeight: 5
            }
          },
          {
            filter: ["<=", "mag", 5],
            symbol: {
              markerType: "ellipse",
              markerLineWidth: 0,
              markerFill: this.getGradient([255, 255, 0]),
              markerFillOpacity: 0.8,
              markerWidth: 12,
              markerHeight: 12
            }
          },
          {
            filter: [">", "mag", 5],
            symbol: {
              markerType: "ellipse",
              markerLineWidth: 0,
              markerFill: this.getGradient([216, 115, 149]),
              markerFillOpacity: 0.8,
              markerWidth: 20,
              markerHeight: 20
            }
          }
        ])
        .addTo(window.$app.map);
    },
    replay() {
      this.animate();
      setTimeout(() => {
        this.reset();
      }, 600);
    },
    animate() {
      let mark = window.$app.map._layers[1]._geoList;
      mark.map(item => {
        item.animate(
          {
            symbol: [
              {
                markerWidth: 40,
                markerHeight: 40,
                markerFillOpacity: 0.2,
                markerLineWidth: 1
              },
              {
                markerWidth: 20,
                markerHeight: 20,
                markerFillOpacity: 0.4
              }
            ]
          },
          {
            duration: 600
          }
        );
      });
    },
    reset() {
      let mark = window.$app.map._layers[1]._geoList;
      mark.map(item => {
        item.animate(
          {
            symbol: [
              {
                markerWidth: 20,
                markerHeight: 20,
                markerFillOpacity: 0.7,
                markerLineWidth: 3
              },
              {
                markerWidth: 10,
                markerHeight: 10,
                markerFillOpacity: 0.7
              }
            ]
          },
          {
            duration: 600
          }
        );
      });
    },
    getGradient(colors) {
      return {
        type: "radial",
        colorStops: [
          [0.7, "rgba(" + colors.join() + ", 0.5)"],
          [0.3, "rgba(" + colors.join() + ", 1)"],
          [0.2, "rgba(" + colors.join() + ", 1)"],
          [0.0, "rgba(" + colors.join() + ", 0)"]
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 938px;
  width: 500px;
  position: absolute;
  top: 86px;
  left: 100px;
  background: #081658;
  z-index: 11;
}

.header {
  height: 50px;
  color: #fff;
  border-left: 2px solid #ffd200;
  border-bottom: 1px solid #2e4468;
  font-size: 18px;
  text-align: start;
  margin: 0 auto;
  line-height: 50px;
  text-indent: 24px;
}
.close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 13px;
  font-size: 26px;
}
.header-bottom {
  height: 99px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: #fff;
}
.em {
  color: #02c6ff;
  font-size: 36px;
}
.chartbox {
  margin: 0 auto;
}
.Echartbox {
  width: 500px;
  height: 370px;
  margin: 0 auto;
}
.chartbottom {
  width: 500px;
  height: 370px;
  margin: 0 auto;
}
.title {
  width: 500px;
  height: 34px;
  margin: 0 auto;
  background: #193465;
  text-align: start;
  line-height: 34px;
  text-indent: 10px;
  color: #fff;
}
.icon-fangkuang {
  height: 10px;
  width: 10px;
  color: #084c8a;
  font-size: 10px;
  border: #167fff 1px solid;
  background: #084c8a;
  margin-right: 5px;
}
// #Echartbox{
//   height: 100%;
//   width: 100%;
// }
.bottom-nav {
  height: 100%;
  height: 351px;
  // width: 360px;
  margin: 0 auto;
}
.bottom-nav ::v-deep .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
.bottom-nav ::v-deep .el-table tr {
  background-color: transparent !important;
}
.bottom-nav ::v-deep .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
.bottom-nav ::v-deep .el-table__row > td {
  border: none;
}
/* 清除底部横线 */
.el-table::before {
  height: 0px;
}
.bottom-nav ::v-deep .el-table,
.el-table tr {
  background-color: none;
}
.seamless-warp {
  text-align: start;
  height: 296px;
  padding-left: 10px;
  overflow: hidden;
  ul li {
    list-style: none;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .itemli {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      display: flex;
      padding-top: 20px;
      // justify-content: space-around;
      // align-items: center;
      color: #fff;
      span {
        // text-overflow: ellipsis;

        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
.scrolltitle {
  width: 100%;
  font-size: 20px;
  display: flex;
  .scrolltext {
    display: inline-block;
    // width: 202px;
    font-size: 22px;
    font-weight: 900;
    padding-top: 5px;
    padding-bottom: 3px;
    font-size: 18px;
    line-height: 32px;
    height: 32px;
    color: #4795b8;
  }
  .scrolltext1 {
    padding-left: 35px;
  }
  .scrolltext2 {
    padding-left: 65px;
  }
  .scrolltext3 {
    padding-left: 95px;
  }
}
</style>
<style lang="scss">
::v-deep .popbox {
  text-align: left !important;
  text-indent: 10px !important;
}
.pop_dept_ep {
  text-align: left !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden;

  p {
    text-align: left !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
}
// #app-map ::v-deep .maptalks-msgBox{
// .maptalks-msgContent {
// ::v-deep .popbox{
//   text-align: left!important;
// }
// .pop_dept_ep {
//   text-align: left!important;
// }
// }
// }
</style>
