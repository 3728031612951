<template>
  <div class="app-map">
    <map-legend></map-legend>
    <ChartLeft class="chart"></ChartLeft>
    <ChartRight class="chart"></ChartRight>
    <MD></MD>
    <BL></BL>
    <Search></Search>
    <div id="app-map"></div>
    <img
      src="../../../../public/img/map/marker1.png"
      alt=""
      class="mapmarker"
    />
    <!-- <img
      src="../../../../public/img/map/mapmarker.png"
      alt=""
      class="mapmarker"
    /> -->
  </div>
</template>

<script>
import { polygon, pointOnFeature, centroid } from "@turf/turf";
import * as mapConf from "@/util/mapConf";
import { deepCopy, popData } from "@/util/util";
import api from "@/util/api";
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";
import MapLegend from "./map-legend/map-legend";
import mapTool from "./lib/mapTool.js";
import popup from "./lib/Popup";

import * as $configs from "./config.js"; //地图配置
import setStyle from "@/util/style";
import xhalabel from "./img/xhalabel.png"; // 西海岸文字
import HWD from "./img/HWD.png"; // 海湾点
import maptool from "./lib/mapTool.js";

import polygonPattern from "./lib/polygonPattern";
// 引入左边列表
import ChartLeft from "./chart-left/index";
// 引入右边列表
import ChartRight from "./chart-right/index";
// 引入代替左侧列表的弹框
import middledialog from "./middle-dialog/middle-dialog";
// 引入底部label框
import bottom_label from "./bottom-label/bottom-label";
// 引入代替右侧的搜索框
import Search from "./searchinput/searchinput";
export default {
  name: "app-map",
  components: {
    "map-legend": MapLegend,
    ChartLeft: ChartLeft,
    ChartRight,
    MD: middledialog,
    BL: bottom_label,
    Search: Search
  },
  created() {
    window.$app = this;

    // this.$router.push({
    //       path:'/'
    //     })
    //     let path = this.$route.fullPath; //先获取路由路径
    //     this.$router.push(''); //再跳转路由路径，query参数没带过去，所以被清除了
  },
  data() {
    return {
      center: [120.80285, 37.46765],
      base: "image",
      map: null,
      layers: {},
      zoom: 9,
      distanceTool: "",
      areaTool: "",
      regionLine: null, //市区线
      coastline: null, // 海岸线
      seaArea: null,
      colorObj: {
        保留区: "#45b97c",
        养殖区: "#33a3dc",
        航道区: "#8a5d19",
        海洋自然保护区: "#50b7c1",
        风景旅游区: "#fab27b",
        海洋特别保护区: "#585eaa",
        文体休闲娱乐区: "#ef4136",
        锚地区: "#8e7437",
        港口区: "#2a5caa",
        特殊利用区: "#f7acbc",
        工业与城镇用海区: "#63434f",
        矿产与能源区: "#8a8c8e",
        捕捞区: "#ffd400",
        增殖区: "#973c3f"
      },
      arr: [],
      cityoptions:[]
    };
  },
  mounted() {
    this.initMap();
    // this.measurement()
    let path = this.$route.path; //先获取路由路径
    // debugger
    // this.$route.path.replace()
    this.comLayer =this.initVectorLayer("comlayer", 1);

  },
  methods: {
    initMap() {
      // 初始化地图
      const map = (this.map = new maptalks.Map("app-map", {
        attribution: "",
        bearing: 0,
        center: this.center,
        dragPitch: false,
        dragRotate: false,
        maxExtent: [105, 3, 133, 41],
        maxZoom: 20,
        minZoom: 5,
        pitch: 0,
        zoom: this.zoom,
        overviewControl: true,
        scaleControl: true
      }));
      Object.keys($configs.baseLayer).forEach(key => {
        this.layers[key] = new maptalks.TileLayer(
          "layerGeoQ",
          $configs.baseLayer[key]
        );
      });
      this.setBaseLayer(this.base);
      // this.addHYQQ(); // 海域确权
      // this.addRedLine(); // 生态红线
      // this.addLandRangeLine(); // 陆地范围线
      // this.addRangeLine(); // 海洋范围线
      // this.addCoastLine(); // 海岸线
      // this.addfunArea(); // 功能区划
      this.addYQ(); //   渔区
      // this.addBay(); //  海湾点海湾线
      this.addSeaBaseLine(); // 领海基线
      this.addTYcoastline();
      this.getAllInfomation();
      this.addSeaArea(); // 添加YT海区
    },
    // 底图图层初始化
    initBaseLayer(key, options) {
      const layers = this.layers;
      const name = `base_${key}`;
      let layer = layers[name];
      if (layer) {
        layer.remove();
      }
      layer = new maptalks.TileLayer(name, {
        subdomains: options.subdomains,
        urlTemplate: options.url
      });
      layers[name] = layer;
      return layer;
    },
    // 矢量图层初始化
    initVectorLayer(key, zIndex) {
      const layers = this.layers;
      const name = `base_${key}`;
      let layer = this.layers[name];
      if (layer) {
        layer.remove();
      }
      layer = new maptalks.VectorLayer(name, {
        drawAltitude: false,
        enableAltitude: false,
        zIndex: zIndex || 1
      }).addTo(this.map);
      layers[name] = layer;
      return layer;
    },
    // 设置底图图层
    setBaseLayer(key) {
      const layers = this.layers;
      const name = `${key}`;
      const layer = layers[name];
      if (layer) {
        this.map.setBaseLayer(layer);
        this.base = key;
      }
    },
    // 地图元素构造
    initGeometry({ className, params }) {
      return new maptalks[className](...params);
    },
    // 请求烟台数据
    getAllInfomation() {

      this.arr = [];
      // 同时获取企业数量
      this.$axios.post(api.query_enterprise).then(res => {
        let _r = res.data.result;
        window.$app.enterpriselist = res.data.result.list
        let enterprisename = _r.name;
        let enterprisevalue = _r.value;

        let namelength = enterprisename.length;
        for (let i = 0; i < namelength; i++) {
          let a = {};
          a.name = enterprisename[i];
          a.value = enterprisevalue[i];
          this.arr.push(a);
        }

        this.regionLine = this.initVectorLayer("YTSQ", -1);
        this.$axios.post(api.getAll_yanTai).then(res => {
          let _r = res.data.result;
          this.addMk(_r);
          this.cityoptions = _r
          _r.forEach(item => {
            this.arr.forEach(arritem => {
              if (arritem.name === item.name) {
                item.value = arritem.value;
              }
            });
            this.addregionLine(item);
          });
        });
      });
    },
    // 添加批量标注
    addMk(result) {
      var markers = []; //标注集合
      //测试数据
      var dataCity = result;
      dataCity.forEach(item => {
        markers.push(
          new maptalks.Marker(JSON.parse(item.center), {
            //标注添加到集合
            symbol: {
              textName: item.name,
              textSize: 20,
              textFill: "#fff"
            }
          })
        );
      });
      this.regionLine.addGeometry(markers);
    },
    // 渲染市区线
    addregionLine(options) {

      var coordinates = JSON.parse(options.lonLat);
      coordinates.forEach((item, index) => {
        var list = [];
        item.forEach((val, indexTow) => {
          if (val[0] && val[1]) {
            list.push(val);
          }
        });
        const symbol = [
          {
            // lineDasharray: lineDasharray,
            polygonFill: "#2468a2", // 图形覆盖颜色
            polygonOpacity: 0.1, // 图形覆盖区域透明度
            lineColor: "#3194fb",
            lineWidth: 4,
            lineDasharray: [2, 1, 2], //线形
            lineOpacity: 1
          }
        ];
        var id = `Q_X_${options.name}_${index}`;
        const area = this.initGeometry({
          className: "Polygon",
          params: [list, { id, symbol }]
        });

        area.addTo(this.regionLine);
        area.setInfoWindow({
          autoOpenOn: "click",
          single: true,
          // width: 183,
          // height: 105,
          // custom: true,
          // dx: -3,
          // dy: -12,
          title: options.name + "规模以上企业数量",
          content:
            '<div class="content2" style="color: rgb(165, 53, 53)!important;border:none;">' +
            '<div class="pop_dept">' +
            '<p style:"padding:0;border:none">' +
            options.name +
            "共有" +
            options.value +
            "家规模以上涉海企业" +
            "</p>"
        });
        // this.$nextTick(function() {
        //   //   //设置表格的最大高度
        //   setTimeout(() => {
        //     let msgBox = document.querySelector(".maptalks-close");
        //     msgBox.onclick = function() {};
        //   }, 1000);
        // });
      });
    },
    // 添加烟台海岸线
    addTYcoastline() {

      this.coastline = this.initVectorLayer("coastline", 1);
      this.$axios.post(api.shoreline_getAll).then(res => {
        let _r = res.data;
        if (_r.success) {
          _r.result.forEach((item, index) => {
            var coordinates = JSON.parse(item.coordinates);
            var line = new maptalks.LineString(coordinates, {
              symbol: {
                // lineColor: "red",
                // lineWidth: 8,
                // // textName: "line",
                // textPlacement: "line",
                // textSize: 20,
                // textDy: -20,
                lineColor: "#f47920",
                lineWidth: 4,
                lineDasharray: [2, 1, 2], //线形
                lineOpacity: 0.5,
                polygonFill: "rgba(32, 88, 146, 0)",
                polygonOpacity: 0
              }
            }).addTo(this.coastline);
          });
        }
      });
    },
    // 添加烟台海区数据
    addSeaArea(active) {
      this.seaArea = this.initVectorLayer("seaArea", 199);
      this.$axios.post(api.division_getAll).then(res => {
        let _r = res.data;
        if (_r.success) {
          _r.result.forEach(item => {
            const id = `a_w_${item.partitionName}`;
            this.addWatersToMap(id, item);

          });
        }
      });
      if (active) {
        this.map.removeLayer(this.seaArea);
      }
      // this.seaArea.hide();
    },
    // 海区叠加到地图
    addWatersToMap(id, options) {
      let that = this;
      const layer = this.seaArea;
      let className = "";
      let textName = "";
      let textSize = {
        stops: [
          [11, 0],
          [12, 18]
        ]
      };
      let lineDasharray = null;
      var colorObj = this.colorObj;
      const symbol = [
        {
          // lineDasharray: lineDasharray,
          polygonFill: colorObj[options.classification], // 图形覆盖颜色
          polygonOpacity: 0.8, // 图形覆盖区域透明度
          lineColor: "#fff", // 线条颜色
          lineWidth: 0.2 // 线条宽度
        },
        {
          textName: options.partitionName,
          // textSize: 14,
          textDx: 0,
          textDy: 0,
          textFill: "#fff",
          textSize: textSize
        }
      ];

      var datalist = JSON.parse(options.lonLat);

      datalist.forEach((data, index) => {
        id = `a_w_${options.partitionName}_${index}`;
        const area = this.initGeometry({
          // className: className,
          className: "Polygon",
          params: [data, { id, symbol }]
        });

        area.addTo(layer);
        area.on("click", evt => {
          that.seaAreaClick(
            `a_w_${options.partitionName}`,
            evt,
            options,
            datalist
          );
        });
        area.setInfoWindow({
          title: options.partitionName,
          content: `<div class=''><p><span>县区:</span><em class='pop_d'>${options.countyCity}</em></p></div>
            <div class='pop_dept'><p><span>分类:</span><em class='pop_d'>${options.classification}</em></p></div>
            <div class='pop_dept'><p><span>分区编码:</span><em class='pop_d'>${options.partitionCode}</em></p></div>`
        });
      });
    },
    // 海区点击事件
    seaAreaClick(id, evt, options, datalist) {
      let that = this;
      var geoList = this.seaArea._geoList;
      geoList.forEach(item => {
        if (id.indexOf(item._id) < 0) {
          const area = this.seaArea.getGeometryById(item._id);
          area.updateSymbol([
            {
              polygonOpacity: 0.1,
              lineColor: "#fff", // 线条颜色
              lineWidth: 0.2 // 线条宽度
            }
          ]);
        }
      });
      datalist.forEach((item, index) => {
        const area = this.seaArea.getGeometryById(id + `_${index}`);
          console.log(area);

        area.updateSymbol([
          {
            polygonFill: this.colorObj[options.classification], // 图形覆盖颜色
            polygonOpacity: 0.9,
            lineColor: "#fff", // 线条颜色
            lineWidth: 0.2 // 线条宽度
          }
        ]);
      });
      this.$nextTick(function() {
        //   //设置表格的最大高度
        setTimeout(() => {
          let msgBox = document.querySelector(".maptalks-close");
          msgBox.onclick = function() {
            that.seaAreaHY();
          };
        }, 1000);
      });
    },
    // 海区点击还原
    seaAreaHY(id, evt, options) {
      var geoList = this.seaArea._geoList;
      geoList.forEach(item => {
        const area = this.seaArea.getGeometryById(item._id);
        area.updateSymbol([
          {
            polygonOpacity: 0.5,
            lineColor: "#fff", // 线条颜色
            lineWidth: 0.2 // 线条宽度
          }
        ]);
      });
    },
    // 添加领海基线
    addSeaBaseLine(active) {
      this.$axios
        .get(api.LHJX)
        .then(res => {
          const layer = this.initVectorLayer("LHJX", 1);
          maptalks.GeoJSON.toGeometry(res.data, geo => {
            geo.setSymbol({
              lineColor: "#f8a7f5",
              lineWidth: 3,
              // 'lineDasharray': [2, 1 ,2], //线形
              lineOpacity: 1,
              polygonOpacity: 0
            });
            layer.addGeometry(geo);
          });
          if (active) {
            this.map.removeLayer(layer);
          }
          layer.hide();
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 添加西海岸海洋范围数据线
    addRangeLine() {
      this.$axios
        .get(api.hy1)
        .then(res => {
          const layer = this.initVectorLayer("hy", 1);
          maptalks.GeoJSON.toGeometry(res.data, geo => {
            geo.setSymbol({
              lineColor: "#3194fb",
              lineWidth: 2,
              lineDasharray: [2, 1, 2], //线形
              lineOpacity: 1,
              polygonOpacity: 0
            });
            layer.addGeometry(geo);
          });
        })
        .catch(err => {
        });
    },
    // 添加西海岸陆地范围数据线
    addLandRangeLine(active) {
      this.$axios
        .get(api.ly)
        .then(res => {
          const layer = this.initVectorLayer("outline", 1);
          maptalks.GeoJSON.toGeometry(res.data, geo => {
            geo.setSymbol({
              lineColor: "#15b1ad",
              lineWidth: 2,
              polygonFill: "rgb(135,196,240)",
              lineDasharray: [5, 4, 5], //线形
              polygonOpacity: 0.6
            });
            layer.addGeometry(geo);
          });
          if (active) {
            this.map.removeLayer(layer);
          }
          // 添加西海岸文字
          const textlayer = this.initVectorLayer("text", 1);
          const marker = new maptalks.Marker([119.888, 35.86004], {
            symbol: {
              markerFile: xhalabel,
              // 'markerWidth'  : 28,
              // 'markerHeight' : 40,
              markerDx: 0,
              markerDy: 0,
              markerOpacity: 10
            }
          });
          marker.addTo(textlayer);
        })
        .catch(err => {
        });
    },
    // 添加海岸线
    addCoastLine(active) {
      this.$axios
        .post(api.shoreline_getAll)
        .then(res => {
          const layer = this.initVectorLayer("coastline", 1);
          maptalks.GeoJSON.toGeometry(res.datat, geo => {
            geo.setSymbol({
              lineColor: "#ff0100",
              lineWidth: 1,
              polygonFill: "rgb(135,196,240)",
              polygonOpacity: 0.6
            });
            layer.addGeometry(geo);
          });
          layer.hide();
        })
        .catch(() => {});
    },
    // 添加渔区
    addYQ(active) {
      this.$axios
        .get(api.YQ)
        .then(res => {
          const layer = this.initVectorLayer("YQ", 1);
          let features = res.data.features;
          features.forEach(item => {
            var geojson = item.geometry;

            maptalks.GeoJSON.toGeometry(geojson, geo => {
              geo.setProperties(item.properties);
              geo.setSymbol([
                {
                  lineColor: "chocolate",
                  lineOpacity: 0.8,
                  lineWidth: 0.5,
                  polygonFill: "#ffffff",
                  polygonOpacity: 0
                },
                {
                  textName: `{${"Name"}}`,
                  textSize: {
                    stops: [
                      [7, 0],
                      [8, 14]
                    ]
                  },
                  textFill: "#999",
                  textHaloFill: "#101010",
                  textHaloRadius: 2,
                  textHorizontalAlignment: "middle",
                  textWeight: 400
                }
              ]);
              layer.addGeometry(geo);
            });
          });
          layer.hide();
        })
        .catch(() => {});
    },
    //
    // 生态红线
    addRedLine(active) {
      this.$axios.post(api.redline).then(res => {
        const layer = this.initVectorLayer("redline", 1);
        var that = this;
        var features = res.data.result;
        features.forEach(item => {
          var geojson = JSON.parse(item.geo);
          maptalks.GeoJSON.toGeometry(geojson, geo => {
            that.GeoPopClick("生态红线", geo, item, layer);
          });
        });
        that.labelonLayer(layer, features, "code");
        layer.setStyle(that.getredStyle("生态红线"));
        layer.hide();
      });
    },
    // 海域确权
    addHYQQ() {
      this.$axios.get(api.HYQQ).then(res => {
        const layer = this.initVectorLayer("HYQQ", 1);
        var that = this;
        var features = res.data.features;
        features.forEach(function(item) {
          var geojson = item.geometry;
          maptalks.GeoJSON.toGeometry(geojson, geo => {
            // 将海域确权点添加到地图
            var prop = deepCopy(item.properties); // prop为需要展示的描述文字
            delete prop["geo"];
            geo.setProperties(prop);
            layer.addGeometry(geo);
            layer.setStyle(that.initHYQQstyle());

            // 添加样式和点击事件
            var options = {
              content:
                '<div class="content" style="display: flex;flex-direction: column;align-items: flex-start;height:314px;width:433px;font-size:16px;color:#fff">' +
                '<div class="pop_title">' +
                `「海域确权」` +
                `<span>${prop.SYQR}</span>` +
                "</div>" +
                '<div class="pop_text1">' +
                `项目名称：${prop.SYQR}` +
                "</div><br>" +
                '<div class="pop_text1">' +
                "使用权人：" +
                `${prop.rec_person}` +
                "</div><br>" +
                '<div class="pop_dept">' +
                `使用用途:${prop.YHFS}` +
                "</div>" +
                '<div class="pop_dept">' +
                `用海级别：  ${prop.sea_level}` +
                "</div>" +
                '<div class="arrow">' +
                `证书编号：  ${prop.certi_code}` +
                "</div>" +
                "</div>"
            };
            geo.on("click", evt => {
              geo.flash(100, 2, () => {
                var coordinate = that.map.getCenter().toFixed(3);
                // var infoWindow = new maptalks.ui.InfoWindow(options);
                // infoWindow.addTo(geo).show(coordinate);
                geo.setInfoWindow(options);
                if (
                  geo.getType() == "Polygon" ||
                  geo.getType() == "MultiPolygon"
                ) {
                  geo.openInfoWindow(evt.coordinate);
                }
              });
            });
          });
        });
        layer.hide();
      });
    },
    initYourDom() {
      this.container.innerHTML = `
        <div class="pop-container-frame" style="position:relative;width:100%;height: 100%;overflow: hidden;box-shadow: rgba(3, 31, 60, 0.6) 2px 2px 15px;">
            <div class="popTitle" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin:9px auto;height: 26px;font-size: 18px;line-height: 26px;color: white;background: linear-gradient(to right, #1677FF00, #1677FC, #1677FF00) ">title</div>
            <div class="pop-status" style="height:45px;width:100%">
                <div class="circle-text" style="float: left;line-height: 45px;width: 25%;height: 100%;">运行状态</div>
                <div style="float: left;line-height: 45px;width: 25%;height: 100%;" id="quipState"></div>
                <div class="circle-text" style="float: left;line-height: 45px;width: 25%;height: 100%;">传输状态</div>
                <div style="float: left;line-height: 45px;width: 25%;height: 100%;" id="dataState"></div>
            </div>
            <div class="popSpans" style="float: left;width: 100%;padding: 3px;">
            </div>
            <div class="popLabel" style="float: left;width: 100%;">
            </div>
            <div class="popLabelBtn" style="float: left;width: 100%;">
            </div>
            <div class="pop-btns" style="float: left;width: 96%;margin:0 2%">
            </div>
        </div>`;

      this.popTitle = this.container.querySelector(".popTitle");
      this.popSpans = this.container.querySelector(".popSpans");
      this.popLabel = this.container.querySelector(".popLabel");
      this.popLabelBtn = this.container.querySelector(".popLabelBtn");
      this.popStatus = this.container.querySelector(".pop-status");
      this.quipState = this.container.querySelector("#quipState");
      this.dataState = this.container.querySelector("#dataState");
      this.btnContent = this.container.querySelector(".pop-btns");
    },
    // 海域弹框文字确权配置
    initHYQQstyle() {
      let conf = [
        {
          type: "fill",
          name: "其他",
          value: "",
          color: "#ffffff",
          key: "use_typeA_", //读取的key
          fillAlpha: 0.5
        },
        {
          type: "fill",
          name: "交通运输用海",
          value: "交通运输用海",
          color: "#F5B6FD",
          key: "use_typeA_" //读取的key
        },
        {
          type: "fill",
          name: "其他用海",
          value: "其他用海",
          color: "#B6C1FD",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "工业用海",
          value: "工业用海",
          color: "#BDF5FD",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "排污倾倒用海",
          value: "排污倾倒用海",
          color: "#B8FDB6",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "旅游娱乐用海",
          value: "旅游娱乐用海",
          color: "#FDCBBB",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "海底工程用海",
          value: "海底工程用海",
          color: "#FDC8E1",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "渔业用海",
          value: "渔业用海",
          color: "#B6FDDF",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "特殊用海",
          value: "特殊用海",
          color: "#D4E0FD",
          key: "use_typeA_"
        },
        {
          type: "fill",
          name: "造地工程用海",
          value: "造地工程用海",
          color: "#F0FDD4",
          key: "use_typeA_"
        }
      ];
      let label = {
        海域确权: {
          label: "",
          title: "pro_name",
          pic: "",
          column: [
            {
              name: "项目名称",
              key: "pro_name",
              unit: "",
              info: "full"
            },
            {
              name: "使用权人",
              key: "SYQR",
              unit: ""
            },
            {
              name: "用海级别",
              key: "sea_level",
              unit: ""
            },
            {
              name: "证书编号",
              key: "certi_code",
              unit: ""
            },
            {
              name: "用海方式",
              key: "YHFS",
              unit: ""
            }
          ]
        }
      };
      let style = [];
      return (style = this.getStyleFromConf(conf, label));
    },
    // 海域确权配置
    GeoPopClick(type, geo, properties, layer, popup) {
      var prop = deepCopy(properties);
      delete prop["geo"];
      geo.setProperties(prop);
      layer.addGeometry(geo);
      geo.on("click", evt => {
        geo.flash(100, 2, () => {
          //evt.coordinate
          if (geo.getType() == "Polygon" || geo.getType() == "MultiPolygon") {
            popup.popShow(evt.coordinate, popData(type, geo.getProperties()));
          } else {
            popup.popShow(geo.getCenter(), popData(type, geo.getProperties()));
          }
        });
      });
    },
    // 海域确权
    getFillSymbol(
      color,
      lineColor,
      textColor,
      key,
      fillalpha,
      borderAlpha,
      lineDash
    ) {
      let symbol = [];
      borderAlpha = borderAlpha ? borderAlpha : 0.8;
      fillalpha = fillalpha || fillalpha == 0 ? fillalpha : 0.8;
      lineColor = lineColor || lineColor == 0 ? lineColor : "#104068";
      textColor = textColor ? textColor : "#FFFFFF";
      lineDash = lineDash ? lineDash : [10, 5, 5];
      symbol.push({
        polygonFill: color,
        polygonOpacity: fillalpha,
        lineColor: lineColor,
        lineWidth: 0.5,
        lineOpacity: borderAlpha,
        lineDasharray: lineDash
      });
      if (key && key !== "") {
        symbol.push({
          textName: "{" + key + "}",
          textSize: 14,
          textFill: "#fff",
          textHaloFill: "#101010",
          textHaloRadius: 2,
          textHorizontalAlignment: "middle",
          textWeight: 400
        });
      }
      return symbol;
    },
    // 海域确权
    getPatternSymbol(pattern, fillAlpha, key, lineColor, dash) {
      //图案base64填充
      let alpha = fillAlpha || 0.8;
      let line = lineColor || "#121212";
      let symbol = [];
      var poly = {
        polygonOpacity: alpha,
        polygonPatternFile: pattern,
        lineColor: line,
        lineWidth: 0.5,
        lineOpacity: 0.8
      };
      if (dash) {
        poly.lineDasharray = dash;
      }
      symbol.push(poly);
      if (key) {
        symbol.push({
          textName: "{" + key + "}",
          textSize: 14,
          textFill: "#fff",
          textHaloFill: "#101010",
          textHaloRadius: 2,
          textHorizontalAlignment: "middle",
          textWeight: 400
        });
      }
      return symbol;
    },
    // 海域确权
    getStyleFromConf(conf, label) {
      //style-conf   图上标注label（可以没有）

      let style = [];
      let that = this;
      if (!conf) {
        return [];
      }
      if (conf instanceof Array) {
        conf.forEach(function(item) {
          let fillType = item.fillType ? item.fillType : item.type;
          let borderColor = item.borderColor ? item.borderColor : undefined;
          let textColor = item.textColor ? item.textColor : undefined;
          let symbol;
          let borderAlpha = item.borderAlpha ? item.borderAlpha : 0.9;
          let fillAlpha = item.fillAlpha ? item.fillAlpha : 0.9;
          let lineDash = item.lineDash;
          if (fillType === "fill") {
            symbol = mapTool.getFillSymbol(
              item.color,
              borderColor,
              textColor,
              false,
              fillAlpha,
              borderAlpha,
              lineDash
            );
          }
          if (fillType === "bias") {
            symbol = mapTool.getPatternSymbol(
              item.src,
              item.alpha,
              false,
              item.borderColor,
              item.lineDash
            );
          }
          style.push({
            filter: ["==", item.key, item.value],
            symbol: symbol
          });
        });
      } else {
        let fillType = conf.fillType ? conf.fillType : conf.type;
        let borderColor = conf.borderColor ? conf.borderColor : undefined;
        let textColor = conf.textColor ? conf.textColor : undefined;
        let symbol;
        let borderAlpha = conf.borderAlpha ? conf.borderAlpha : 0.9;
        let fillAlpha = conf.fillAlpha ? conf.fillAlpha : 0.9;
        let num = conf.num ? conf.num : 1;
        let lineDash = conf.lineDash;
        if (fillType === "fill") {
          symbol = mapTool.getFillSymbol(
            conf.color,
            borderColor,
            textColor,
            false,
            fillAlpha,
            borderAlpha,
            lineDash
          );
        }
        if (fillType === "bias") {
          symbol = mapTool.getPatternSymbol(
            conf.src,
            conf.alpha,
            false,
            conf.borderColor,
            conf.lineDash
          );
        }
        for (let i = 0; i < num; i++) {
          style.push({ symbol: symbol });
        }
      }
      return style;
    },
    addfunArea() {
      this.$axios.post(api.functionarea).then(res => {
        const layer = this.initVectorLayer("funArea", 1);
        var that = this;
        var features = res.data.result;
        features.forEach(item => {
          var geojson = JSON.parse(item.geo);
          maptalks.GeoJSON.toGeometry(geojson, geo => {
            that.GeoPopClick("功能区划", geo, item, layer);
          });
        });
        maptool.labelonLayer(layer, features, "code");
        layer.setStyle(that.getstyle("功能区划"));
        layer.hide();
      });
    },
    // 海湾点配置
    addBay(active) {
      // 海湾点
      this.$axios
        .get(api.baypoint)
        .then(res => {
          const layer = this.initVectorLayer("baypoint", 2);
          var features = res.data.features;
          features.forEach(item => {
            var geojson = item.geometry;
            maptalks.GeoJSON.toGeometry(geojson, geo => {
              geo.setSymbol({
                markerFile: HWD,
                markerWidth: 20,
                markerHeight: 30,
                markerDx: 10,
                markerDy: 20
              });
              geo.setProperties(geojson.properties);
              layer.addGeometry(geo);
              geo.on("click", evt => {
                geo.flash(100, 2);
              });
            });
          });

          layer.hide();
        })
        .catch(() => {});

      let that = this;
      // 海弯线配置
      that.$axios
        .get(api.bay)
        .then(res => {
          const layer = this.initVectorLayer("bay", 2);
          var features = res.data.features;
          features.forEach(function(item) {
            var geojson = item.geometry;
            maptalks.GeoJSON.toGeometry(geojson, geo => {
              that.GeoOperate("bay", geo, item.properties, layer);
            });
          });
          layer.setStyle(that.getstyle("海湾线"));
          layer.hide();
        })
        .catch(() => {});

      // 海湾文字配置
      let text = {
        label1: "Name", //标注字段
        label2: "Name_sup", //标注字段
        textDx1: 0,
        textDy1: -10,
        textDx2: 0,
        textDy2: 10
      };

      that.$axios
        .get(api.baylabel)
        .then(res => {
          const layer = this.initVectorLayer("baylabel", 2);
          res.data.forEach(item => {
            let sybs = this.getDoubleLabelStyle(text);
            var coor = item.coordinates;
            let prop = item.properties;
            let geo1 = new maptalks.Marker(coor);
            geo1.setProperties(prop);
            geo1.setSymbol(sybs[0]);
            let geo2 = new maptalks.Marker(coor);
            geo2.setProperties(prop);
            geo2.setSymbol(sybs[1]);
            layer.addGeometry(geo1);
            layer.addGeometry(geo2);
          });
          layer.hide();
        })
        .catch(() => {});
    },
    // 区域红线方法
    getredStyle() {
      let conf = [
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash",
            color: "#CC48A0",
            fill: "#F7C2BB"
          }),
          name: "限制类海洋特别保护区",
          value: "X-HT",
          lineDash: [10, 5, 5],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        },
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash-back-slash",
            color: "#CC48A0",
            fill: "#F4B6B4"
          }),
          name: "禁止类海洋特别保护区",
          value: "J-HT",
          lineDash: [10, 5, 5],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        },
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash",
            color: "#59004E",
            fill: "#FF00C3"
          }),
          name: "砂质岸线与邻近海域",
          value: "X-SZ",
          lineDash: [8, 2, 5],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        },
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash",
            color: "#CC48A0",
            fill: "#F7C2BB"
          }),
          name: "限制类海洋自然保护区",
          value: "X-HZ",
          lineDash: [5, 3, 2],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        },
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash-back-slash",
            color: "#CC48A0",
            fill: "#F4B6B4"
          }),
          name: "禁止类海洋自然保护区",
          value: "J-HZ",
          lineDash: [6, 5, 2],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        },
        {
          fillType: "bias",
          type: "img",
          src: polygonPattern({
            type: "slash",
            color: "#CC48A0",
            fill: "#E1C7FF"
          }),
          name: "重要滨海旅游区",
          value: "X-BL",
          lineDash: [9, 6, 5],
          borderColor: "#CC48A0",
          key: "drawCategory", //读取的key
          alpha: 0.9
        }
      ];
      let popConf = {
        生态红线: {
          label: "code",
          title: "name",
          pic: "",
          column: [
            {
              name: "区域类型",
              key: "type",
              unit: ""
            },
            {
              name: "区域类别",
              key: "category",
              unit: ""
            },
            {
              name: "区域代码",
              key: "code",
              unit: ""
            }
          ]
        }
      };
      let label = popConf.label;
      let style = [];
      return (style = mapTool.getStyleFromConf(conf, label));
    },
    // 海岸线方法
    getstyle(type, prop) {
      let conf = mapConf.legendConf[type];
      let popConf = mapConf.popupConf[type] || {};
      let label = popConf.label;
      let style = [];
      switch (type) {
        case "功能区划":
        case "生态红线":
        case "海域确权":
        case "保护区":
        case "海洋牧场":
        case "布防区域":
          style = maptool.getStyleFromConf(conf, label);
          break;
        case "海湾线":
          conf = mapConf.other["海湾线"];
          style = maptool.getLineStyleFromConf(conf);
          break;
        case "养殖企业":
        case "苗种企业":
        case "海岛":
        case "涉海企业":
        case "港口":
        case "渔港":
        case "涉海科研机构":
        case "沉积物调查":
        case "水质调查站":
        case "海湾点":
        case "海洋站":
        case "浮标":
        case "兴趣点":
        case "无人机":
          style = maptool.getPointStyle(conf);
          break;
        case "入海口卫士":
        case "渔港卫士":
        case "岛链卫士":
        case "岸线卫士":
        case "蓝湾监控":
        case "新建监控":
          style = maptool.getWSPointStyle(conf, prop);
          break;
        default:
          break;
      }
      return style;
    },
    getLineStyleFromConf(conf) {
      let style = [];
      let that = this;
      conf.forEach(function(item) {
        var symbol = that.getLineConfSymbol(item);
        style.push({
          filter: ["==", item.key, item.value],
          symbol: symbol
        });
      });
      return style;
    },
    getLineConfSymbol(conf) {
      let color = conf.color || "#ffffff";
      let dashArray = !conf.dashArray ? null : conf.dashArray;
      let symbol = {
        lineColor: color,
        lineWidth: 3,
        lineDasharray: dashArray, //线形
        lineOpacity: 1
      };
      return symbol;
    },
    getDoubleLabelStyle(conf) {
      //用于单点两个标注：海湾标注
      let textDx1 = conf.textDx1 || 0;
      let textDy1 = conf.textDy1 || 3;
      let textDx2 = conf.textDx2 || 0;
      let textDy2 = conf.textDy2 || -33;
      let label1 = conf.label1;
      let label2 = conf.label2;
      let s1 = {
        textName: "{" + label1 + "}",
        textSize: 18,
        textWeight: 400,
        textFill: "#101010",
        textHaloFill: "#fff",
        textHorizontalAlignment: "middle",
        textHaloRadius: 3,
        textDy: textDy1,
        textDx: textDx1
      };
      let s2 = {
        textName: "{" + label2 + "}",
        textSize: 12,
        textWeight: 200,
        textFill: "#101010",
        textHaloFill: "#fff",
        textHaloRadius: 1,
        textHorizontalAlignment: "middle",
        textDy: textDy2,
        textDx: textDx2
      };
      return [s1, s2];
    },
    // 添加marker点击方法，弹窗等
    GeoOperate(type, geo, properties, layer) {
      var prop = deepCopy(properties);
      delete prop["geo"];
      geo.setProperties(prop);
      layer.addGeometry(geo);
      if (type == "bay") {
        var options = {
          content:
            '<div class="content" style="height:300px;width:433px;font-size:16px;color:#fff">' +
            `<div class="pop_title" style="font-size:25px;margin:9px auto;text-align:start;background: linear-gradient(to right, #1677FF00, #1677FC, #1677FF00);">` +
            `「海湾线」  ${prop.bay_name}` +
            "</div>" +
            '<div style="padding-top:8px;text-align: start;padding-left: 18px;" class="pop_text1">' +
            `<span style="width:70px;text-align:start">包含区域：</span>` +
            `${prop.bay_supp}` +
            "</div><br>" +
            '<div class="pop_dept" style="text-align: start;padding-left: 18px;">' +
            `<span style="width:70px;text-align:start">区级湾长</span>` +
            `  ${prop.QJWZ}` +
            "</div>" +
            '<div class="pop_dept" style="text-align: start;padding-left: 18px;padding-top:15px;">' +
            `<span style="width:70px;text-align:start;font-size: 14px;
            font-family: PingFang SC;
            border-left: 2px solid #00daf800;
            font-weight: 700;">湾区详情：</span>` +
            `<span style="    text-align: left;
            width: 190px;">${prop.detail}</span>` +
            "</div>" +
            "</div>"
        };
      }
      geo.on("click", evt => {
        geo.setInfoWindow(options);
        geo.flash(100, 2, () => {
          geo.openInfoWindow(evt.coordinate);
        });
      });
    },
    getPopup(type, prop) {
      let popConf = mapConf.popupConf;
      let conf = popConf["海湾线"];
      if (!conf) {
        return;
      }
      let titleKey = conf.title;
      let column = conf.column;
      let isPic = conf.pic === "" ? false : true;
      let btns = conf.btn;
      let isBtn = btns instanceof Array && btns.length > 0 ? true : false;
      let htmlContent = "";
      if (isPic) {
        htmlContent = '<div class="popup-leftright relativeContianer left">';
      } else {
        htmlContent = '<div class="popup-onlyleft relativeContianer left">';
      }
      htmlContent += '<div class="popup-left relativeContianer left">';
      let index = 0;
      column.forEach(function(col) {
        index++;
        if (index % 2 == 1) {
          htmlContent += '<div class="popup-row left bootstrap">';
        } else {
          htmlContent += '<div class="popup-row left">';
        }
        htmlContent += '<div class="popup-label left">' + col.name + "：</div>";
        let value = prop[col.key];
        if (!value || value == "" || value == "null" || value == "undefined") {
          value = " - ";
        }
        if (col.info === "full") {
          htmlContent +=
            '<div class="popup-data left"> ' +
            value +
            " " +
            col.unit +
            "</div></div>";
        } else {
          htmlContent +=
            '<div class="popup-data left ellipse">' +
            value +
            " " +
            col.unit +
            "</div></div>";
        }
      });
      if (isBtn) {
        htmlContent += '<div class="popup-row popcenter left">';
        btns.forEach(function(btn) {
          htmlContent +=
            "<a  class='popup-btn left' href='javascript:void(0);' onclick=\"popupClick('" +
            type +
            "','" +
            prop[btn.key] +
            "','" +
            btn.name +
            "');\">" +
            btn.name +
            "</a>";
        });
        htmlContent += "</div>";
      }
      htmlContent +=
        '<div class="popup-row left"><div style="clear:both"></div></div>';
      htmlContent += "</div>";
      if (isPic) {
        let src = prop[conf.pic];
        if (!prop[conf.pic]) {
          src = 0;
        }
        htmlContent +=
          '<div class="popup-right relativeContianer left"><img style="width:100%" src="' +
          api.picUrl +
          src +
          '"></div>';
      }
      htmlContent += "</div>";
      let html_title =
        '<div class="popup-title ellipse left">[' +
        type +
        "] " +
        prop[titleKey] +
        "</div>";
      return {
        autoOpenOn: false,
        title: html_title,
        content: htmlContent
      };
    },
    labelonLayer(layer, list, key) {
      list.forEach(function(json) {
        var geojson = JSON.parse(json.geo);
        maptalks.GeoJSON.toGeometry(geojson, geo => {
          var point = pointOnFeature(polygon(geojson.coordinates));
          let label = new maptalks.Marker(point.geometry.coordinates);
          label.setProperties(json);
          label.setSymbol(mapTool.getLabelStyle({ key: key })).addTo(layer);
        });
      });
    },
    // 定位
    locate() {
      this.map.animateTo({
        center: this.center,
        zoom: this.zoom
      });
    },
    toggleDraw(configs) {
      // 绘制工具开关
      const { drawend, drawstart, mode, mousemove, once, symbol } = configs;
      const layers = this.layers;
      const name = `screen_${mode}`;
      let tool = layers[name];
      if (!tool) {
        tool = new maptalks.DrawTool({ mode, once, symbol }).addTo(this.map);
        if (drawstart) {
          tool.on("drawstart", (param) => {
            drawstart(param);
          });
        }
        if (drawend) {
          tool.on("drawend", (param) => {
            drawend(param);
            if (once) {
              setTimeout(() => {
                this.toggleDraw({
                  drawend: () => {},
                  mode,
                  once,
                  symbol,
                });
              }, 500);
            }
          });
        }
        if (mousemove) {
          tool.on("mousemove", (param) => {
            mousemove(param);
          });
        }
        layers[name] = tool;
        tool.disable();
      }
      if (tool.isEnabled()) {
        // 启用
        tool.disable();
      } else {
        tool.remove();
        tool = new maptalks.DrawTool({ mode, once, symbol }).addTo(this.map);
        if (drawstart) {
          tool.on("drawstart", (param) => {
            drawstart(param);
          });
        }
        if (drawend) {
          tool.on("drawend", (param) => {
            drawend(param);
            if (once) {
              setTimeout(() => {
                // this.toggleDraw({
                //   drawend: () => {},
                //   mode,
                //   once,
                //   symbol
                // })
              }, 500);
            }
          });
        }
        if (mousemove) {
          tool.on("mousemove", (param) => {
            mousemove(param);
          });
        }
        layers[name] = tool;
      }
      return tool;
    },
    // 添加测距控件
    measurement() {
      // 测距时判断一下有没有点击测面积，如果有，禁用
      if (this.areaTool) {
        this.areaTool.disable();
        this.areaTool = "";
      }
      // 先禁用，在开启
      if (this.layers.base_measurement && this.distanceTool) {
        this.distanceTool.disable();
        this.distanceTool = "";
      } else {
        const layer = this.initVectorLayer("measurement", 1);
        this.distanceTool = new maptalks.DistanceTool(
          mapConf.distanceToolsymbol
        ).addTo(this.map);
      }
    },
    area() {
      // 测距时判断一下有没有点击测距离，如果有，禁用
      if (this.layers.base_measurement && this.distanceTool) {
        this.distanceTool.disable();
        this.distanceTool = "";
      }
      // 先禁用，在开启
      if (this.areaTool) {
        this.areaTool.disable();
        this.areaTool = "";
      } else {
        this.areaTool = new maptalks.AreaTool(mapConf.area).addTo(this.map);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.app-map {
  height: 100%;
  /* width: 3080px; */
  /* height: 1080px; */
  z-index: 10;
  position: relative;
}
#app-map {
  height: 100%;
}

#app-map ::v-deep .maptalks-msgBox {
  /* background-color: transparent;
  border-color: transparent;
  height: 230px !important; */
  position: relative !important;
  width: 350px !important;
  left: 190px !important;
  bottom: 160px !important;
  border: none;
}
#app-map ::v-deep .enterprise_title {
  background: #167fff;
}
#app-map ::v-deep .maptalks-msgBox h2 {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  background: #000d51;
  padding-left: 15px;
  text-align: left;
  border-left: #cbf705 2px solid;
}
#app-map ::v-deep .maptalks-msgContent {
  padding: 10px 0;
  background: #000d51;
  p {
    border: 1px solid #122358;
    color: #fff;
    font-size: 18px;
    padding: 5px 0;
    margin: 0;
    span {
      width: 80px;
      display: inline-block;
      text-align: right;
      padding-right: 20px;
    }
    .pop_d {
      width: 150px;
      padding-left: 20px;
      display: inline-block;
      text-align: left;
    }
  }
  // .pop_dept:nth-child(2n) {
  //   background: #56608d !important;
  //   border: none !important;
  // }
}

#app-map ::v-deep .maptalks-msgBox a.maptalks-close,
#app-map ::v-deep .maptalks-msgBox a.maptalks-close:hover {
  background-image: url("./img/close-icon1.png") !important;
  background-size: 100% 100% !important;
  height: 16px !important;
  right: 12px !important;
  top: 12px !important;
  width: 16px !important;
  z-index: 999 !important;
}
.mapmarker {
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
}
#app-map ::v-deep.maptalks-overview {
  width: 150px;
  height: 150px;
  position: absolute;
  /* top: 681px; */
  bottom: 30px;
  right: 713px;
  border-radius: 200px 200px 0 200px;
  overflow: hidden;
  z-index: 11;
}
#app-map ::v-deep.maptalks-overview-button {
  position: absolute;
  /* top: 681px; */
  bottom: 30px;
  right: 713px;
  cursor: pointer;
  background: #1acce6;
  width: 18px;
  height: 18px;
  font: 16px sans-serif;
  text-align: center;
  line-height: 16px;
  border: 1px solid #b4b3b3;
  color: #363539;
  z-index: 11;
}
#app-map ::v-deep.maptalks-control > div:nth-child(2) {
  position: absolute !important;
  top: 1033px !important;
  left: 2760px !important;
  font-size: 20px !important;
  z-index: 999;
}
#app-map ::v-deep.maptalks-control > div:nth-child(2) div div {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  border-left: 2px solid #fff !important;
  border-image: initial;
  border-top: none;
  line-height: 1.5 !important;
  padding: 0px;
  color: #fff !important;
  font-size: 18px !important;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0);
  width: 123px;
}
// #app-map ::v-deep.maptalks-control {
//   div:nth-child(2) {
//     position: absolute !important;
//     overflow: visible !important;
//     bottom: 20px !important;
//     /* left: -153px; */
//     left: 857px !important;
//     z-index: 20 !important;
//     div {
//       div {
//         width: 100px;
//         color: #fff !important;
//         border-right: 2px solid rgb(255, 255, 255) !important;
//         border-bottom: 2px solid rgb(255, 255, 255) !important;
//         border-left: 2px solid rgb(246, 246, 246) !important;
//       }
//     }
//   }
// }

.container {
  width: 100%;
  height: 100%;
}
.content2 {
}
/* .popup-left {
  width: 270px;
}

.onlyleft {
  width: 280px;
}

.popup-leftright {
  width: 500px;
}

.popup-right {
  width: 220px;
  height: 100%;
}
.popup-left {
    width: 270px;

  }

  .onlyleft {
    width: 280px;
  }

  .popup-leftright {
    width: 500px
  }

  .popup-right {
    width: 220px;
    height: 100%;
  }

  .bootstrap {
    background-color: #dae3ec
  }

  .popup-label {
    text-align: right;
    width: 70px;
  }

  .popup-data {
    text-align: left;
    width: 190px;
  }

  .popcenter {
    display: flex;
    justify-content: center;
  }



  .popup-btn {
    width: 80px;
    text-align: center;
    background: #058BE9;
    color: #fff;
    font-weight: 200;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
  }

  .popup-btn:hover {
    background: #172a3d;
  }

  .leaflet-popup-content {
    margin: 10px;
  }

  .popup-row {
    width: 270px;
    padding: 4px;
  }

  .popup-title {
    font-size: 16px;
    color: #ffffff;
    max-width: 240px;
    font-weight: 100;
  }

  .maptalks-msgBox .maptalks-msgContent {
    font-size: 14px;
    float: left;
    padding: 6px;
  }

  .maptalks-msgBox h2 {
    background-color: #143a62e6;
    border-radius: 6px 6px 0 0;
  } */

/* #app-map ::v-deep .content{color:rgb(155, 14, 14);width:190px;height:128px;background-color:#051127;border:1px
solid #0c2c45}
.pop_title{float:left;padding-left:10px;width:180px;height:36px;line-height:36px;font-weight:bold;font-size:16px}
.pop_time{float:left;width:183px;height:30px;margin:0 10px;line-height:36px}
.pop_dept{float:left;padding:5px;max-width:65px;line-height:15px;text-align:center;border:1px
solid #192b41;margin:0 10px}
.pop_arrow{float:left;width:15px;height:24px;line-height:24px;}
.arrow{display:block;width:17px;height:10px;position:absolute;left:50%;margin-left:-5px;bottom:-10px} */
</style>
