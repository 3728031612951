<template>
  <transition
    leave-active-class="animate__animated animate__fadeOutRight"
    enter-active-class="animate__animated animate__fadeInRight"
    mode="out-in"
  >
    <div class="chart-right" v-if="show" ref="rightshow">
      <MD></MD>
      <Trend></Trend>
      <!-- <NOM></NOM> -->
      <!-- <AES></AES> -->
      <CityGDP></CityGDP>
    </div>
  </transition>
</template>

<script>
import MD from "./oceanmain-gdp/oceanmain-gdp";
import NOM from "./number-of-marine/number-of-marine";
// import AES from "./annual-economic-share/annual-economic-share";
import Trend from './trend/trend'

// 各区市主要海洋产业产值
import CityGDP from '../chart-left/city-gdp/city-gpd'


export default {
  name: "chart-right",
  components: {
    MD,
    NOM,
    // AES
    Trend,
    CityGDP,
  },
  data() {
    return {
      show: true,
      areaCount: "",
      proportion: "",
      ranking: ""
    };
  },
  mounted() {
    this.brother = this.$parent.$children[3];
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.chart-right {
  position: absolute;
  right: 0;
  z-index: 11;
  height: 100%;
  width: 600px;
}
</style>
