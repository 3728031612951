import {
    polygon,
    pointOnFeature,
    centroid
} from '@turf/turf'
import * as maptalks from 'maptalks';

let maptool = {};
maptool.getAISPoint = function(opt) {
    const colorOnline = {
        'AIS': '#00cc00',
        'Beidou': '#3366cc',
        'Radar': '#663366',
        'TRACK': '#99ff00'
    }
    const colorOffline = {
        'AIS': '#8FBC8F',
        'Beidou': '#6495ED',
        'Radar': '#9370DB',
        'TRACK': '#99ff00'
    }
    const isRadar = opt.type === 'Radar'
    const color = (opt.online ? colorOnline[opt.type] : colorOffline[opt.type]) || '#c0c0c0'
    const symbol = [{
            markerType: isRadar ? 'ellipse' : 'triangle',
            markerFill: color,
            markerLineColor: 'white',
            markerLineWidth: 1,
            markerWidth: {
                stops: [
                    [10, 0],
                    [11, isRadar ? 20 : 15]
                ]
            },
            markerHeight: {
                stops: [
                    [10, 0],
                    [11, isRadar ? 20 : 30]
                ]
            },
            markerRotation: 360 - opt.angle
        },
        {
            markerType: 'ellipse',
            markerFill: color,
            markerLineColor: 'white',
            markerLineWidth: 0,
            markerWidth: {
                stops: [
                    [10, 5],
                    [11, 0]
                ]
            },
            markerHeight: {
                stops: [
                    [10, 5],
                    [11, 0]
                ]
            }
        }
    ]
    if (isRadar) {
        symbol.push({
            markerDx: 0,
            markerDy: 0,
            markerType: 'bar',
            markerFill: 'white',
            markerLineWidth: 0,
            markerWidth: {
                stops: [
                    [10, 0],
                    [11, 1]
                ]
            },
            markerHeight: {
                stops: [
                    [10, 0],
                    [11, 10]
                ]
            },
            markerRotation: 360 - opt.angle
        })
    }
    return symbol
}
maptool.getPointStyle = function(conf) {
        let src = conf.src;
        let dx = conf.dx || 0;
        let dy = conf.dy || 0;
        let width = conf.width || 20;
        let height = conf.height || 30;
        let textDx = conf.textDx || 10;
        let textDy = conf.textDy || -7;
        let isLabel = !conf.label || conf.label === '' ? false : true;
        let label = conf.label;
        let symbol = [{
            'markerFile': src,
            'markerWidth': width,
            'markerHeight': height,
            'markerDx': dx,
            'markerDy': dy,
        }];
        if (isLabel) {
            symbol.push({
                'textName': '{' + label + '}',
                'textSize': 12,
                'textFill': '#02F1F3',
                textWeight: 400,
                textHorizontalAlignment: 'right',
                textHaloFill: '#0e56a8',
                textHaloRadius: 1,
                textDy: textDy,
                textDx: textDx,
            });
        }
        return symbol;
    },
    maptool.getWSPointStyle = function(conf, prop) {
        let src = conf.src;
        let dx = conf.dx || 0;
        let dy = conf.dy || 0;
        let width = conf.width || 20;
        let height = conf.height || 30;
        let textDx = conf.textDx || 10;
        let textDy = conf.textDy || -7;
        let isLabel = !conf.label || conf.label === '' ? false : true;
        let label = conf.label;
        let symbol = [{
            'markerFile': src,
            'markerWidth': width,
            'markerHeight': height,
            'markerDx': dx,
            'markerDy': dy,
        }];
        if (isLabel) {
            let color = '#ffffff';
            if (prop.vedioCode && prop.vedioCode != '') {
                color = '#02F1F3';
            }
            symbol.push({
                'textName': '{' + label + '}',
                // 'textSize': 12,
                'textFill': color,
                // textOpacity: {
                //     "stops": [
                //         [10, 0],
                //         [11, 1]
                //     ]
                // },
                'textSize': {
                    "stops": [
                        [10, 0],
                        [11, 12],
                    ]
                },
                textHaloOpacity: {
                    "stops": [
                        [10, 0],
                        [11, 1]
                    ]
                },
                textWeight: 400,
                textHorizontalAlignment: 'right',
                textHaloFill: '#0e56a8',
                textHaloRadius: 1,
                textDy: textDy,
                textDx: textDx,
            });
        }
        return symbol;
    },
    maptool.getDoubleLabelStyle = function(conf) { //用于单点两个标注：海湾标注
        let textDx1 = conf.textDx1 || 0;
        let textDy1 = conf.textDy1 || 3;
        let textDx2 = conf.textDx2 || 0;
        let textDy2 = conf.textDy2 || -33;
        let label1 = conf.label1;
        let label2 = conf.label2;
        let s1 = {
            'textName': '{' + label1 + '}',
            'textSize': 18,
            textWeight: 400,
            'textFill': '#101010',
            'textHaloFill': '#fff',
            'textHorizontalAlignment': 'middle',
            'textHaloRadius': 3,
            textDy: textDy1,
            textDx: textDx1,
        };
        let s2 = {
            'textName': '{' + label2 + '}',
            'textSize': 12,
            textWeight: 200,
            'textFill': '#101010',
            'textHaloFill': '#fff',
            'textHaloRadius': 1,
            'textHorizontalAlignment': 'middle',
            textDy: textDy2,
            textDx: textDx2,
        };
        return [s1, s2];
    },

    maptool.getLabelStyle = function(conf) { //单点标注：海湾标注
        let textDx = conf.textDx != null ? conf.textDx : 0;
        let textDy = conf.textDy != null ? conf.textDy : 3;
        let key = conf.key;
        let symbol = {
            'textName': '{' + key + '}',
            'textSize': conf.textSize || 14,
            textWeight: conf.textWeight || 400,
            'textFill': conf.color || '#fff',
            'textHaloFill': conf.textHaloFill || '#101010',
            'textHorizontalAlignment': 'middle',
            'textHaloRadius': 1,
            textDy: textDy,
            textDx: textDx,
            'markerWidth': conf.markerWidth || 80,
            'markerHeight': conf.markerHeight || 26,
            'markerType': conf.markerType || null,
            'markerFillOpacity': conf.markerFillOpacity || 0.8,
            'markerFill': conf.markerFill || '#167fff',
            'markerLineWidth': conf.markerLineWidth || 0
        };
        if (conf.textHalo == false) {
            delete symbol.textHaloFill
            delete symbol.textHaloRadius
        }
        return symbol;
    },
    maptool.getLineSymbol = function(color, alpha, width) {
        color = color || '#ffffff';
        alpha = alpha || 1;
        let symbol = {
            'lineColor': color,
            'lineWidth': width || 2,
            'lineDasharray': null, //线形
            'lineOpacity': alpha
        };
        return symbol;
    };
maptool.getPatternSymbol = function(pattern, fillAlpha, key, lineColor, dash) { //图案base64填充
    let alpha = fillAlpha || 0.8;
    let line = lineColor || '#121212';
    let symbol = [];
    var poly = {
        'polygonOpacity': alpha,
        'polygonPatternFile': pattern,
        lineColor: line,
        'lineWidth': 0.5,
        lineOpacity: 0.8
    };
    if (dash) {
        poly.lineDasharray = dash;
    }
    symbol.push(poly);
    if (key) {
        symbol.push({
            'textName': '{' + key + '}',
            'textSize': 14,
            'textFill': '#fff',
            'textHaloFill': '#101010',
            'textHaloRadius': 2,
            'textHorizontalAlignment': 'middle',
            textWeight: 400,
        });
    }
    return symbol;
};
maptool.getFillSymbol = function(color, lineColor, textColor, key, fillalpha, borderAlpha, lineDash) {
    let symbol = [];
    borderAlpha = borderAlpha ? borderAlpha : 0.8;
    fillalpha = fillalpha || fillalpha == 0 ? fillalpha : 0.8;
    lineColor = lineColor || lineColor == 0 ? lineColor : '#104068';
    textColor = textColor ? textColor : '#FFFFFF';
    lineDash = lineDash ? lineDash : [10, 5, 5]
    symbol.push({
        'polygonFill': color,
        'polygonOpacity': fillalpha,
        'lineColor': lineColor,
        'lineWidth': 0.5,
        lineOpacity: borderAlpha,
        'lineDasharray': lineDash
    });
    if (key && key !== '') {
        symbol.push({
            'textName': '{' + key + '}',
            'textSize': 14,
            'textFill': '#fff',
            'textHaloFill': '#101010',
            'textHaloRadius': 2,
            'textHorizontalAlignment': 'middle',
            textWeight: 400,
        });
    }
    return symbol;
};
maptool.getStyleFromConf = function(conf, label) { //style-conf   图上标注label（可以没有）
    let style = [];
    let that = this;
    if (!conf) {
        return [];
    }
    if (conf instanceof Array) {
        conf.forEach(function(item) {
            let fillType = item.fillType ? item.fillType : item.type;
            let borderColor = item.borderColor ? item.borderColor : undefined;
            let textColor = item.textColor ? item.textColor : undefined;
            let symbol;
            let borderAlpha = item.borderAlpha ? item.borderAlpha : 0.9;
            let fillAlpha = item.fillAlpha ? item.fillAlpha : 0.9;
            let lineDash = item.lineDash
            if (fillType === 'fill') {
                symbol = maptool.getFillSymbol(item.color, borderColor, textColor, false, fillAlpha, borderAlpha, lineDash);
            }
            if (fillType === 'bias') {
                symbol = maptool.getPatternSymbol(item.src, item.alpha, false, item.borderColor, item.lineDash);
            }
            style.push({
                'filter': ['==', item.key, item.value],
                'symbol': symbol
            })
        });
    } else {
        let fillType = conf.fillType ? conf.fillType : conf.type;
        let borderColor = conf.borderColor ? conf.borderColor : undefined;
        let textColor = conf.textColor ? conf.textColor : undefined;
        let symbol;
        let borderAlpha = conf.borderAlpha ? conf.borderAlpha : 0.9;
        let fillAlpha = conf.fillAlpha ? conf.fillAlpha : 0.9;
        let num = conf.num ? conf.num : 1;
        let lineDash = conf.lineDash
        if (fillType === 'fill') {
            symbol = maptool.getFillSymbol(conf.color, borderColor, textColor, false, fillAlpha, borderAlpha, lineDash);
        }
        if (fillType === 'bias') {
            symbol = maptool.getPatternSymbol(conf.src, conf.alpha, false, conf.borderColor, conf.lineDash);
        }
        for (let i = 0; i < num; i++) {
            style.push({ 'symbol': symbol })
        }
    }
    return style;
};

maptool.labelonLayer = function(layer, list, key) {
    list.forEach(function(json) {
        var geojson = JSON.parse(json.geo);
        maptalks.GeoJSON.toGeometry(geojson, geo => {
            var point = pointOnFeature(polygon(geojson.coordinates));
            let label = new maptalks.Marker(point.geometry.coordinates);
            label.setProperties(json);
            label.setSymbol(maptool.getLabelStyle({ key: key })).addTo(layer);
        });
    })
};
maptool.labelonLayerObj = function(layer, list, key) {
    list.forEach(function(json) {
        var geojson = json.geometry;
        maptalks.GeoJSON.toGeometry(geojson, geo => {
            var point = pointOnFeature(polygon(geojson.coordinates));
            let label = new maptalks.Marker(point.geometry.coordinates);
            let text = json.properties[key]
            let markerWidth = text.length * 20 < 60 ? 60 : text.length * 20
            let conf = {
                    key: key,
                    color: '#FFFFFF',
                    textSize: 16,
                    textWeight: 500,
                    textHalo: false,
                    textDy: 0,
                    markerWidth: markerWidth,
                    markerHeight: 26,
                    markerType: 'square',
                    markerFillOpacity: 0.8,
                    markerFill: '#167fff',
                    markerLineWidth: 0
                }
                // debugger
            label.setProperties(json.properties);
            label.setSymbol(maptool.getLabelStyle(conf)).addTo(layer);
        });
    })
};
maptool.getLineStyleFromConf = function(conf) { //style-conf   图上标注label（可以没有）
    let style = [];
    let that = this;
    conf.forEach(function(item) {
        var symbol = maptool.getLineConfSymbol(item);
        style.push({
            'filter': ['==', item.key, item.value],
            'symbol': symbol
        })
    });
    return style;
};
maptool.getLineConfSymbol = function(conf) {
    let color = conf.color || '#ffffff';
    let dashArray = !conf.dashArray ? null : conf.dashArray;
    let symbol = {
        'lineColor': color,
        'lineWidth': 3,
        'lineDasharray': dashArray, //线形
        'lineOpacity': 1
    };
    return symbol;
};
maptool.panelHtml = function(geos, conf) {
    var html = '';
    html += '<div class="map-panel">';
    html += '<div class="popup-left relativeContianer left">';
    geos.forEach((geo) => {
        var attr = geo.getProperties();
        html += '<div class="panel-row left bootstrap">';
        html += '<div class="panel-label ellipse left">' + attr[conf.name] + '</div>';
        html += '<div class="panel-data left">' + "<a  class='panel-btn left' href='javascript:void(0);' onclick=\"popupClick('卫士','" + attr[conf.key] + "');\">" + conf.btn + "</a>" + ' </div></div>';
    });
    html += '</div></div>';
    return html;
}

maptool.wsPopHtml = function(geos, conf) {
    var html = '';
    html += '<div class="map-panel">';
    html += '<div class="popup-left relativeContianer left">';
    geos.forEach((geo) => {
        var attr = geo.getProperties();
        html += '<div class="panel-row left bootstrap">';
        html += '<div class="panel-label ellipse left">' + attr[conf.name] + '</div>';
        html += '<div class="panel-data left">' + "<a  class='panel-btn left' href='javascript:void(0);' onclick=\"popupClick('卫士','" + attr[conf.key] + "');\">" + conf.btn + "</a>" + ' </div></div>';
    });
    html += '<div class="panel-row left bootstrap">';
    html += '<div class="panel-data right">' + "<a  class='panel-btn left' style='width:120px;background:#083d62' href='javascript:void(0);' onclick=\"popupClick('卫士系统','code');\">海洋卫士智能监管</a>" + ' </div></div>';
    html += '</div></div>';
    let html_title = '<div class="popup-title ellipse left">[海洋卫士] 监控列表</div>';
    return {
        autoOpenOn: false,
        title: html_title,
        content: html
    };
};

maptool.getOtherPopup = function(type, prop, conf) {
    let titleKey = conf.title;
    let column = conf.column;
    let btns = conf.btn;
    let isBtn = btns instanceof Array && btns.length > 0 ? true : false;
    let htmlContent = '';
    htmlContent = '<div class="popup-onlyleft relativeContianer left">';
    htmlContent += '<div class="popup-left relativeContianer left">';
    let index = 0;
    column.forEach(function(col) {
        index++;
        if (index % 2 == 1) {
            htmlContent += '<div class="popup-row left bootstrap">';
        } else {
            htmlContent += '<div class="popup-row left">';
        }
        htmlContent += '<div class="popup-label left">' + col.name + '：</div>';
        if (col.info === 'full') {
            htmlContent += '<div class="popup-data left"> ' + prop[col.key] + ' ' + col.unit + '</div></div>';
        } else {
            htmlContent += '<div class="popup-data left ellipse">' + prop[col.key] + ' ' + col.unit +
                '</div></div>';
        }
    })
    if (isBtn) {
        htmlContent += '<div class="popup-row popcenter left">'
        btns.forEach(function(btn) {
            htmlContent += "<a  class='popup-btn left' href='javascript:void(0);' onclick=\"popupClick('" + btn.name +
                "','" + prop[btn.key] + "');\">" + btn.name + "</a>";
        });
        htmlContent += '</div>';
    }
    htmlContent += '<div class="popup-row left"><div style="clear:both"></div></div>';
    htmlContent += "</div>";
    htmlContent += "</div>";
    let html_title = '<div class="popup-title ellipse left">[' + type + ']</div>';
    return {
        autoOpenOn: false,
        title: html_title,
        content: htmlContent
    };
};

export default maptool