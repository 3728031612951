<template>
  <transition leave-active-class="animate__animated animate__fadeOutLeft"
              enter-active-class="animate__animated animate__fadeInLeft"
              mode="out-in">
    <div class="chart-left"
         v-show="show">
      <!-- <swiper ref="mySwiper" :options="swiperOptions" class="swiperbox">
        <swiper-slide class="swiper-slide">
          <OceanGDP></OceanGDP>
          <YearGDP></YearGDP>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <OceanGDP></OceanGDP>
          <YearGDP></YearGDP>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> -->
      <el-select v-model="year"
                 placeholder="请选择"
                 class="select-wrap"
                 @change="handleYear"
                 :popper-append-to-body="false">
        <el-option v-for="item in fullYears"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
      <OceanGDP2 :index="index"
                 :year="year"
                 @getYears="getYears"></OceanGDP2>
      <YearGDP :index="index"
               :year="year"
               :years="years"></YearGDP>
      <Classify :index="index"
                :years="years"
                :year="year"></Classify>
      <!-- <CityGDP></CityGDP> -->
    </div>
  </transition>
</template>

<script>
// import 'swiper/css/swiper.min.css';
// import { Swiper, SwiperSlide } from "swiper";
// import "swiper/css";
// import CityGDP from "./city-gdp/city-gpd";
import OceanGDP2 from "./ocean-gpd/ocean-gdp2";
import YearGDP from "./year-gdp/year-gdp";
import Classify from './classify-oftype/classify-oftype'

export default {
  name: "chart-left",
  components: {
    // CityGDP,
    OceanGDP2,
    YearGDP,
    Classify
  },
  data () {
    return {
      index: 0,
      years: [],
      fullYears: [],
      year: '2016',
      show: true,
      brother: "",

    };
  },
  computed: {

  },
  mounted () {
    this.brother = this.$parent.$children[3];
  },
  methods: {
    chartLoop () {
      const total = this.years.length - 1
      let dir = 'add'
      this.timer2 = setInterval(() => {
        if (this.index === total) {
          dir = 'plus'
        }
        if (this.index === 0) {
          dir = 'add'
        }
        this.index = (dir === 'add') ? this.index + 1 : this.index - 1
        this.year = this.years[this.index].value

      }, 3000);
    },
    handleYear (year) {
      this.year = year
      const all = this.years.map(i => i.value)
      const index = all.indexOf(year)
      if (index >= 0) {
        this.index = index
      }
    },
    getYears (years) {
      this.years = years
      this.fullYears = JSON.parse(JSON.stringify(years))
      console.log(this.fullYears, 'aaaaaa')
      // this.fullYears.unshift({
      //   label: '全部',
      //   value: 'all'
      // })
      this.$nextTick(() => {
        this.chartLoop()
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.chart-left {
  position: absolute;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 620px;
}
.select-wrap {
  position: absolute;
  right: 93px;
  top: 87px;
  z-index: 999;
  width: 90px;
  border: none;
}
// .swiperbox {
//   width: 100%;
//   // height: 700px;
// }
::v-deep .el-select-dropdown {
  border-radius: 5px;
  border: none !important;
}
::v-deep .el-scrollbar__wrap {
  overflow: hidden;
}
::v-deep .el-input__inner {
  background-color: transparent !important;
  border: none !important;
}
::v-deep .popper__arrow::after {
  border-bottom-color: transparent !important;
}
</style>
