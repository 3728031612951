let deepCopy = function(newer) {
    var obj = {};
    for (var i in newer) {
        obj[i] = typeof newer[i] == 'object' ? deepCopy(newer[i]) : newer[i];
    }
    return obj;
};



let popData = function(type, prop) {
    function fp(key) { // format prop's key
        return prop[key] ? prop[key] : "-"
    }
    let pop = {
        type: "",
        status: false,
        title: '',
        pic: '',
        prop: {},
        spans: [],
        labels: [],
        btns: []
    };
    pop.type = type;
    pop.prop = prop;
    switch (type) {
        case "海岸线":
            pop.labels = [{
                name: "岸线情况",
                value: fp('info')
            }, {
                name: "数据来源",
                value: fp('source')
            }];
            pop.btns = [{
                type: "岸线监管",
                name: "岸线监管"
            }];
            break;
        case "渔船AIS":
        case "北斗":
        case "雷达":
            pop.title = fp('cm');
            pop.spans = [{
                name: "船首向",
                value: fp('zhx'),
                unit: " °"
            }, {
                name: "航速",
                value: fp('sog'),
                unit: "节"
            }, {
                name: "经度",
                value: fp('jd').toString().substring(0, 6),
                unit: " °"
            }, {
                name: "纬度",
                value: fp('wd').toString().substring(0, 5),
                unit: " °"
            }];
            pop.labels = [{
                name: "MMSI",
                value: fp('mmsi')
            }, {
                name: "船东",
                value: fp('ownerName')
            }, {
                name: "联系方式",
                value: fp('ownerTel')
            }, {
                name: "最后更新",
                value: fp('gpsdwsj')
            }];
            pop.btns = [{
                type: "渔船监管",
                name: "渔船监管"
            }];
            break;
        case "无人机":
            pop.title = fp('brand');
            pop.spans = [{
                name: "经度",
                value: fp('lon').toString().substring(0, 6),
                unit: " °"
            }, {
                name: "纬度",
                value: fp('lat').toString().substring(0, 5),
                unit: " °"
            }];
            pop.labels = [{
                name: "类型",
                value: fp('uavType')
            }, {
                name: "编号",
                value: fp('uavname')
            }, {
                name: "更新时间",
                value: fp('time')
            }];
            pop.btns = [];
            break;
        case "浮标":
            pop.title = fp('equipName');
            pop.pic = fp('fileId');
            pop.status = true;
            pop.labels = [{
                name: "站点用途",
                value: fp('purpose')
            }, {
                name: "站点编号",
                value: fp('commMode')
            }, {
                name: "权属单位",
                value: fp('manufacturer')
            }, {
                name: "使用单位",
                value: fp('dept')
            }, {
                name: "所属海区",
                value: fp('seaArea')
            }];
            pop.btns = [{
                type: "浮标详情",
                name: "浮标详情"
            }];
            break;
        case "海洋站":
            pop.title = fp('equipName');
            pop.pic = fp('fileId');
            pop.status = true;
            pop.labels = [{
                name: "站点用途",
                value: fp('purpose')
            }, {
                name: "站点编号",
                value: fp('commMode')
            }, {
                name: "权属单位",
                value: fp('manufacturer')
            }, {
                name: "使用单位",
                value: fp('dept')
            }, {
                name: "所属海区",
                value: fp('seaArea')
            }];
            pop.btns = [{
                type: "海洋站详情",
                name: "海洋站详情"
            }];
            break;
        case "功能区划":
            pop.title = fp('areaName');
            pop.labels = [{
                name: "区划名称",
                value: fp('areaName')
            }, {
                name: "区划类型",
                value: fp('areaType')
            }, {
                name: "区划代码",
                value: fp('code')
            }, {
                name: "区划面积",
                value: fp('funArea') + " km²"
            }, {
                name: "生态保护",
                value: fp('enProtection'),
                info: 'full'
            }];
            break;
        case "海岛":
            pop.title = fp('standardName');
            pop.pic = fp('fileId')
            pop.labels = [{
                name: "所属区划",
                value: fp('townName')
            }, {
                name: "岸线长度",
                value: fp('coastlineLen') + " km²"
            }, {
                name: "海岛面积",
                value: fp('area') + ' m²'
            }, {
                name: "区划面积",
                value: fp('funArea') || +" km²"
            }, {
                name: "常住人口",
                value: fp('permanentPopolation') + " 人",
            }];
            break;
        case "水质调查站":
            pop.title = fp('monitorStation');
            pop.spans = [{
                name: "水深",
                value: fp('waterDepth'),
                unit: " m"
            }, {
                name: "透明度",
                value: fp('transparency'),
                unit: " m"
            }, {
                name: "水温",
                value: fp('waterTemp'),
                unit: " ℃"
            }, {
                name: "盐度",
                value: fp('salinity'),
                unit: " "
            }];
            pop.labels = [{
                name: "航次",
                value: fp('voyage')
            }, {
                name: "备注",
                value: fp('remark')
            }];
            pop.btns = [{
                type: "水质调查站详情",
                name: "调查站详情"
            }, {
                type: "水质调查站分析评价",
                name: "分析评价"
            }];
            break;
        case "沉积物调查":
            pop.title = fp('monitorStation');
            pop.spans = [{
                name: "有机碳",
                value: fp('organicCarbon'),
                unit: " %"
            }, {
                name: "酸碱度",
                value: fp('ph'),
                unit: " "
            }, {
                name: "温度",
                value: fp('temperature'),
                unit: " ℃"
            }, {
                name: "石油类",
                value: fp('petroleum'),
                unit: " 10-6"
            }];
            pop.labels = [{
                name: "航次",
                value: fp('voyage')
            }, {
                name: "备注",
                value: fp('remark')
            }];
            pop.btns = [{
                type: "沉积物调查站详情",
                name: "调查站详情"
            }, {
                type: "沉积物调查站分析评价",
                name: "分析评价"
            }];
            break;
        case "渔港":
            pop.title = fp('wharfName');
            pop.spans = [{
                name: "吞吐量",
                value: fp('handlingCapacity'),
                unit: " 万吨"
            }, {
                name: "泊位数",
                value: fp('berthNum'),
                unit: " 个"
            }];
            pop.labels = [{
                name: "码头地址",
                value: fp('wharfAddress')
            }, {
                name: "用途",
                value: fp('theUse')
            }, {
                name: "建成时间",
                value: fp('buildDateStr')
            }, {
                name: "停靠船舶",
                value: fp('useTypeMembership')
            }];
            break;
        case "苗种企业":
            pop.title = fp('companyName');
            pop.labels = [{
                name: "企业名称",
                value: fp('companyName')
            }, {
                name: "企业地址",
                value: fp('companyAddress')
            }];
            break;
        case "养殖企业":
            pop.title = fp('companyName');
            pop.labels = [{
                name: "企业名称",
                value: fp('companyName')
            }, {
                name: "企业地址",
                value: fp('companyAddress')
            }, {
                name: "企业法人",
                value: fp('legalPersonName')
            }, {
                name: "养殖面积",
                value: fp('breedAquaticsArea') + " 公顷"
            }];
            break;
        case "港口":
            pop.title = fp('portName');
            pop.labels = [{
                name: "港口类型",
                value: fp('portType')
            }, {
                name: "吞吐量",
                value: fp('totalHandleCapacity') + " 亿吨"
            }, {
                name: "港区面积",
                value: fp('portArea') + " km²"
            }, {
                name: "泊位数",
                value: fp('berthNum') + " 个"
            }];
            break;
        case "涉海科研机构":
            pop.title = fp('institutionName');
            pop.labels = [{
                name: "机构类型",
                value: fp('institutionType')
            }, {
                name: "机构地址",
                value: fp('address')
            }, {
                name: "机构职能",
                value: fp('institutionInfo'),
                info: 'full'
            }];
            break;
        case "涉海企业":
            pop.title = fp('companyName');
            pop.labels = [{
                name: "企业名称",
                value: fp('companyName')
            }, {
                name: "企业地址",
                value: fp('address')
            }, {
                name: "企业职能",
                value: fp('companyInfo'),
                info: 'full'
            }];
            break;
        case "生态红线":
            pop.title = fp('name');
            pop.labels = [{
                name: "区域类型",
                value: fp('type')
            }, {
                name: "区域类别",
                value: fp('category')
            }, {
                name: "区域代码",
                value: fp('code'),
                info: 'full'
            }];
            break;
        case "海域确权":
            pop.title = fp('pro_name');
            pop.labels = [{
                name: "项目名称",
                value: fp('pro_name'),
                info: 'full'
            }, {
                name: "使用权人",
                value: fp('SYQR')
            }, {
                name: "用海级别",
                value: fp('sea_level')
            }, {
                name: "证书编号",
                value: fp('certi_code')
            }, {
                name: "用海方式",
                value: fp('用海方式')
            }];
            break;
        case "保护区":
            pop.title = fp('proName');
            pop.labels = [{
                name: "区域类型",
                value: fp('proPartition'),
                info: 'full'
            }, {
                name: "区域级别",
                value: fp('proGrade')
            }, {
                name: "区域面积",
                value: fp('proArea') + " 亩"
            }];
            break;
        case "海湾线":
            pop.title = fp('bay_name');
            pop.labels = [{
                name: "包含区域",
                value: fp('bay_supp')
            }, {
                name: "区级湾长",
                value: fp('QJWZ')
            }, {
                name: "湾区详情",
                value: fp('detail'),
                info: 'full'
            }];
            pop.btns = [{
                type: "湾长管理",
                name: "湾长管理"
            }];
            break;
        case "海洋牧场":
            pop.title = fp('oriName');
            pop.labels = [{
                name: "公司名称",
                value: fp('constCom')
            }, {
                name: "牧场面积",
                value: fp('area')
            }, {
                name: "牧场投资",
                value: fp('money'),
            }, {
                name: "牧场备注",
                value: fp('remarks'),
                info: 'full'
            }];
            pop.btns = [{
                type: "牧场监管",
                name: "牧场监管"
            }];
            break;
        default:
            break
    }
    return pop;
};
// 轮循函数
// usage: wait(fn.bind(ctx, ...args), 10000);
function wait(fn, timeout, tick) {
    timeout = timeout || 5000;
    tick = tick || 250;
    var timeoutTimer = null;
    var execTimer = null;

    return new Promise(function(resolve, reject) {

        timeoutTimer = setTimeout(function() {
            clearTimeout(execTimer);
            reject(new Error('polling fail because timeout'));
        }, timeout);

        tickHandler(fn);

        function tickHandler(fn) {
            var ret = fn();
            if (!ret) {
                execTimer = setTimeout(function() {
                    tickHandler(fn);
                }, tick)
            } else {
                clearTimeout(timeoutTimer);
                resolve();
            }
        }
    });
}



export {
    wait,
    deepCopy,
    popData,
}