function initCanvas(width, height, className) {
  var canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  canvas.style.position = 'absolute';
  canvas.style.left = '0px';
  canvas.style.top = '0px';

  if (className) {
    canvas.className = className;
  }

  document.body.appendChild(canvas);
  return canvas;
}

function destroyInnerCanvas(canvas, destroyCanvas) {
  canvas && destroyCanvas && document.body.removeChild(canvas);
}

//右斜线
function drawSlash(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(0, width);
  ctx.lineTo(height, 0);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}

//左斜线
function drawBackSlash(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(width, height);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}
//横线不铺满
function drawHorizomtal(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(0, height / 2);
  ctx.lineTo(width, height / 2);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}
//横线铺满
function drawFull(ctx, width, height, fill) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
}
//竖线
function drawVertical(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(width / 2, 0);
  ctx.lineTo(width / 2, height);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}

//左右斜线
function drawSlashBackSlash(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(width / 2, 0);
  ctx.lineTo(0, height / 2);
  ctx.lineTo(width / 2, height);
  ctx.lineTo(width, height / 2);
  ctx.closePath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}

//方形
function drawSquare(
  ctx,
  width,
  height,
  fill,
  color,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width / 2, height / 2);
}

//菱形
function drawDiamond(
  ctx,
  width,
  height,
  fill,
  color,
  lineWidth,
) {
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, width, height);
  ctx.beginPath();
  ctx.moveTo(width / 2, height / 4);
  ctx.lineTo(width / 4, height / 2);
  ctx.lineTo(width / 2, height * 0.75);
  ctx.lineTo(width * 0.75, height / 2);
  ctx.closePath();
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = color;
  ctx.fill();
}

export default function polygonPattern(options) {
  var {
    width = 12,
      height = 12,
      color = 'rgba(0,0,0,1)',
      type,
      destroyCanvas = true,
      fill = 'rgba(0,0,0,0)',
      lineWidth = 1,
  } = options;

  //初始化canvas
  var canvas = initCanvas(width, height);
  var ctx = canvas.getContext('2d');

  //初始化颜色，如果color没有，则color为黑色
  if (!color) {
    color = 'rgba(0,0,0,1)';
  }

  //画图案
  if (ctx) {
    switch (type) {
      case 'slash':
        drawSlash(ctx, width, height, fill, color, lineWidth);
        break;
      case 'back-slash':
        drawBackSlash(ctx, width, height, fill, color, lineWidth);
        break;
      case 'horizomtal':
        drawHorizomtal(ctx, width, height, fill, color, lineWidth);
        break;
      case 'full':
        drawFull(ctx, width, height, fill);
        break;
      case 'vertical':
        drawVertical(ctx, width, height, fill, color, lineWidth);
        break;
      case 'slash-back-slash':
        drawSlashBackSlash(ctx, width, height, fill, color, lineWidth);
        break;
      case 'square':
        drawSquare(ctx, width, height, fill, color);
        break;
      case 'diamond':
        drawDiamond(ctx, width, height, fill, color, lineWidth);
        break;
    }
  }
  //转base64
  const base64 = canvas.toDataURL('image/png', 1);
  //消除canvas
  destroyInnerCanvas(canvas, destroyCanvas);
  //return base64
  return base64;
}
