<template>
  <div class="city-gdp">
    <p class="title">各区市主要海洋产业产值</p>
    <div id="myChart-city"></div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "city-gdp",
  data () {
    return {
      results: {
        city: [],
        lastYear: [],
        nowYear: [],
        myChart: "",
        sumNumber: [],
        dataLength: 0,
        timer: '',
        country: [],
        province: [],
        province18: [],
        province23: [],
        province22: [],
        num: 3,

      }
    };
  },
  mounted () {
    this.queryCantonal();
  },
  updated () {
    this.drawLine();
  },
  beforeDestroy () {
    window.clearInterval(this.timer)
  },
  methods: {

    queryCantonal () {
      this.$axios.post(api.cantonal).then(res => {

        this.results.city = res.data.result.city;
        this.results.value2019 = res.data.result.lastYear;
        this.results.value2020 = res.data.result.nowYear;
        // this.drawLine();
        this.echZZTXr(res.data.result);
      });
    },

    echZZTXr (data) {
      let that = this;
      this.myChart = that.$echarts.init(
        document.getElementById("myChart-city"),
        "light"
      );
      var city = data.city;
      var country = data.value2019;
      var province = data.value2020;
      var province18T = data.value2018;
      var province23T = data.value2023;
      var province22T = data.value2022;
      this.city = data.city;
      this.province = data.value2020;
      this.country = data.value2019;
      this.province18 = data.value2018;
      this.province23 = data.value2023;
      this.province22 = data.value2022;
      this.province21 = data.value2021;
      let dom = 800;
      let barWidth = dom / 40;
      let manColors = [];
      let womanColors = [];
      let eightColors = [];
      let sevenColors = [];
      let sixColors = [];
      let newColors = [];
      for (let i = 0; i < 10; i++) {

        newColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#fa8863" // 最左边
            },
            {
              offset: 0.5,
              color: "#d27050" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#c26445" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#d3623a"
            }
          ]
        });
        womanColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#7bbfea" // 最左边
            },
            {
              offset: 0.5,
              color: "#33a3dc" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#228fbd" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#2468a2"
            }
          ]
        });
        eightColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#3e55e0" // 最左边
            },
            {
              offset: 0.5,
              color: "#2742e8" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#1a30b4" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#122597"
            }
          ]
        });
        sevenColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#9fe6b8" // 最左边
            },
            {
              offset: 0.5,
              color: "#44d978" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#398654" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#13a245"
            }
          ]
        });
        sixColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#ffdb5c" // 最左边
            },
            {
              offset: 0.5,
              color: "#f1db8d" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#c29909" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#a68a28"
            }
          ]
        });
        manColors.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#73fcff" // 最左边
            },
            {
              offset: 0.5,
              color: "#86eef1" // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#5ad6d9" // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#3dc8ca"
            }
          ]
        });
      }

      this.myChart.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            formatter: function (p) {
              let div = `

                ${p[0].seriesName}:${p[0].value}亿元
                <br/>
                ${p[3].seriesName}:${p[3].value}亿元
                <br/>
                ${p[6].seriesName}:${p[6].value}亿元
                <br/>
                ${p[9].seriesName}:${p[9].value}亿元
                <br/>
                ${p[12].seriesName}:${p[12].value}亿元
                 <br/>
                ${p[15].seriesName}:${p[15].value}亿元

            `;
              return div;
            }
          },
          color: ["#73fcff", "#7bbfea", "#2148a3",],
          legend: {
            selectedMode: false,
            textStyle: {
              color: "#fff",
              fontSize: '16'
            },
            top: '3%',
          },
          grid: {
            top: '15%',
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true
          },

          xAxis: {

            type: "category",
            data: city.slice(0, 3),
            axisLine: {
              //这是y轴文字颜色
              lineStyle: {
                color: "#fff"
              }
            },
            axisLabel: {
              margin: 24,
              show: true,
              // interval: 0, //显示所有X轴信息
              rotate: 0, //倾斜角度
              textStyle: {
                fontSize: 16,
                color: "#C6E0F2",
              }
            }

          },
          yAxis: {
            name: '亿元',
            type: "value",
            // boundaryGap: [0, 0.01],
            // minInterval: 200,  //Y轴刻度尺间隔

            axisLine: {
              //这是y轴文字颜色
              lineStyle: {
                color: "#fff"
              }
            },
            axisLabel: {
              show: true,
              // interval: 0, //显示所有X轴信息
              rotate: 0, //倾斜角度
              textStyle: {
                fontSize: 16,
                color: "#C6E0F2"
              },
              // 刻度尺后面的单位
              // formatter: '{value} 个'
            }

          },
          series: [
            {
              name: "2018",
              type: "bar",
              data: this.province18.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return manColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2018",
              type: "pictorialBar",
              data: this.province18.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-320%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return manColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2018",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.province18.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-320%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return manColors[params.dataIndex % 7].colorStops[0].color;
                  }
                }
              }
            },
            // 2
            {
              name: "2019",
              type: "bar",
              data: this.country.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return womanColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2019",
              type: "pictorialBar",
              data: this.country.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-200%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return womanColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2019",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.country.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-200%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return womanColors[params.dataIndex % 7].colorStops[0]
                      .color;
                  }
                }
              }
            },
            // 3
            {
              name: "2020",
              type: "bar",
              data: this.province.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return eightColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2020",
              type: "pictorialBar",
              data: this.province.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-70%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return eightColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2020",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.province.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["-70%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return eightColors[params.dataIndex % 7].colorStops[0]
                      .color;
                  }
                }
              }
            },
            // 4
            {
              name: "2021",
              type: "bar",
              data: this.province21.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return sevenColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2021",
              type: "pictorialBar",
              data: this.province21.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["60%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return sevenColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2021",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.province21.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["60%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return sevenColors[params.dataIndex % 7].colorStops[0]
                      .color;
                  }
                }
              }
            },
            // 5
            {
              name: "2022",
              type: "bar",
              data: this.province22.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return sixColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2022",
              type: "pictorialBar",
              data: this.province22.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["200%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return sixColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2022",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.province22.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["200%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return sixColors[params.dataIndex % 7].colorStops[0]
                      .color;
                  }
                }
              }
            },
            // 6
            {
              name: "2023",
              type: "bar",
              data: this.province23.slice(0, this.num),
              barWidth: barWidth,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return newColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 2,
              name: "2023",
              type: "pictorialBar",
              data: this.province23.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["325%", "50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  color: function (params) {
                    return newColors[params.dataIndex % 7];
                  }
                }
              }
            },
            {
              z: 3,
              name: "2023",
              type: "pictorialBar",
              symbolPosition: "end",
              data: this.province23.slice(0, this.num),
              symbol: "diamond",
              symbolOffset: ["325%", "-50%"],
              symbolSize: [barWidth, barWidth * 0.5],
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  color: function (params) {
                    return newColors[params.dataIndex % 7].colorStops[0]
                      .color;
                  }
                }
              }
            },

          ]
        },
        true
      );

      // this.zZTEchVal = EchVal;
      this.setTn();
    },
    setTn () {
      // 饼图
      let that = this;
      let lastIndex = 0;
      let currentIndex = 0;

      // 柱状
      var city = this.city;
      var country = this.country;
      var province = this.province;
      var province18 = this.province18;
      var province23 = this.province23;
      var province22 = this.province22;
      var province21 = this.province21;
      var numVal = 3;
      var i = 0;
      var cityT = [];
      var countryT = [];
      var provinceT = [];
      var province18T = [];
      var province23T = [];
      var province22T = [];
      var province21T = [];
      this.timer = setInterval(function () {
        // 柱状
        if (city.length - i > numVal) {
          for (var j = 0; j < numVal; j++) {
            cityT[j] = city[i + j];
            countryT[j] = country[i + j];
            provinceT[j] = province[i + j];
            province18T[j] = province18[i + j];
            province23T[j] = province23[i + j];
            province22T[j] = province22[i + j];
            province21T[j] = province21[i + j];
          }
        }
        if (city.length - i <= numVal && i < city.length) {
          for (var j = 0; j < numVal; j++) {
            cityT[j] = city[i + j >= city.length ? i + j - city.length : i + j];

            countryT[j] =
              country[i + j >= city.length ? i + j - city.length : i + j];
            provinceT[j] =
              province[i + j >= city.length ? i + j - city.length : i + j];

            province18T[j] =
              province18[i + j >= city.length ? i + j - city.length : i + j];
            province23T[j] =
              province23[i + j >= city.length ? i + j - city.length : i + j];
            province22T[j] =
              province22[i + j >= city.length ? i + j - city.length : i + j];
            province21T[j] =
              province21[i + j >= city.length ? i + j - city.length : i + j];
          }
        }
        i++;
        if (i == city.length) i = 0;
        if (that.myChart) {
          that.myChart.setOption({
            xAxis: [
              {
                data: cityT
              }
            ],
            series: [

              {
                data: province18T
              },
              {
                data: province18T
              },
              {
                data: province18T
              },
              {
                data: countryT
              },
              {
                data: countryT
              },
              {
                data: countryT
              },
              {
                data: provinceT
              },
              {
                data: provinceT
              },
              {
                data: provinceT
              },
              {
                data: province21T
              },
              {
                data: province21T
              },
              {
                data: province21T
              },
              {
                data: province22T
              },
              {
                data: province22T
              },
              {
                data: province22T
              },
              {
                data: province23T
              },
              {
                data: province23T
              },
              {
                data: province23T
              },
            ]
          });
        }
        // 饼图
        // if (that.bTEchVal) {
        //   that.bTEchVal.dispatchAction({
        //     type: "downplay",
        //     seriesIndex: 0,
        //     dataIndex: lastIndex
        //   });
        //   that.bTEchVal.dispatchAction({
        //     type: "highlight",
        //     seriesIndex: 0,
        //     dataIndex: currentIndex
        //   });
        //   that.bTEchVal.dispatchAction({
        //     type: "showTip",
        //     seriesIndex: 0,
        //     dataIndex: currentIndex
        //   });
        // }

        // lastIndex = currentIndex;
        // ++currentIndex;

        // if (currentIndex >= that.dataLength) {
        //   currentIndex = 0;
        // }
      }, 3000);
    },
  }
};
</script>
<style lang="scss" scoped>
#myChart-city {
  width: 100%;
  height: 100%;
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
.city-gdp {
  height: 32%;
  width: 100%;
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  // height: calc(31% - 85px);
}
</style>
