<template>
  <div class="map-legend">
    <ul class="legend-ul">
      <li class="legend-li">
        <el-popover
         style="color:#fff!important"
          placement="left"
          title="请输入企业名称"
          width="200"
          trigger="click"
          content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="searchenterprise"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"
            value-key='name'
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.location }}</span>
            </template>
          </el-autocomplete>
          <i
            slot="reference"
            class="iconfont iconjiansuo"
          ></i>
        </el-popover>
      </li>
      <!-- 视图保存和切换--中心点切换 -->
      <!-- <div :class="['zoomCenteroffset', plugins.offset]" v-show="showChart" class="zoomCenterClass"> -->
      <div :class="['zoomCenteroffset']" class="zoomCenterClass"></div>
      <li>
        <el-popover placement="left" width="518" trigger="hover">
          <el-button
            style="margin-bottom:10px"
            @click="zoomAndCenter()"
            size="mini"
            type="primary"
            >保存当前视图</el-button
          >
          <el-table
            :header-cell-style="{ background: '#104FA2' }"
            class="table"
            @row-click="openDetails"
            :data="stList"

            style="width: 518px;"
          >
            <el-table-column
              align="center"
              width="100"
              property="createTime"
              label="创建时间"
            ></el-table-column>
            <el-table-column
              align="center"
              width="80"
              property="zoom"
              label="缩放等级"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              property="longitude"
              label="经度"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              property="latitude"
              label="纬度"
            ></el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              label="操作" >
              <template slot-scope="scope">
                <el-button type="danger" plain @click="delwatch(scope.row)">删除</el-button>
              </template>

            </el-table-column>
          </el-table>
          <el-button
            class="popBtn"
            size="mini"
            type="primary"
            slot="reference"
            icon="el-icon-picture-outline"
          ></el-button>
        </el-popover>
      </li>
      <li>
        <el-popover placement="left" width="400" trigger="hover">
          <div class="jwdClass">
            <span>经度</span>
            <el-input
              v-on:input="lonLat.lon = lonLat.lon.replace(/[^\d.]/g, '')"
              style="width=200px"
              placeholder="请输入经度"
              v-model="lonLat.lon"
              clearable
            >
            </el-input>
          </div>
          <div class="jwdClass">
            <span>纬度</span>
            <el-input
              v-on:input="lonLat.lat = lonLat.lat.replace(/[^\d.]/g, '')"
              style="width=200px"
              placeholder="请输入纬度"
              v-model="lonLat.lat"
              clearable
            >
            </el-input>
          </div>
          <div style="width:100%">
            <el-button
              style="margin-top:10px;float:right;margin-right:10px"
              @click="mapCenterClick()"
              size="mini"
              type="primary"
              >定位</el-button
            >
            <el-button
              style="margin-top:10px;float:right;margin-right:10px"
              @click="
                lonLat.lat = '';
                lonLat.lon = '';
              "
              size="mini"
              >清空</el-button
            >
          </div>

          <el-button
            class="popBtn"
            size="mini"
            type="primary"
            slot="reference"
            icon="el-icon-map-location"
          ></el-button>
        </el-popover>
      </li>
      <li>
        <ul class="legend-li iconfont icontuli">
          <li class="seamap">
            <span class="seamap-span" @click="baseLayer('地图')">地图</span>
            <span class="seamap-span" @click="baseLayer('遥感')">遥感</span>
            <span class="seamap-span" @click="baseLayer('海图')">海图</span>
            <span class="seamap-span" @click="baseLayer('深色')">深色</span>
          </li>
        </ul>
      </li>
      <li
        v-for="(item, index) in legend"
        :class="['legend-li', item.active]"
        :key="index"
        @click="toggleTrawler(item.list, index)"
      >
        <span :class="[item.iconfont, 'iconfont']" :title="item.list"> </span>
        <!-- <span class="legend-text" >{{item.list}}</span> -->
        <!-- <span v-for="(_marker, _index) in item.marker"
          :class="_marker.type"
          :key="_index">{{ _marker.content }}
        </span> -->

        <!-- <span v-show="total[item.prop]">({{ total[item.prop] }})
        </span> -->
      </li>
    </ul>

    <!-- 鼠标经纬度 -->
    <!-- <div  @click="mapCenterClick()" class="mouse-position">

      <div class="item">

        <span class="label">经度</span>

        <span class="value">{{ mouse.longitude }}</span>

      </div>

      <div class="item">

        <span class="label">纬度</span>

        <span class="value">{{ mouse.latitude }}</span>

      </div>

    </div> -->
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "map-legend",
  data() {
    return {
      basemap: [
        {
          地图: "low",
          遥感: "image",
          海图: "sea",
          深色: "deep"
        }
      ],
      legend: [
        {
          active: "active",
          list: "地图复位",
          iconfont: "iconfont icondingwei1",
          newm: "qda2"
        },
        {
          active: "active",
          list: "缩小",
          iconfont: "iconfont iconjian",
          newm: "qda2"
        },
        {
          active: "active",
          list: "放大",
          iconfont: "iconfont icon-jia",
          newm: "qda2"
        },
        // {
        //   active: '',
        //   list:'陆地海域',
        //   iconfont:'iconfont iconhuanjing',
        //   newm:'qda2'
        // },
        {
          active: "active",
          list: "渔区",
          iconfont: "iconfont iconbuyu",
          newm: "qda4"
        },
        {
          active: "active",
          list: "领海基线",
          iconfont: "iconfont icontaishiganzhi",
          newm: "qda3"
        },
        // {
        //   active: 'active',
        //   list:'海岸线',
        //   iconfont:'iconfont icongaojing1',
        //   newm:'qda3'
        // },
        // {
        //   active: 'active',
        //   list:'海区',
        //   iconfont:'iconfont icongaojing1',
        //   newm:'qda3'
        // },
        // {
        //   active: 'active',
        //   list:'功能区划',
        //   iconfont:'iconfont iconjianguanmoshi',
        //   newm:'qda4'
        // },
        // {
        //   active: 'active',
        //   list:'海湾',
        //   iconfont:'iconfont iconquyu',
        //   newm:''
        // },
        // {
        //   active: 'active',
        //   list:'海域确权',
        //   iconfont:'iconfont iconmsnui-map-locate',
        //   newm:'qda4'
        // },
        // {
        //   active: 'active',
        //   list:'生态红线',
        //   iconfont:'iconfont iconanalysis',
        //   newm:'qda7'
        // },
        {
          active: "active",
          list: "测距",
          iconfont: "iconfont iconchangduceliang",
          newm: "qda7"
        },
        {
          active: "active",
          list: "测面积",
          iconfont: "iconfont iconmianji",
          newm: "qda7"
        }
      ],
      stList: [
        {
          zoom: 8,
          longitude: 121.447932,
          latitude: 37.640386,
          createBy: "",
          createTime: "2020-09-28 00:00:00",
          focusId: ""
        },
      ], //视图list
      lonLat: {
        lon: "",
        lat: ""
      },
      searchenterprise: '',
      restaurants:  [
          { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },

        ]
    };
  },
  mounted() {
    this.stListReq()
  },
  methods: {
    // 放大镜搜索框
    querySearch(queryString, cb) {
      var restaurants = window.$app.enterpriselist;
      // var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
      window.$app.map.animateTo({
        center: [item.longitude,item.latitude],
        zoom: 16
      });
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    // 更改地图样式
    baseLayer(type) {
      this.basemap.map(item => {
        window.$app.setBaseLayer(item[type]);
      });
    },
    toggleTrawler(type, index) {
      if (index === "0") {
      }
      const current = this.legend[index];
      current.active = current.active ? "" : "active";
      if (type === "缩小") {
        window.$app.map.zoomOut();
      }
      if (type === "放大") {
        window.$app.map.zoomIn();
      }
      if (type === "领海基线" && current.active) {
        window.$app.layers.base_LHJX.hide();
      }
      if (type === "领海基线" && !current.active) {
        window.$app.layers.base_LHJX.show();
      }

      if (type === "海岸线" && current.active) {
        window.$app.layers.base_coastline.hide();
      }
      if (type === "海岸线" && !current.active) {
        window.$app.layers.base_coastline.show();
      }

      if (type === "渔区" && current.active) {
        window.$app.layers.base_YQ.hide();
      }
      if (type === "渔区" && !current.active) {
        window.$app.layers.base_YQ.show();
      }

      if (type === "地图复位") {
        window.$app.locate();
        current.active = "";
      }

      if (type === "测距" && !current.active) {
        window.$app.measurement();
      }
      if (type === "测距" && current.active) {
        window.$app.measurement();
      }
      if (type === "测面积" && !current.active) {
        window.$app.area();
      }
      if (type === "测面积" && current.active) {
        window.$app.area();
      }
      if (type === "海区" && !current.active) {
        window.$app.layers.base_seaArea.show();
      }
      if (type === "海区" && current.active) {
        window.$app.layers.base_seaArea.hide();
      }
      // if (type === '海湾' && current.active) {
      //   window.$app.layers.base_bay.hide()
      //   window.$app.layers.base_baypoint.hide()
      //   window.$app.layers.base_baylabel.hide()

      // }
      // if (type === '海湾' && !current.active) {
      //   window.$app.layers.base_bay.show()
      //   window.$app.layers.base_baypoint.show()
      //   window.$app.layers.base_baylabel.show()
      // }
      // if (type === '海域确权' && current.active) {
      //   window.$app.layers.base_HYQQ.hide()

      // }
      // if (type === '海域确权' && !current.active) {
      //   window.$app.layers.base_HYQQ.show()
      // }
      // if (type === '生态红线' && !current.active) {
      //   window.$app.layers.base_redline.show()
      // }
      // if (type === '生态红线' && current.active) {
      //   window.$app.layers.base_redline.hide()
      // }
      // if (type === '功能区划' && current.active) {
      //   window.$app.layers.base_funArea.hide()
      // }
      // if (type === '功能区划' && !current.active) {
      //   window.$app.layers.base_funArea.show()
      // }
      // if (type === '陆地海域' && current.active) {
      //   window.$app.layers.base_outline.hide()
      //   window.$app.layers.base_hy.hide()
      // }
      // if (type === '陆地海域' && !current.active) {
      //   window.$app.layers.base_outline.show()
      //   window.$app.layers.base_hy.show()
      // }

      // this.$emit('toggle', type, current.active)
    },
    openDetails(row) {
      //具体操作
      window.$app.map.animateTo(
        {
          center: [row.longitude, row.latitude],
          zoom: row.zoom,
          pitch: 0
          // bearing: 360,
        }
        // {
        //   duration: 7000,
        // }
      );
    },
    zoomAndCenter() {
      let that = this;
      this.stList.zoom = window.$app.map.getZoom()
      this.stList.longitude = window.$app.map.getCenter().x
      this.stList.latitude = window.$app.map.getCenter().y
      var force = {
        zoom: window.$app.map.getZoom(),
        longitude: window.$app.map.getCenter().x,
        latitude: window.$app.map.getCenter().y
        // createBy: "",
        // createTime: "",
        // focusId: "",
      };
      var param = new FormData();
      param.append("zoom", window.$app.map.getZoom());
      param.append("longitude", window.$app.map.getCenter().x);
      param.append("latitude", window.$app.map.getCenter().y);
      that.$axios.post(api.forcesave).then(res=>{
        if (res.success) {
          this.$notify({
          title: '成功',
          message: '这是一条成功的提示消息',
          type: 'success'
        });
        that.stListReq()
        }
      }).catch(error =>{
        this.$notify.error({
          title: '错误',
          message: '请检查网络后重新再试'
        });
      })
      // that.$util._formPost(
      //   false,
      //   that,
      //   that.ref,
      //   param,
      //   api.ranchForce_save,
      //   _r => {
      //     if (_r.success) {
      //       that.$util._notify.success("操作成功！");
      //       that.stListReq();
      //     }
      //   }
      // );
    },
    stListReq(){
      this.$axios.post(api.loadforce).then(_r => {
        if (_r.success) {
          if (_r.result) {
            this.stList = _r.result;
          }
        }
      })
    },
    mapCenterClick() {
      if (this.lonLat.lon && this.lonLat.lat) {
        window.$app.map.animateTo(
          {
            center: [this.lonLat.lon, this.lonLat.lat]
            // zoom: 10,
            // pitch: 0,
            // bearing: 360,
          }
          // {
          //   duration: 7000,
          // }
        );
      }
    },
    // 删除图层
    delwatch(row){
      console.log(row,'row')
      this.$confirm('是否删除该数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          let param = new FormData();
          param.append("id", row.focusId);
          this.$axios.post(api.deleforce,param).then(_r => {
            console.log(_r,'_r_r_r_r')
            if (_r.success) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.stListReq()
            }
            if (_r.data.code = '-1') {
              this.$message({
                type: 'error',
                message: _r.data.error+'请检查网络后重试！'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '删除失败，请检查网络后重试！'
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.zoomCenterClass {
  width: 60px;
  height: 100px;
  position: absolute;
  right: 665px;
  bottom: 555px;
  z-index: 999;
}
.zoomCenterClass > span {
  display: inline-block;
  margin-bottom: 10px !important;
}
.jwdClass {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  /* margin-bottom: 10px; */
}

.jwdClass span {
  width: 50px !important;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
}
.jwdClass > div {
  margin-left: 20px;
  display: inline-block;
}
.jwdClass .el-input {
  width: 300px !important;
}
.trawler-map {
  height: 100%;
  width: 100%;
}

.trawler-mask {
  width: 100%;
  bottom: 0px;
  left: 0px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 9;
}

.map-plugin-list {
  bottom: 300px;
  height: auto;
  position: absolute;
  right: 680px;
  transition-duration: 0.3s;
  width: auto;
  z-index: 9999;
}
.map-plugin-list.offset {
  transform: translateX(650px);
}
.map-colorBz.offset {
  transform: translateX(-850px);
}

.zoomCenteroffset.offset {
  transform: translateX(650px);
}

.map-plugin {
  background-color: rgba(63, 111, 192, 1);
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  margin-bottom: 5px;
  position: relative;
  width: 30px;
}
.map-plugin > span {
  font-size: 16px;
  font-weight: 600;
}
.map-plugin.active {
  background-color: rgba(2, 255, 255, 0.5);
}
.map-plugin-popup {
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  height: 0px;
  line-height: 20px;
  opacity: 0;
  overflow: auto;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  right: 0px;
  text-overflow: unset;
  top: 100%;
  transition-delay: 0s;
  transition-duration: 0s;
  visibility: hidden;
  white-space: unset;
  z-index: 99;
  width: 75px;
}
.map-plugin > span.map-plugin-popup {
  font-size: 12px;
  font-weight: 300;
}
.map-plugin-sublist {
  display: block;
  opacity: 0;
  padding-left: 0px;
  position: absolute;
  right: 34px;
  top: 0px;
  transition-duration: 0.3s;
  visibility: hidden;
}
.map-plugin:hover .map-plugin-sublist {
  opacity: 1;
  visibility: visible;
}
.map-plugin:hover .map-plugin-popup {
  height: auto;
  opacity: 1;
  transition-delay: 0.7s;
  transition-duration: 0.3s;
  visibility: visible;
}
.popBtn {
  width: 30px;
  height: 30px;
  line-height: 12px;
  padding-left: 10px;
  padding-right: 12px;
  background-color: rgba(63, 111, 192, 1);
  border: none;
}
.map-plugin-subitem {
  background-color: rgba(63, 111, 192, 1);
  border-radius: 5px;
  color: darkgrey;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  list-style: none;
  margin-bottom: 1px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}
.map-plugin-subitem.active {
  color: white;
}
.map-plugin-subitem:last-child {
  margin-bottom: 0px;
}
.map-plugin-subicon {
  border-radius: 4px;
  display: inline-block;
  height: 8px;
  margin-right: 10px;
  position: relative;
  transform: translateY(-1px);
  width: 8px;
}
.map-plugin-subicon::after {
  border-radius: 4px;
  content: " ";
  display: inline-block;
  height: 8px;
  left: 10px;
  position: absolute;
  width: 8px;
}
.map-plugin-subicon.current {
  background-color: #ff0000;
  border-radius: 0px;
  transform: rotate(45deg);
}
/* AIS渔船类型 */
.map-plugin-subicon.ais {
  background-color: #64bbed;
}
.map-plugin-subicon.ais::after {
  /*background-color: #2494C2;*/
  border: 1px solid #64bbed;
  background-color: #939393;
}
/* 北斗渔船类型 */
.map-plugin-subicon.beidou-common {
  background-color: #2989ff;
}
.map-plugin-subicon.beidou-common::after {
  /*background-color: #2459C2;*/
  border: 1px solid #2989ff;
  background-color: #939393;
}
.map-plugin-subicon.beidou-breed {
  background-color: #00cc00;
}
.map-plugin-subicon.beidou-breed::after {
  /*border: 1px solid #589158;*/
  border: 1px solid #00cc00;
  background-color: #939393;
}
.map-plugin-subicon.beidou-law {
  background-color: #00ffff;
}
.map-plugin-subicon.beidou-law::after {
  /*border: 1px solid #589158;*/
  border: 1px solid #00ffff;
  background-color: #939393;
}
/* 雷达渔船类型 */
.map-plugin-subicon.radar {
  background-color: #9370db;
}
.map-plugin-subicon.radar::after {
  /*background-color: #6D46BF;*/
  border: 1px solid #9370db;
  background-color: #939393;
}
.map-plugin-subicon.other {
  background-color: #939393;
}
/* 雷达-AIS */
.map-plugin-subicon.radar-ais {
  background-color: #cd60ba;
}
/* 重点关注渔船 */
.map-plugin-subicon.focus {
  border: 2px solid #ff9900;
  border-radius: 12px;
  height: 12px;
  margin-right: 4px;
  transform: translateY(2px);
  width: 12px;
}
.footer {
  background-image: url("/static/img/screen/track-review-bg.png");
  background-size: 100% 100%;
  position: absolute;
  bottom: 114px;
  font-family: "SHSCNR";
  left: 370px;
  right: 425px;
  z-index: 99;
}
.review-container {
  width: 100%;
  height: 120px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
  border-radius: 5px;
  /*background-color: rgba(0, 10, 16, 0.8);*/
  color: rgba(255, 255, 255, 0.8);
  position: relative;
}
.review-close {
  background-image: url("/static/img/screen/close-icon.png");
  background-size: 100% 100%;
  position: absolute;
  top: 36px;
  right: -36px;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}
.review-tool-bar {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}
.bar-left {
  width: 30%;
  text-align: left;
}
.bar-right {
  width: 30%;
  text-align: right;
}
.bar-center {
  width: 40%;
  text-align: center;
}

.ivu-btn-ghost.ivu-btn-dashed,
.ivu-btn-ghost.ivu-btn-default {
  color: rgb(1, 94, 234) !important;
  border-color: rgb(1, 94, 234) !important;
}
.ivu-btn-ghost.ivu-btn-dashed:hover,
.ivu-btn-ghost.ivu-btn-default:hover {
  color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

.bar-right .ivu-btn-small {
  padding-left: 0px;
  padding-right: 0px;
}

.bar-left .ivu-btn-small,
.bar-right .ivu-btn-small {
  font-size: 10px;
}

.trawler-map-container .ivu-spin-fix {
  background-color: rgba(16, 16, 16, 0.8);
}

/* 鼠标经纬度 */
.mouse-position {
  align-items: center;
  /* background-color: rgba(255, 255, 255, 1); */
  bottom: 20px;
  display: flex;
  /* flex-direction: row; */
  height: 23px;
  line-height: 23px;
  justify-content: space-between;
  left: 680px;
  position: fixed;
  /* transform: skew(-30deg); */
  width: 282px;
  z-index: 10;
}
.mouse-position .item {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  width: 46%;
}
.mouse-position .item .label {
  /* background-color: #055bff;
  flex-shrink: 0;
  height: 100%;
  padding-left: 3px;
  padding-right: 3px; */
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.mouse-position .item .value {
  /* color: #055bff;
  flex-grow: 1;
  height: 100%;
  padding-left: 3px;
  padding-right: 3px;
  text-align: left; */
  /* width: 60px; */
  height: 20px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  /* background: #000D51 ; */
  background-color: rgba(43, 35, 35, 0.3);
  /* opacity: 0.2; */
  border-radius: 2px;
}
</style>

<style scoped lang="scss">
.map-legend {
  z-index: 200;
  bottom: 120px;
  padding: 0px;
  position: absolute;
  right: 635px;
  overflow: hidden;
  border-radius: 5px;
}
.legend-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 27px;
}
.legend-li {
  padding-left: 5px;
  padding-top: 10px;
  background: #083c6d;
  color: azure;
  text-align: start;
  height: 20px;
  line-height: 10px;
  cursor: pointer;
  border-radius: 5px;

  opacity: 0.5;
}
.legend-li:hover {
  background: blue;
}

.legend-li:hover .seamap {
  display: block;
  position: fixed;
  right: 659px;
  top: 718px;
  display: flex;
  width: 200px;
  height: 22px;
  line-height: 22px;
  padding: 5px;
  padding-right: 5px;

  .seamap-span {
    border-radius: 5px;
    margin-right: 1px;
    border-radius: 2px;
    height: 30px;
    width: 80px;
    text-align: center;
    line-height: 30px;
    overflow: hidden;
    background: white;
    color: #000000;
  }
  .seamap-span:hover {
    background: #083c6d !important;
  }
}

.legend-text {
  padding-left: 5px;
}

.active {
  opacity: 0.5;
}
.seamap {
  position: fixed;
  right: 49px;
  top: 544px;
  display: flex;
  width: 200px;
  height: 22px;
  line-height: 22px;
  padding: 5px;
  padding-right: 5px;
  display: none;
}
// .zoomCenterClass {
//   width: 60px;
//   height: 100px;
//   position: absolute;
//   right: 665px;
//   bottom: 555px;
//   z-index: 999;
// }
// .zoomCenterClass > span {
//   display: inline-block;
//   margin-bottom: 10px !important;
// }

// .zoomCenteroffset.offset {
//   transform: translateX(650px);
// }
.legend-ul ::v-deep el-button {
  background-color: #083c6d;
}
.legend-ul ::v-deep.el-table--fit {
  background: #104fa2 !important ;
}
.legend-ul ::v-deep .el-table__row {
  background: #104fa2 !important ;
}

// .legend-ul ::v-deep .el-table__row
.popBtn {
  width: 30px;
  height: 30px;
  line-height: 12px;
  padding-left: 10px;
  padding-right: 12px;
  background: #083c6d !important;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0.5;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
<style>
.el-popover {
  background: #104fa2 !important ;
  border: none !important;
}
.maptalks-ico {
  display: none !important;
}
.jwdClass .el-input__inner {
  background: rgba(6, 57, 100, 0.36);
  border: 1px solid #1e7dac;
  font-family: PingFang SC;
  font-weight: 200;
  color: #ffffff !important;
}

.el-table
  table
  .el-table--fit
  .el-table--striped
  .el-table--scrollable-x
  .el-table--enable-row-hover
  .el-table--enable-row-transition {
  width: 418px !important;
}
.el-popover__title{
  color: #fff;
}
</style>
