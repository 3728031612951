<!-- 底部控制框 -->
<template>
  <div class="bbox">
    <div class="option-box">
      <!--  第一个点击功能  基础地理后来添加的 -->
      <div
        class="geographic"
        @click="geographic"
        :class="{ active: particulars === true }"
      >
        <img src="./img/label0.png" alt="" />
        <div class="labeltitle">基础地理</div>
      </div>
      <!-- 第二个点击功能  功能区划单独展示 -->
      <div
        class="geographic"
        @click="changefunction"
        :class="{ active: func.visible === true }"
      >
        <img src="./img/fun.png" alt="" />
        <div class="labeltitle">
          功能区划
        </div>
      </div>
      <!--  第三个点击功能  企业联动，可在data中配置 -->
      <div
        class="geographic classify"
        :class="{ active: isActive === index }"
        v-for="(item, index) in list"
        :key="index"
        @click="changelist($event, index)"
      >
        <img :src="item.img" alt="" />
        <div class="labeltitle">{{ item.title }}</div>
      </div>
      <!-- 第四个点击功能  十四五规划  -->
      <div
        class="geographic"
        @click="economy"
        :class="{ active: economyLayout.visible === true }"
      >
        <img src="./img/econmylayout.png" alt="" />
        <div class="labeltitle">
          十四五规划
        </div>
      </div>

      <!-- 第五个点击功能主要海洋产业产值 -->
      <!-- 海洋经济总体布局湾 -->
      <div
        class="geographic"
        @click="oceanlay"
        :class="{ active: oceanLayout.visible === true }"
      >
        <img src="./img/oceanlayout.png" alt="" />
        <div class="labeltitle">
          支柱产业
        </div>
      </div>

      <!-- 点击第五个底部按钮，海洋产业产值，需要展示的页面 -->

      <ChartGather v-if="oceanLayout.visible"></ChartGather>

      <!-- 摄像头 -->
      <div
        style="display:none"
        class="geographic"
        @click="LoadVideo"
        :class="{ active: video.visible === true }"
      >
        <img src="./img/camerabtn.png" alt="" />
        <div class="labeltitle">
          实时监控
        </div>
      </div>

      <!-- 基础地理  海岸线 市区划分 -->

      <!-- 单独定义一个框，定位出去不影响option-box的结构 -->
      <!-- 单独定义的小框 -->
      <div class="particularsbox" v-show="particulars">
        <div>
          <div class="lableClass jcdlActive">基础地理</div>
          <div class="valueClass">
            <el-checkbox-group v-model="checkList" @change="checkboxChange()">
              <!-- <el-checkbox label="功能区域"></el-checkbox> -->
              <el-checkbox label="海岸线"></el-checkbox>
              <el-checkbox label="市区划分"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="particularsboxfourfive" v-show="economyLayout.visible">
        <div>
          <div class="lableClass jcdlActive">十四五规划</div>
          <div class="valueClass">
            <el-checkbox-group
              v-model="fourfiveList"
              @change="checkffboxChange()"
            >
              <!-- <el-checkbox label="功能区域"></el-checkbox> -->
              <el-checkbox style="margin-right: 5px" label="海洋经济总体布局"></el-checkbox>
              <el-checkbox style="margin-right: 5px" label="渔港经济区布局"></el-checkbox>
              <el-checkbox style="margin-right: 5px" label="海水淡化与海上风电产业布局"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <!-- 点击第五个底部按钮，海洋产业产值，需要展示的页面 单独定义的小框 -->
      <div
        class="particularsboxfourfive"
        style="width:800px"
        v-show="oceanLayout.visible"
      >
        <div>
          <div class="lableClass jcdlActive">支柱产业</div>
          <div class="valueClass">
            <el-radio-group v-model="fiveList" @change="checkfiveboxChange">
              <el-radio :label="3">海洋渔业生产情况</el-radio>
              <el-radio :label="6">海洋交通运输业生产情况</el-radio>
              <el-radio :label="9">滨海旅游业服务情况</el-radio>
            </el-radio-group>
            <!-- <el-checkbox-group
            v-model="fiveList"
            @change="checkfiveboxChange()"
          >
            <el-checkbox label="海洋渔业生产情况"></el-checkbox>
            <el-checkbox label="海洋交通运输业生产情况"></el-checkbox>
            <el-checkbox label="滨海旅游业服务情况"></el-checkbox>
          </el-checkbox-group> -->
          </div>
        </div>
      </div>
      <!-- 企业类型更改 -->
      <div
        class="particularsbox2"
        :class="iconshow ? 'boxshow' : 'boxnoshow'"
        v-show="enterpriseshow"
      >
        <div>
          <div class="lableClass jcdlActive">企业类型</div>
          <div class="valueClass-enterprisetype">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              style="position: absolute;right: 18px;color:#fff"
              >全选</el-checkbox
            >
            <i
              class="iconfont iconshangjiantou"
              v-if="iconshow"
              @click="changeiconshow"
            ></i>
            <i
              class="iconfont iconxiajiantou"
              v-else
              @click="changeiconshow"
            ></i>
            <el-checkbox-group
              v-model="enterprisetype2"
              @change="handleCheckedCitiesChange"
            >
              <div style=""></div>
              <el-checkbox
                style="margin-right: 5px"
                v-for="(item, index) in enterprisetype"
                :key="index"
                :label="item"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <!-- <el-checkbox-group v-model="enterprisetype" @change="handleCheckedCitiesChange">
            <el-checkbox
              v-for="(item, index) in enterprisetype"
              :key="index"
              :label="item"
            ></el-checkbox>
          </el-checkbox-group> -->
          </div>
        </div>
      </div>

      <!-- 综合查询按钮 -->
      <div @click="inputshow" class="zhcxClass">
        <img src="./img/zhcx.png" alt="" />
      </div>

      <!-- :cameraIndexCode = 'xxx' -->
      <div class="videobox" v-if="video.visible">
        <div class="video-window">
          <!-- <video class="video-window" src="rtsp://10.10.30.2:554/openUrl/VjGMM12"></video>   -->
          <video-comp
            :cameraIndexCode="cameraIndexCode"
            layout="5x5"
            ref="video-preview"
            :timeOut="500"
          >
          </video-comp>
        </div>
        <!-- 选择摄像头点控制按钮 -->

        <!-- <div class="videotab">
        <div v-for="(item, index) in videolist" :key="index">
          <button class="btn" @click="VideoBtn(item.cameraIndexCode, index)">
            {{ item.cameraName }}
          </button>
        </div>
      </div> -->
        <div class="videotab">
          <el-col>
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              :unique-opened="true"
            >
              <el-submenu
                v-for="(item, index) in videodatalist"
                :key="index"
                :index="index"
              >
                <template slot="title">
                  <i class="iconfont iconshexiangtou3"></i>
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group
                  v-for="(item, index) in item.value"
                  :key="index"
                >
                  <el-menu-item
                    :index="index"
                    @click="VideoBtn(item.cameraIndexCode, item, index)"
                    >{{ item.camera_name }}</el-menu-item
                  >
                  <!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
                </el-menu-item-group>
              </el-submenu>
              <!-- <el-submenu index="2">
                <template slot="title">
                  <i class="iconfont iconshexiangtou3"></i>
                  <span>导航一</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">选项1</el-menu-item>
                  <el-menu-item index="1-2">选项2</el-menu-item>
                </el-menu-item-group>
              </el-submenu> -->
            </el-menu>
          </el-col>
        </div>
      </div>

      <!-- <ul v-if="seaAreaColor" v-show="showChart" class="colorBz" :class="['map-colorBz', plugins.offset]"> -->
      <ul class="colorBz" :class="['map-colorBz']" v-if="seaAreaColor">
        <li>保留区<i style="background:#45b97c"></i></li>
        <li>养殖区<i style="background:#33a3dc"></i></li>
        <li>航道区<i style="background:#8a5d19"></i></li>
        <li>海洋自然保护区<i style="background:#50b7c1"></i></li>
        <li>风景旅游区<i style="background:#fab27b"></i></li>
        <li>海洋特别保护区<i style="background:#585eaa"></i></li>
        <li>文体休闲娱乐区<i style="background:#ef4136"></i></li>
        <li>锚地区<i style="background:#8e7437"></i></li>
        <li>港口区<i style="background:#2a5caa"></i></li>
        <li>特殊利用区<i style="background:#f7acbc"></i></li>
        <li>工业与城镇用海区<i style="background:#63434f"></i></li>
        <li>矿产与能源区<i style="background:#8a8c8e"></i></li>
        <li>捕捞区<i style="background:#ffd400"></i></li>
        <li>增殖区<i style="background:#973c3f"></i></li>
      </ul>

      <!-- 鼠标经纬度 -->
      <!-- <div v-show="showChart" @click="mapCenterClick()" class="mouse-position">

      <div class="item">

        <span class="label">经度</span>

        <span class="value">{{ mouse.longitude }}</span>

      </div>

      <div class="item">

        <span class="label">纬度</span>

        <span class="value">{{ mouse.latitude }}</span>

      </div>

    </div> -->
      <!-- 摄像头显示隐藏  -->
      <!-- <div class="changevideobtn">
      <el-button v-if="openvideo" type="primary" @click="LoadVideo">
        渔港监控
      </el-button>
      <el-button v-else type="primary" @click="closeVideo">
        关闭监控
      </el-button>
    </div> -->
    </div>
  </div>
</template>

<script>
import { ClusterLayer } from "maptalks.markercluster";
import "maptalks.animatemarker/dist/maptalks.animatemarker.min.js";
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";
import api from "@/util/api";
import videocomp from "../video-comp/video-comp";
import { setTimeout } from "timers";
import ChartGather from "./chartgather/chartgather";
export default {
  name: "bottom-label",
  components: {
    "video-comp": videocomp,
    ChartGather
  },
  data() {
    return {
      list: [
        // {
        //   title: "渔船",
        //   img: require("./img/label1.png")
        // },
        // {
        //   title: "港口",
        //   img: require("./img/label2.png")
        // },
        {
          title: "规上企业",
          img: require("./img/label3.png")
        }
        // {
        //   title: "科研机构",
        //   img: require("./img/label4.png")
        // }
      ],
      particulars: "",

      enterpriseshow: false,
      checkList: ["功能区域", "海岸线", "市区划分"],
      fourfiveList: [],
      fiveList: 3,
      video: {
        code: [],
        visible: false
      },
      enterprisetype2: [
        "海洋矿业",
        "海洋地质勘查业",
        "涉海产品再加工",
        "海洋管理",
        "海水利用业",
        "滨海旅游业",
        "海洋可再生能源利用业",
        "海洋盐业",
        "海洋信息服务业",
        "海洋技术服务业",
        "海洋水产品加工业",
        "海洋药物和生物制品业",
        "涉海原材料制造",
        "海洋仪器制造",
        "海洋科学研究",
        "海洋化工业",
        "海洋产品批发、零售",
        "涉海服务业",
        "海洋生态环境保护",
        "涉海新材料制造业",
        "海洋渔业",
        "海工装备制造业",
        "海洋船舶工业",
        "海洋油气业",
        "海洋工程建筑业",
        "海洋交通运输业"
      ],
      enterprisetype: [
        "海洋矿业",
        "海洋地质勘查业",
        "涉海产品再加工",
        "海洋管理",
        "海水利用业",
        "滨海旅游业",
        "海洋可再生能源利用业",
        "海洋盐业",
        "海洋信息服务业",
        "海洋技术服务业",
        "海洋水产品加工业",
        "海洋药物和生物制品业",
        "涉海原材料制造",
        "海洋仪器制造",
        "海洋科学研究",
        "海洋化工业",
        "海洋产品批发、零售",
        "涉海服务业",
        "海洋生态环境保护",
        "涉海新材料制造业",
        "海洋渔业",
        "海工装备制造业",
        "海洋船舶工业",
        "海洋油气业",
        "海洋工程建筑业",
        "海洋交通运输业"
      ],
      // 更改控制视频插件显示隐藏
      openvideo: true,
      // 请求到的摄像头code列表
      cameraIndexCode: [],
      // 摄像头名字和摄像头codeindex
      videolist: [],
      isActive: 6,
      // 企业图层
      changepriselayer: null,
      epitem: [],
      isIndeterminate: true,
      checkAll: true,
      // 更改企业列表
      iconshow: false,
      markerposition: [],
      // 监控摄像头显示隐藏
      videobtnshow: true,
      seaAreaColor: true,
      videodatalist: "",
      // 功能区划显示隐藏
      func: {
        visible: true
      },
      // 经济部局显示隐藏控制字段
      economyLayout: {
        visible: false
      },
      oceanLayout: {
        visible: false
      },
      // 第五个控制图标主要海洋产业
      oceaneconomics: {
        visible: false
      },
      // res请求的json数据
      economylist: [],
      // economylayer经济部局图层
      economylayer: null,
      // 海洋请求到的json数据集合
      oceanlayoutlist: "",
      // oceanlayoutlayer海洋经济布局图层
      oceanlayoutlayer: null,

      //更改显示地图颜色
      changeoceanlayer: null,

      // 十四五规划显示隐藏
      fourfive: "",

      //
      windpowerlayer: null
    };
  },

  mounted() {
    // this.RequestPort();
    this.loadEnterpriseType();
    this.LoadVideoList();

    // 十四五规划第二个按钮需要加载的数据
    this.loadeconomy();
    // 经济海湾
    this.loadoceanlay();
    this.loaddesalination();
  },
  methods: {
    // 点击企业
    changelist(e, index) {
      // 企业显示隐藏
      this.enterpriseshow = !this.enterpriseshow;
      console.log(this.enterpriseshow);
      // 如果企业显示 //
      // 背景色安排 // 第四个隐藏
      //第一个基础地理弹框隐藏
      if (this.enterpriseshow) {
        this.isActive = 0;
        this.economyLayout.visible = false;
        this.oceanLayout.visible = false;

        this.particulars = false;
      }
      // 如果企业不显示,背景色一边去
      if (!this.enterpriseshow) {
        this.isActive = 7;
      }
      this.$parent.$children[3].project_show = !this.$parent.$children[3]
        .project_show;
      this.$parent.$children[1].show = !this.$parent.$children[1].show;
    },
    // 原先展示渔船渔港的方法
    changelist2(e, index) {
      // debugger
      this.particulars = false;
      this.enterpriseshow = false;
      this.isActive = index;

      this.$parent.$children[3].isShow.forEach((item, index2) => {
        if (index2 == index) {
          this.$parent.$children[3][item] = !this.$parent.$children[3][item];
          if (this.$parent.$children[3][item] == false) {
            this.$parent.$children[1].show = true;
            this.isActive = 6;
          } else {
            this.$parent.$children[1].show = false;
          }
        } else {
          this.$parent.$children[3][item] = false;
        }
        // this.$nextTick(() => {
        //   this.drawLine();
        //   this.drawLine2();
        //   this.drawLine3();
        //   this.drawLine4();
        // });
      });
      if (this.isActive === 1) {
        window.$app.layers.base_FP.show();
      } else {
        window.$app.layers.base_FP.hide();
      }
      if (this.isActive === 0) {
        window.$app.layers.base_FB.show();
      } else {
        window.$app.layers.base_FB.hide();
      }
      // 控制企业图层显示隐藏
      if (this.isActive === 2) {
        this.enterpriseshow = true;
        // window.$app.layers.base_enterprise.show()
      } else {
        // window.$app.layers.base_enterprise.hide()
        this.enterpriseshow = false;
      }
    },
    checkffboxChange() {
      const oceanlayout = this.fourfiveList.includes("海洋经济总体布局");
      const fishlayout = this.fourfiveList.includes("渔港经济区布局");
      const seawaterlayout = this.fourfiveList.includes(
        "海水淡化与海上风电产业布局"
      );
      if (oceanlayout) {
        this.changeoceanlayer.show();
        this.oceanlayoutlayer.show();
      } else {
        this.changeoceanlayer.hide();
        this.oceanlayoutlayer.hide();
      }
      if (fishlayout) {
        this.economylayer.show();
        window.$app.layers.base_FP.show();
      } else {
        this.economylayer.hide();
        window.$app.layers.base_FP.hide();
      }
      if (seawaterlayout) {
        this.windpowerlayer.show();
      } else {
        this.windpowerlayer.hide();
      }
    },
    checkfiveboxChange() {
      if (this.fiveList === 3) {
        this.$children[5].changechartshow.one = true;
      } else {
        this.$children[5].changechartshow.one = false;
      }
      if (this.fiveList === 6) {
        this.$children[5].changechartshow.two = true;
      } else {
        this.$children[5].changechartshow.two = false;
      }
      if (this.fiveList === 9) {
        this.$children[5].changechartshow.three = true;
      } else {
        this.$children[5].changechartshow.three = false;
      }
    },
    // 更改地图颜色  //海洋经济总体布局de方法
    changecolor() {
      this.changeoceanlayer = window.$app.initVectorLayer("YTSQ1", 10);

      const citylist = window.$app.cityoptions;
      console.log(citylist);
      citylist.forEach(item => {
        this.addregionLine(item);
        // 使用updatesymbol怎么解决
        // const id = `a_w_${item.partitionName}`;
        // this.addWatersToMap(id, item);
        // window.$app.layers.base_YTSQ.updateSymbol([
        //   {
        //     polygonFill: colorObj[item.name], // 图形覆盖颜色
        //     polygonOpacity: 0.8, // 图形覆盖区域透明度
        //     lineColor: "#fff", // 线条颜色
        //     lineWidth: 0.2 // 线条宽度
        //   },
        // ]);
      });
      this.changeoceanlayer.hide();
    },
    // 更改地图颜色  // 西翼南翼核心区
    addregionLine(options) {
      const colorObj = {
        芝罘区: "#74CE6A",
        福山区: "#74CE6A",
        牟平区: "#74CE6A",
        开发区: "#74CE6A",
        高新区: "#74CE6A",
        莱山区: "#74CE6A",
        长岛综合试验区: "#74CE6A",
        蓬莱区: "#74CE6A",
        栖霞市: "#33a3dc",
        海阳市: "#33a3dc",
        招远市: "#6877F4",
        莱州市: "#6877F4",
        莱阳市: "#33a3dc",
        龙口市: "#6877F4"
      };
      var coordinates = JSON.parse(options.lonLat);
      coordinates.forEach((item, index) => {
        var list = [];
        item.forEach((val, indexTow) => {
          if (val[0] && val[1]) {
            list.push(val);
          }
        });
        const symbol = [
          {
            // lineDasharray: lineDasharray,
            polygonFill: colorObj[options.name], // 图形覆盖颜色
            polygonOpacity: 0.8, // 图形覆盖区域透明度
            lineColor: "#999",
            lineWidth: 4,
            lineDasharray: [2, 1, 2], //线形
            lineOpacity: 1
          }
        ];
        var id = `Q_X_${options.name}_${index}`;
        const area = window.$app.initGeometry({
          className: "Polygon",
          params: [list, { id, symbol }]
        });

        area.addTo(this.changeoceanlayer);
        var textposition = [
          {
            coordinates: [120.085, 37.31515],
            name: "西"
          },
          {
            coordinates: [120.362389, 37.465556],
            name: "翼"
          },
          {
            coordinates: [120.865033, 37.696389],
            name: "核"
          },
          {
            coordinates: [121.172639, 37.498333],
            name: "心"
          },
          {
            coordinates: [121.529444, 37.258333],
            name: "区"
          },
          {
            coordinates: [120.7825, 37.06],
            name: "南"
          },
          {
            coordinates: [121.035278, 37.06],
            name: "翼"
          }
        ];
        var textmarker = [];
        textposition.forEach(item => {
          textmarker.push(
            new maptalks.Marker(item.coordinates, {
              symbol: {
                textName: item.name,
                textSize: 40,
                textFill: "#D20A0A"
              }
            })
          );
        });
        this.changeoceanlayer.addGeometry(textmarker);
        // area.setInfoWindow({
        //   autoOpenOn: "click",
        //   single: true,
        //   // width: 183,
        //   // height: 105,
        //   // custom: true,
        //   // dx: -3,
        //   // dy: -12,
        //   title: options.name + "规模以上企业数量",
        //   content:
        //     '<div class="content2" style="color: rgb(165, 53, 53)!important;border:none;">' +
        //     '<div class="pop_dept">' +
        //     '<p style:"padding:0;border:none">' +
        //     options.name +
        //     "共有" +
        //     options.value +
        //     "家规模以上涉海企业" +
        //     "</p>"
        // });
        // this.$nextTick(function() {
        //   //   //设置表格的最大高度
        //   setTimeout(() => {
        //     let msgBox = document.querySelector(".maptalks-close");
        //     msgBox.onclick = function() {};
        //   }, 1000);
        // });
      });
      this.addMk(window.$app.cityoptions);
    },
    // 添加批量标注
    addMk(result) {
      var markers = []; //标注集合
      //测试数据
      var dataCity = result;
      dataCity.forEach(item => {
        markers.push(
          new maptalks.Marker(JSON.parse(item.center), {
            //标注添加到集合
            symbol: {
              textName: item.name,
              textSize: 20,
              textFill: "#fff"
            }
          })
        );
      });
      this.changeoceanlayer.addGeometry(markers);
    },
    // 基础地理点击更改事件
    checkboxChange() {
      // const seaArea = this.checkList.includes("功能区域");
      const coastline = this.checkList.includes("海岸线");
      const regionLine = this.checkList.includes("市区划分");
      // if (seaArea) {
      //   window.$app.layers.base_seaArea.show();
      //   this.seaAreaColor = true;
      // } else {
      //   window.$app.layers.base_seaArea.hide();
      //   this.seaAreaColor = false;
      // }
      if (coastline) {
        window.$app.layers.base_coastline.show();
      } else {
        window.$app.layers.base_coastline.hide();
      }
      if (regionLine) {
        window.$app.layers.base_YTSQ.show();
      } else {
        window.$app.layers.base_YTSQ.hide();
      }
    },
    // 更改企业显示和隐藏
    changeenterpriseshow() {
      this.enterpriseshow = !this.enterpriseshow;
    },

    changefunction() {
      this.func.visible = !this.func.visible;
      if (this.func.visible) {
        window.$app.layers.base_seaArea.show();
        this.seaAreaColor = true;
      } else {
        window.$app.layers.base_seaArea.hide();
        this.seaAreaColor = false;
      }
    },
    // 更改iconfont显示隐藏
    changeiconshow() {
      this.iconshow = !this.iconshow;
    },
    handleCheckAllChange(val) {
      this.enterprisetype2 = val ? this.enterprisetype : [];
      this.isIndeterminate = false;
      // val是全选true或者全不选false，如果全选就加载所有，反之
      if (val) {
        this.handleCheckedCitiesChange(this.enterprisetype);
      }
      if (!val) {
        this.handleCheckedCitiesChange("");
      }
    },
    handleCheckedCitiesChange(value) {
      let checkCount = value.length;
      this.checkAll = checkCount === this.enterprisetype.length;
      this.isIndeterminate =
        checkCount > 0 && checkCount < this.enterprisetype.length;
      window.$app.layers.base_enterprise.hide();
      window.$app.layers.base_enterprise.clear();
      if (window.$app.layers.base_cpl) {
        window.$app.layers.base_cpl.hide();
        window.$app.layers.base_cpl.clear();
      }
      this.changepriselayer = window.$app.initVectorLayer("cpl", "9");
      let a = "";
      a = value.toString();
      this.$parent.$children[3].getEnterpriseinfomation(a, value);
    },

    // 更改选中的企业 BUYONG
    handleCheckedCitiesChange2(value) {
      let checkCount = value.length;
      this.checkAll = checkCount === this.enterprisetype.length;
      this.isIndeterminate =
        checkCount > 0 && checkCount < this.enterprisetype.length;
      window.$app.layers.base_enterprise.hide();
      window.$app.layers.base_enterprise.clear();
      if (window.$app.layers.base_cpl) {
        window.$app.layers.base_cpl.hide();
        window.$app.layers.base_cpl.clear();
      }
      this.changepriselayer = window.$app.initVectorLayer("cpl", "15");
      let a = "";
      a = value.toString();
      const params = new FormData();
      params.append("types", value.toString());
      this.$axios.post(api.query_enterprise, params).then(res => {
        this.epitem = res.data.result.list;
        this.epitem.forEach(item => {
          let x = item.longitude;
          let y = item.latitude;
          if (x != null && y != null) {
            let position = { latitude: x, longitude: y, name: item.name };
            this.markerposition.push(position);
            this.markInfo(this.markerposition);

            // var point = new maptalks.Marker([x, y], {
            //   visible: true,
            //   editable: true,
            //   cursor: "pointer",
            //   shadowBlur: 0,
            //   shadowColor: "black",
            //   draggable: false,
            //   dragShadow: false, // display a shadow during dragging
            //   drawOnAxis: null, // force dragging stick on a axis, can be: x, y
            //   symbol: [
            //     {
            //       textFaceName: "sans-serif",
            //       textName: item.name, //value from name in geometry's properties
            //       textWeight: "normal", //'bold', 'bolder'
            //       textStyle: "normal", //'italic', 'oblique'
            //       textSize: 16,
            //       textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
            //       textFill: "#34495e",
            //       textOpacity: 1,
            //       textHaloFill: "#fff",
            //       textHaloRadius: 2,
            //       textWrapWidth: null,
            //       textWrapCharacter: "\n",
            //       textLineSpacing: 0,

            //       textDx: 0,
            //       textDy: 0,

            //       textHorizontalAlignment: "middle", //left | middle | right | auto
            //       textVerticalAlignment: "middle", // top | middle | bottom | auto
            //       textAlign: "center" //left | right | center | auto
            //     },
            //     {
            //       markerFile: require("../middle-dialog/img/Enterprise.png"),
            //       markerWidth: 28,
            //       markerHeight: 40
            //     }
            //   ]
            // }).addTo(this.changepriselayer);

            // point.setInfoWindow({
            //   title: "企业信息",
            //   content: `<div class='pop_dept'><p><span>企业名称:</span>${item.name}</p></div>
            // <div class='pop_dept'><p><span>企业类型:</span>${item.type}</p></div>
            // <div class='pop_dept'><p><span>企业位置:</span>${item.location}</p></div>`
            // });
          }
        });
      });
      if (value.length == 0) {
        window.$app.layers.base_enterprise.hide();
        window.$app.layers.base_enterprise.clear();
        window.$app.layers.base_cpl.hide();
        window.$app.layers.base_cpl.clear();
      }
    },
    // BUYONG
    markInfo2(position) {
      // window.$app.map.removeLayer(this.enterpriselayer);
      this.markers = [];
      for (let i = 0; i < position.length; i++) {
        let a = position[i];

        let markerP = new maptalks.Marker([a.latitude, a.longitude], {
          properties: {
            name: a.name,
            onSale: a.onSale
          },
          symbol: [
            {
              markerFile: a.onSale
                ? require("../middle-dialog/img/Enterprise.png")
                : require("../middle-dialog/img/Enterprise.png"), //标注点图标
              markerWidth: 28,
              markerHeight: 40
            },
            {
              textFaceName: "sans-serif",
              textName: a.name, //value from name in geometry's properties
              textWeight: "normal", //'bold', 'bolder'
              textStyle: "normal", //'italic', 'oblique'
              textSize: 16,
              textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
              textFill: "#34495e",
              textOpacity: 1,
              textHaloFill: "#fff",
              textHaloRadius: 2,
              textWrapWidth: null,
              textWrapCharacter: "\n",
              textLineSpacing: 0,
              textDx: 0,
              textDy: 0,
              textHorizontalAlignment: "middle", //left | middle | right | auto
              textVerticalAlignment: "middle", // top | middle | bottom | auto
              textAlign: "center" //left | right | center | auto
            }
          ]
        });
        this.markers.push(markerP);
      }
      let clusterLayer = new ClusterLayer("cluster", this.markers, {
        noClusterWithOneMarker: true,
        noClusterWithHowMany: 8, //聚合的最小个数
        maxClusterZoom: 15,
        symbol: {
          markerType: "ellipse",

          markerFill: {
            property: "count",
            type: "interval",
            stops: [
              [0, "rgb(135, 196, 240)"],
              [9, "#1bbc9b"],
              [50, "rgb(116, 115, 149)"],
              [99, "rgb(216, 115, 149)"]
            ]
          },
          markerFillOpacity: 0.7,
          markerLineOpacity: 1,
          markerLineWidth: 3,
          markerLineColor: "#fff",
          markerWidth: {
            property: "count",
            type: "interval",
            stops: [
              [0, 40],
              [9, 60],
              [50, 70],
              [99, 80]
            ]
          },
          markerHeight: {
            property: "count",
            type: "interval",
            stops: [
              [0, 40],
              [9, 60],
              [50, 70],
              [99, 80]
            ]
          }
        },
        drawClusterText: true,
        geometryEvents: true,
        single: true
      });
      window.$app.map.addLayer(clusterLayer);
      this.clusterLayer = clusterLayer;

      function onClick(e) {
        e.target.setInfoWindow({
          content: "" + e.target.properties.name + "",
          width: 150,
          dy: 5,
          autoPan: true,
          custom: false,
          autoOpenOn: "click", //set to null if not to open when clicking on marker
          autoCloseOn: "click"
        });
      }
    },

    // 海洋布局(海湾)点击事件
    oceanlay() {
      this.oceanLayout.visible = !this.oceanLayout.visible;
      // 关闭所有为true的
      // 左边弹窗收起
      // this.$parent.$children[3].project_show = false;
      // this.$parent.$children[1].show = true
      this.particulars = false;
      this.isActive = 9;
      // 规上企业隐藏
      this.enterpriseshow = false;
      // this.$parent.$children[3].project_show = !this.$parent.$children[3]
      // .project_show;
      this.economyLayout.visible = false;
      this.$parent.$children[1].show = true;
      this.$parent.$children[3].project_show = false;
    },
    // 请求经济海湾   // 海洋经济总体布局的海湾
    loadoceanlay() {
      this.$axios.post(api.ocean_layout).then(res => {
        this.oceanlayoutlist = res.data.result.list;
        console.log(res, "laji");
        const layer = window.$app.initVectorLayer("oceanlayout", 15);
        this.oceanlayoutlayer = layer;
        // this.oceanLayout.visible = !this.oceanLayout.visible;
        var oceanlayoutlist = this.oceanlayoutlist;
        oceanlayoutlist.forEach((it, index) => {
          // var lists = item.
          var coordinates = it.coordinates;

          coordinates.forEach((item, index) => {
            var list = [];
            item.forEach((val, indexTow) => {
              if (val[0] && val[1]) {
                list.push(val);
              }
            });
            const symbol = [
              {
                // lineDasharray: lineDasharray,
                polygonFill: "#72F7FC", // 图形覆盖颜色
                polygonOpacity: 1, // 图形覆盖区域透明度
                lineColor: "#009da3",
                lineWidth: 2,
                lineDasharray: [2, 1, 2], //线形
                lineOpacity: 1
              },
              {
                textName: it.name,
                // textSize: 14,
                textDx: 0,
                textDy: -10,
                textFill: "#fff",
                textSize: "18"
              },
              {
                // textName: it.introduce,
                textName: it.details,

                textFaceName: "sans-serif",
                textWeight: "normal", //'bold', 'bolder'
                textStyle: "normal", //'italic', 'oblique'
                textSize: 15,
                textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
                textFill: "#34495e",
                textOpacity: 1,
                textHaloFill: "#fff",
                textHaloRadius: 5,
                textWrapWidth: null,
                textWrapCharacter: "\n",
                textLineSpacing: 0,

                textDx: 0,
                textDy: 30,

                textHorizontalAlignment: "middle", //left | middle | right | auto
                textVerticalAlignment: "middle", // top | middle | bottom | auto
                textAlign: "center" //left | right | center | auto
              }
            ];
            var id = `Q_X_${it.name}_${index}`;
            const area = window.$app.initGeometry({
              className: "Polygon",
              params: [list, { id, symbol }]
            });
            area.addTo(this.oceanlayoutlayer);
          });
        });

        this.oceanlayoutlayer.hide();
      });
    },
    // 海水淡化与海上风电产业布局请求接口
    loaddesalination() {
      this.$axios.post(api.windpower).then(res => {
        const layer = window.$app.initVectorLayer("windpower", 10);
        this.windpowerlayer = layer;
        const result = res.data.result.type1;
        result.forEach(item => {
          // "莱州华电海水淡化项目""龙口裕龙岛海水淡化项目""烟台万华海水淡化项目""开发区海上平台海水淡化项目""海阳核电海水淡化项目"
          const marker = new maptalks.Marker(item.lngLat, {
            symbol: [
              {
                textName: item.name,
                textSize: 18,
                textFill: "#9ce17c",
                textWeight: 900
              },
              {
                // 先随便加上一个文件，后期替换
                markerFile: require("./img/taifeng4.png"),
                markerDx: 0,
                markerDy: 0,
                markerOpacity: 10,
                markerWidth: 40,
                markerHeight: 40
              }
            ]
          });
          marker.addTo(layer);
        });

        const resultsec = res.data.result.type2[0];

        // "长岛海水淡化项目"
        resultsec.lngLatList.forEach((item, index) => {
          const markerLng = new maptalks.Marker(item, {
            symbol: [
              {
                textName: index == 4 ? resultsec.name : "",
                textSize: 18,
                textFill: "#e8989a",
                textDy: 10,
                textWeight: 900
              },
              {
                // 先随便加上一个文件，后期替换
                markerFile: require("./img/waterchangered2.png"),
                markerDx: 0,
                markerDy: 0,
                markerOpacity: 10,
                markerWidth: 50,
                markerHeight: 50
              }
            ]
          }).addTo(layer);
        });
        // 产业园区
        const type3 = res.data.result.type3;
        type3.forEach(item => {
          const type3marker = new maptalks.Marker(item.lngLat, {
            symbol: [
              {
                markerType: "ellipse",
                markerFile: require("./img/garden.png"),
                markerLineWidth: 0,
                markerWidth: 40,
                markerHeight: 40
              },
              {
                textName: item.name,
                textSize: 18,
                textFill: "#f8a710",
                textWeight: 900,
                textDy: 20
              }
            ]
          }).addTo(layer);
        });

        // "莱州湾海上风电集群""牟平海上风电集群""千里岩海上风电集群"
        const type4 = res.data.result.type4;
        type4.forEach(item => {
          const type4marker = new maptalks.Marker(item.lngLat, {
            symbol: [
              {
                markerType: "ellipse",
                markerFile: require("./img/windmill.png"),
                markerLineWidth: 0,
                markerWidth: 100,
                markerHeight: 100
              },
              {
                markerFile: require("./img/windmill.png"),
                markerWidth: 100,
                markerHeight: 100,
                markerDx: -70
              },
              {
                textName: item.name,
                textSize: 18,
                textFill: "#0F81C5",
                textDy: 20,
                textWeight: 900
              }
            ]
          }).addTo(layer);
        });
        layer.hide();
      });
    },

    // 十四五规划的点击按钮
    economy() {
      // debugger
      this.economyLayout.visible = !this.economyLayout.visible;

      // 如果基础地理点击之后，需要显示选择基础地理具体情况的checkbox框，这时十四五规划可能已经显示了，所以需要把十四五规划的框隐藏掉，同理，十四五规划也是这个操作
      if (this.economyLayout.visible == true) {
        this.oceanLayout.visible = false;
        this.particulars = false;
        this.isActive = 9;
        // 规上企业隐藏
        this.enterpriseshow = false;
        // this.$parent.$children[3].project_show = !this.$parent.$children[3]
        // .project_show;
        this.$parent.$children[1].show = true;
        this.$parent.$children[3].project_show = false;
      }

      // economyLayout.visible
      if (!this.changeoceanlayer) {
        this.changecolor();
      }
      // if (this.economyLayout.visible) {
      //   window.$app.layers.base_economy1.show()
      // }else {
      //   window.$app.layers.base_economy1.hide()
      // }
    },
    // 基础地理点击事件
    geographic() {
      this.isActive = 6;
      this.particulars = !this.particulars;
      this.enterpriseshow = false;
      this.$parent.$children[3].isShow.forEach(item => {
        this.$parent.$children[3][item] = false;
      });
      this.$parent.$children[1].show = true;
      // 如果基础地理点击之后，需要显示选择基础地理具体情况的checkbox框，这时十四五规划可能已经显示了，所以需要把十四五规划的框隐藏掉，同理，十四五规划也是这个操作
      if (this.particulars == true) {
        this.economyLayout.visible = false;
        this.oceanLayout.visible = false;
      }
    },

    inputshow() {
      this.$parent.$children[5].refreshShow = !this.$parent.$children[5]
        .refreshShow;
      this.$parent.$children[2].show = !this.$parent.$children[2].show;
    },
    // 请求摄像头
    RequestPort() {
      this.$axios.post(api.requestvideo).then(res => {
        let _r = JSON.parse(res.data);
        const list = _r.data.list;
        list.forEach(item => {
          this.cameraIndexCode.push(item.cameraIndexCode);
          this.videolist.push({
            cameraIndexCode: item.cameraIndexCode,
            cameraName: item.cameraName
          });
        });
      });
    },
    // 摄像头请求
    addData(list) {
      list.forEach(item => {
        this.cameraIndexCode.push(item.cameraIndexCode);
        this.videolist.push({
          cameraIndexCode: item.cameraIndexCode,
          cameraName: item.cameraName
        });
      });
    },

    // 请求列表数据
    LoadVideoList() {
      this.$axios.post(api.videodatalist).then(res => {
        let _r = res.data.result;
        this.videodatalist = _r;
      });
    },
    // 自定义数据
    RequestPort1() {
      let list = [
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea1",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56e2",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea3",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea4",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea5",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港一"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港二"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港三"
        },
        {
          cameraIndexCode: "1f488aa31f8a40ee9458f705132a56ea",
          cameraName: "渔港四"
        }
      ];
      list.forEach(item => {
        this.videolist.push({
          cameraIndexCode: item.cameraIndexCode,
          cameraName: item.cameraName
        });
      });
    },
    // 加载视频
    LoadVideo() {
      // 更改控制视频插件显示隐藏
      this.openvideo = !this.openvideo;
      this.video.visible = !this.video.visible;
      if ((this.openvideo = true)) {
        this.RequestPort();
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs["video-preview"].ready().then(() => {
              this.$refs["video-preview"].handlePreview(this.codes);
              this.$refs["video-preview"].setLayout("5x5");
            });
          });
        }, 500);
      }
      if ((this.openvideo = false)) {
        this.$nextTick(() => {
          this.$refs["video-preview"].uninit();
        });
      }
    },
    // 关闭视频
    closeVideo() {
      // 更改控制视频插件显示隐藏
      // this.openvideo = true;
      this.video.visible = false;
      this.$nextTick(() => {
        this.$refs["video-preview"].uninit();
      });
    },
    VideoBtn(e, index, item) {
      let code = index.camera_index_code;
      this.$refs["video-preview"].handlePreview(code);
    },
    loadEnterpriseType() {
      // this.$axios.post(api.enterprise_type).then(res => {
      //   this.enterprisetype = res.data.result;
      // });
    },

    // 渔港经济区布局的方法
    fishadd() {
      // this.economylayer
    },

    // 加载经济布局地区json 渔港经济区布局的方法
    loadeconomy() {
      this.$axios.post(api.layout).then(res => {
        this.economylist = res.data.result.list;
        var economylist = this.economylist;
        const layer = window.$app.initVectorLayer("economy1", 9);
        this.economylayer = layer;
        var markers = [];
        // 接口只传了一个
        /**********************************************************************************************************
         接口为四个对象[{},{},{},{}]每个对象里面如下，只有中心
          点的经度纬度，和l、s
          需要调用addGeometry方法，创建一个marker对象，通过maptalks的：
          var markers = [],
          markers.push(
            new maptalks.Marker(([x,y]),{
              symbol:{

              }
            })
          )
         layer.addGeometry(markers)


          {
            "coordinates": [120.031589,37.595425],
            "name": "莱州湾渔港经济区",
            "short": 100,
            "long": 200
            }
        **********************************************************************************************************/
        economylist.forEach(item => {
          markers.push(
            new maptalks.Marker(item.coordinates, {
              symbol: [
                {
                  markerType: "ellipse",
                  markerFill: "#fff", //颜色
                  markerFillOpacity: 0.4, //遮罩透明度
                  markerLineColor: "#999999",
                  markerLineWidth: 2,
                  // markerLineDasharray: [2], //线形
                  markerLineOpacity: 1,
                  markerWidth: item.short,
                  markerHeight: item.long,
                  markerDx: 0,
                  markerDy: 0,
                  markerRotation: item.angle,
                  markerOpacity: 1
                },
                {
                  textName: item.name,
                  textSize: 20,
                  textFill: "#CC2C5C"
                }
              ]
            })
          );
        });

        layer.addGeometry(markers);
        layer.hide();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bbox {
  width: 3840px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.option-box {
  height: 100px;
  width: 706px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: auto;
  background-color: rgba(3, 27, 48, 0.5);
  // background: #000d51;
  border-radius: 10px;
  z-index: 11;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.active {
  background: rgba(115, 117, 111, 2.5);
  padding-top: 9px;
  width: 76px;
  height: 71px;
  border-radius: 9px;
}
.noactive {
  background: rgba(115, 117, 111, 0);
}
.classify {
  margin: 10px;
  padding-top: 9px;
  width: 166px;
  height: 71px;
  border-radius: 9px;
  img {
    height: 44px;
    width: 44px;
  }
  .labeltitle {
    font-size: 19px;
    color: aliceblue;
  }
}
.classify:hover {
  cursor: pointer;
}
.labeltitle {
  font-size: 19px;
  color: aliceblue;
}
img {
  height: 44px;
  width: 44px;
}
.geographic:hover {
  cursor: pointer;
}
.geographic {
  cursor: pointer;
  padding-top: 9px;
  width: 186px;
  height: 71px;
  border-radius: 9px;
  margin: 10px;
}
.particularsbox:active {
  background: rgba(115, 117, 111, 2.5);
}

.particularsbox {
  width: 700px;

  // height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  // left: 0px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    // width: 100%;
    // height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;
    .lableClass {
      width: 90px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClass {
      width: calc(100% - 110px);
      height: 100%;
      line-height: 30px;
    }
    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClassTow {
      width: calc(100% - 150px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.particularsboxfourfive {
  width: 611px;

  // height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    // width: 100%;
    // height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;
    .lableClass {
      width: 90px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClass {
      width: calc(100% - 110px);
      height: 100%;
      line-height: 30px;
    }
    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClassTow {
      width: calc(100% - 150px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.boxshow {
  // height: 200px;
  height: 140px;
}
.iconfont {
  color: #fff;

  position: absolute;
  right: 44px;
  bottom: 8px;
}
.boxnoshow {
  height: 77px;
}
.particularsbox2 {
  width: 1138px;
  // height: 100px;

  overflow-y: auto;
  overflow: hidden;
  position: absolute;
  bottom: 100px;
  // left: -328px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    // width: 100%;
    // height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;
    .lableClass {
      width: 90px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClass-enterprisetype {
      width: calc(100% - 206px);
      height: 100%;
      line-height: 30px;
      padding-right: 30px;
      padding-left: 13px;
    }
    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClassTow {
      width: calc(100% - 150px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
// .particularsbox {
//   background: rgb(11, 11, 70);
//   position: fixed;
//   margin: 0 auto;
//   bottom: 120px;
//   width: 500px;
//   height: 30px;
//   border-radius: 44px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.zhcxClass {
  width: 32px;
  height: 32px;
  position: relative;
  /* right: 0px; */
  left: 934px;
  top: -910px;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}

.videobox {
  width: 1600px;
  height: 880px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  // transform: translate(-50%, -50%);
  z-index: 99;
  .video-window {
    width: 1600px;
    height: 100%;
    background-color: #ffffff;

    // background: #000d51;
  }
}

.btn {
  width: 158px;
  height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 20px;
  border: none;
  outline: none;
  background: #143552;
  color: #fff;
  margin: 8px;
  padding-left: 12px;
}
.changevideobtn {
  position: fixed;
  top: 10%;
  left: 20%;
}

.jcdlActive {
  background: rgb(55, 117, 252);
  border-radius: 5px;
}

.colorBz {
  width: 200px;
  font-size: 16px;
  position: absolute;
  left: 653px;
  bottom: 53px;
  z-index: 0;
  text-align: left;
  ul {
    list-style: none;
  }
  li {
    margin-bottom: 5px;
    list-style: none;
    color: #fff;
  }
  i {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
  }
}
.map-colorBz.offset {
  transform: translateX(-850px);
}

.videotab {
  color: #fff;
  font-size: 20px;
  display: block;
  width: 200px;
  height: 100%;
  position: absolute;
  left: -200px;
  top: 0;

  background: #0f3681;
  overflow: hidden;

  // width: 100%;
  // height: 15%;
  // overflow: hidden;
  // display: flex;
  // flex-wrap: wrap;
  // overflow: auto;
  // background: rgb(9, 22, 32);
}
.videotab ::v-deep.el-menu--inline {
  // background: #081658;

  background: #081658;

  color: #fff;
  overflow-y: scroll;
  // overflow: scroll;
  max-height: 600px;
  width: 222px;
  .el-menu-item-group {
    .is-active {
      background: rgba(35, 101, 243, 0.5);
      border-radius: 20px;
    }
  }
}
// 菜单央视
.videotab ::v-deep .menubar .is-active {
  background: rgba(35, 101, 243, 0.5) !important;
}
.videotab ::v-deep .el-submenu {
  background: #0054ca !important;

  color: #fff;
}
.is-opened {
  color: #fff;
}
.videotab ::v-deep .el-submenu__title {
  color: #fff;
  text-align: left !important;
  font-size: 24px;
}
.videotab ::v-deep .el-menu-item {
  color: #fff;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .el-submenu__title {
    text-align: left;
    color: #fff;
    text-align: left;
    span {
      color: #fff;
    }
  }
}
// .chartgather {
//   position: fixed;
//   width: 2000px;
//   height: 600px;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   padding: 0 40px;
//   // margin: 40px;
// }
.chartgather {
  position: absolute;
  top: -770px;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: -770px auto;
  padding: 0 40px;
  // margin: 40px;
}
</style>
<style lang="scss">
.videotab ::v-deep .menubar .is-active {
  background: rgba(35, 101, 243, 0.5) !important;
}
.el-submenu.is-opened .el-submenu__title {
  // background-image: linear-gradient(to right, #1b82db, #20dfe1);
  // background-color: #171d4f;
  background: #0054ca !important;
}
.el-submenu .el-submenu__title {
  // background-image: linear-gradient(to right, #1B82DB, #20DFE1);
  background-color: #000b45 !important;
}
.el-checkbox-group .el-checkbox .el-checkbox__label {
  color: #fff !important;
}
.el-radio-group .el-radio .el-radio__label {
  color: #fff !important;
}
</style>
