<template>
  <div class="trend">
    <p class="title">各年度主要海洋产业产值</p>
    <div id="myChartRTb"></div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "trend",
  data () {
    return {
      name: [],
      value: [],
      myChartRT: "",
      timer: '',
    };
  },
  mounted () {
    this.loadAll();
  },
  beforeDestroy () {
    window.clearInterval(this.timer)
  },
  methods: {
    loadAll () {
      this.$axios.post(api.trend).then(res => {

        let _r = this.truncateArraysToLastSix(res.data.result);
        console.log(_r, 'res.data.resultres.data.result')
        this.name = _r.year;
        // 数据重新组合成小数点后两位
        var arr = _r.value;
        var arr2 = []
        arr.forEach(item => {

          arr2.push(Math.floor(item * 100) / 100)
        })
        this.value = arr2;
        this.drawLine();
      });
    },
    truncateArraysToLastSix (data) {
      if (!Array.isArray(data.year) || !Array.isArray(data.value) || data.year.length !== data.value.length) {
        throw new Error('Invalid data: year and value should be arrays of the same length.');
      }

      const maxLength = 6;
      const truncatedYear = data.year.slice(-maxLength); // 截取最后六个元素  
      const truncatedValue = data.value.slice(-maxLength); // 截取最后六个元素  

      return {
        year: truncatedYear.length === maxLength ? truncatedYear : data.year, // 如果截取后长度是6，则返回截取后的数组，否则返回原数组  
        value: truncatedValue.length === maxLength ? truncatedValue : data.value // 同上  
      };
    },
    //变化趋势 折线图数据渲染

    drawLine () {
      this.myChartRT = this.$echarts.init(
        document.getElementById("myChartRTb")
      );
      var option = {
        tooltip: {
          trigger: "axis",
          formatter: "{a} <br/>{b} : {c}亿元",
          textStyle: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 20
          }
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "13%",
          top: "13%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.name,
          axisLabel: {
            show: true,
            // interval: 0, //显示所有X轴信息
            rotate: 0, //倾斜角度
            textStyle: {
              fontSize: 20,
              color: "#C6E0F2"
            }
          }
        },
        yAxis: {
          name: "亿元",
          nameTextStyle: {
            color: "#fff"
          },
          type: "value",
          axisLabel: {
            show: true,
            // interval: 0, //显示所有X轴信息
            rotate: 0, //倾斜角度
            textStyle: {
              fontSize: 18,
              color: "#C6E0F2"
            }
          }
        },
        series: [
          {
            name: "海洋产值",
            type: "line",
            stack: "Total",
            symbol: "circle", //拐点设置为实心
            symbolSize: 18,
            lineStyle: {
              color: "#00F6FF",
              width: 2
            },
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  // color: "#fff",
                  fontSize: 18 //大小
                }
              }
            },
            itemStyle: {
              normal: {
                color: "#00F6FF"
                // lineStyle: {
                //   color: "#FBA33F",
                // },
              }
            },
            data: this.value,
            // areaStyle: { color: "#D1EEEE" },
            // smooth: true,//控制是否为曲线
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(24,187,157,.3)"
                    },
                    {
                      offset: 1,
                      color: "rgba(24,187,157, 0)"
                    }
                  ],
                  false
                ),
                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                shadowBlur: this.WidthAdaptive(10) //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            }
          }
        ]
      };
      this.myChartRT.setOption(option);
      this.setIn();
    },
    //变化趋势 折线图数据渲染
    WidthAdaptive (res) {
      var windth = window.innerWidth;
      let fontSize = windth / 1920;
      return fontSize * res;
    },
    setIn () {
      let len = 0;
      this.timer = setInterval(() => {
        if (len === this.name.length) {
          len = 0;
        }
        this.myChartRT.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: len
        });
        len++;
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
.trend {
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  // height: calc(31% - 85px);
  height: 30%;
  width: 100%;
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
#myChartRTb {
  width: 560px;
  height: 100%;
  margin-left: 20px;
}
</style>
