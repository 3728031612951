import polygonPattern from "@/util/polygonPattern";
let popupConf = {
    "渔船AIS": {
        label: '',
        title: 'cm',
        pic: '',
        btn: [{
            name: '渔船监管',
            key: 'mmsi',
        }],
        column: [{
                name: 'MMSI',
                key: 'mmsi',
                unit: ''
            },
            {
                name: '船首向',
                key: 'zhx',
                unit: ' °'
            },
            {
                name: '船东',
                key: 'ownerName',
                unit: ''
            },
            {
                name: '联系方式',
                key: 'ownerTel',
                unit: ''
            },
            {
                name: '经度',
                key: 'jd',
                unit: ' °'
            },
            {
                name: '纬度',
                key: 'wd',
                unit: ' °'
            },
            {
                name: '更新时间',
                key: 'gpsdwsj',
                unit: ''
            }
        ]
    },
    "海湾线": {
        label: '',
        title: 'bay_name',
        pic: '',
        btn: [{
            name: '',
            key: 'OID_',
        }],
        column: [{
                name: '包含区域',
                key: 'bay_supp',
                unit: ''
            },
            {
                name: '区级湾长',
                key: 'QJWZ',
                unit: ''
            },
            {
                name: '湾区详情',
                key: 'detail',
                unit: '',
                info: 'full'
            }
        ]
    },
    "海洋牧场": {
        label: '',
        title: 'oriName',
        pic: '',
        btn: [{
            name: '牧场监管',
            key: 'constCom',
        }],
        column: [{
                name: '公司名称',
                key: 'constCom',
                unit: ''
            },
            {
                name: '牧场面积',
                key: 'area',
                unit: ''
            },
            {
                name: '牧场投资',
                key: 'money',
                unit: ''
            },
            {
                name: '牧场备注',
                key: 'remarks',
                unit: '',
                info: 'full'
            }
        ]
    }
};
let other = {
    "卫士": {
        name: 'vedioName',
        key: 'vedioCode',
        btn: '实时监控'
    },
    "海岸线": {
        color: "#e62c1b",
        alhpa: 1,
        btn: [{
            name: '岸线监管',
            key: 'OID_',
        }],
        column: [{
                name: '岸线情况',
                key: 'info',
                unit: '',
            },
            {
                name: '数据来源',
                key: 'source',
                unit: ''
            }
        ]
    },
    "国界": {
        color: "#ffffff",
        alhpa: 0.6,
    },
    "渔区": {
        label: 'Name', //标注字段
        color: "#fff",
        fillAlpha: 0,
        borderColor: '#ffffff',
        textColor: '#ffffff',
        borderAlpha: 0.8
    },
    "海湾标注": {
        label1: 'Name', //标注字段
        label2: 'Name_sup', //标注字段
        textDx1: 0,
        textDy1: -10,
        textDx2: 0,
        textDy2: 10
    },
    "海湾线": [{
            color: "#FF0000",
            key: 'bay_name',
            value: '胶州湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#FF8000",
            key: 'bay_name',
            value: '石雀湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#FFFF00",
            key: 'bay_name',
            value: '唐岛湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#00FFFF",
            key: 'bay_name',
            value: '灵山湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#0000FF",
            key: 'bay_name',
            value: '古镇口湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#FF00FF",
            key: 'bay_name',
            value: '龙湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#808080",
            key: 'bay_name',
            value: '琅琊台湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#FF8080",
            key: 'bay_name',
            value: '黄家塘湾',
            dashArray: [10, 5, 2],
        },
        {
            color: "#FFFFE0",
            key: 'bay_name',
            value: '胡岛湾',
            dashArray: [10, 5, 2],
        }
    ],
    "groupLayers": [{
        name: "海湾线",
        check: false
    }, {
        name: "海湾点",
        check: false
    }, {
        name: "海湾标注",
        check: false
    }],
    "LHJXSymbol": [{
            lineColor: '#ff70ff',
            lineWidth: 2
        },
        {
            markerType: 'ellipse',
            markerWidth: 6,
            markerHeight: 6,
            markerLineColor: '#fff',
            markerFill: '#f91111',
            markerPlacement: 'vertex'
        }
    ],
    "HDSymbol": [{
        lineColor: '#df5af7',
        lineWidth: 1,
    }],
    "BFSymbol": [{
        lineColor: '#000000',
        lineWidth: 2,
    }],

};

let legendConf = {
    "生态红线": [{
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash',
                color: '#CC48A0',
                fill: '#F7C2BB'
            }),
            name: "限制类海洋特别保护区",
            value: "X-HT",
            lineDash: [10, 5, 5],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        },
        {
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash-back-slash',
                color: '#CC48A0',
                fill: '#F4B6B4'
            }),
            name: "禁止类海洋特别保护区",
            value: "J-HT",
            lineDash: [10, 5, 5],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        },
        {
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash',
                color: '#59004E',
                fill: '#FF00C3'
            }),
            name: "砂质岸线与邻近海域",
            value: "X-SZ",
            lineDash: [8, 2, 5],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        }, {
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash',
                color: '#CC48A0',
                fill: '#F7C2BB'
            }),
            name: "限制类海洋自然保护区",
            value: "X-HZ",
            lineDash: [5, 3, 2],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        }, {
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash-back-slash',
                color: '#CC48A0',
                fill: '#F4B6B4'
            }),
            name: "禁止类海洋自然保护区",
            value: "J-HZ",
            lineDash: [6, 5, 2],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        }, {
            fillType: 'bias',
            type: "img",
            src: polygonPattern({
                type: 'slash',
                color: '#CC48A0',
                fill: '#E1C7FF'
            }),
            name: "重要滨海旅游区",
            value: "X-BL",
            lineDash: [9, 6, 5],
            borderColor: '#CC48A0',
            key: 'drawCategory', //读取的key
            alpha: 0.9
        }
    ],
    "布防区域": {
        type: "fill",
        // name: "其他",
        color: "#ff0000",
        borderColor: "#ff0000",
        fillAlpha: "0.2",
        borderAlpha: "0.8",
        lineDash: [5, 2],
        num: 7
    },

    "功能区划": [{
            type: "fill",
            name: "其他",
            value: "",
            color: "#ffffff",
            key: 'areaType', //读取的key
            alpha: 0.5
        },
        {
            type: "fill",
            name: "农渔业区",
            value: "农渔业区",
            color: "#00D9FF",
            key: 'areaType', //读取的key
        },
        {
            type: "fill",
            name: "港口航运区",
            value: "港口航运区",
            color: "#A6F9FF",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "工业与城镇用海区",
            value: "工业与城镇用海区",
            color: "#DD5526",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "矿产与能源区",
            value: "矿产与能源区",
            color: "#FFBB00",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "旅游休闲娱乐区",
            value: "旅游休闲娱乐区",
            color: "#FFA6A6",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "海洋保护区",
            value: "海洋保护区",
            color: "#A7FFA6",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "特殊利用区",
            value: "特殊利用区",
            color: "#8D85B6",
            key: 'areaType',
        },
        {
            type: "fill",
            name: "保留区",
            value: "保留区",
            color: "#6464FF",
            key: 'areaType',
        }
    ],
    "海洋牧场": [{
            type: "fill",
            name: "拟建",
            value: "拟建",
            color: "#ffffff",
            key: 'stage',
            fillAlpha: 0.5
        },
        {
            type: "fill",
            name: "已建",
            value: "已建",
            color: "#00D9FF",
            fillAlpha: 0.5,
            key: 'stage', //读取的key
        }
    ],
    "海域确权": [{
            type: "fill",
            name: "其他",
            value: "",
            color: "#ffffff",
            key: 'use_typeA_', //读取的key
            fillAlpha: 0.5
        },
        {
            type: "fill",
            name: "交通运输用海",
            value: "交通运输用海",
            color: "#F5B6FD",
            key: 'use_typeA_', //读取的key
        },
        {
            type: "fill",
            name: "其他用海",
            value: "其他用海",
            color: "#B6C1FD",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "工业用海",
            value: "工业用海",
            color: "#BDF5FD",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "排污倾倒用海",
            value: "排污倾倒用海",
            color: "#B8FDB6",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "旅游娱乐用海",
            value: "旅游娱乐用海",
            color: "#FDCBBB",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "海底工程用海",
            value: "海底工程用海",
            color: "#FDC8E1",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "渔业用海",
            value: "渔业用海",
            color: "#B6FDDF",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "特殊用海",
            value: "特殊用海",
            color: "#D4E0FD",
            key: 'use_typeA_',
        },
        {
            type: "fill",
            name: "造地工程用海",
            value: "造地工程用海",
            color: "#F0FDD4",
            key: 'use_typeA_',
        }
    ],
    "渔船AIS": [{
        type: "div",
        name: 'AIS',
        value: 'AIS',
        color: '#00ff00'
    }],
    "北斗": [{
        type: "div",
        name: '北斗',
        value: 'Beidou',
        color: '#3366cc'
    }],
    "雷达": [{
        type: "div",
        name: '雷达',
        value: 'Radar',
        color: '#663366'
    }]
};
let distanceToolsymbol = {
    'symbol': {
        'lineColor': '#34495e',
        'lineWidth': 2
    },
    'vertexSymbol': {
        'markerType': 'ellipse',
        'markerFill': '#1bbc9b',
        'markerLineColor': '#000',
        'markerLineWidth': 3,
        'markerWidth': 10,
        'markerHeight': 10
    },

    'labelOptions': {
        'textSymbol': {
            'textFaceName': 'monospace',
            'textFill': '#fff',
            'textLineSpacing': 1,
            'textHorizontalAlignment': 'right',
            'textDx': 15,
            'markerLineColor': '#b4b3b3',
            'markerFill': '#000'
        },
        'boxStyle': {
            'padding': [6, 2],
            'symbol': {
                'markerType': 'square',
                'markerFill': '#000',
                'markerFillOpacity': 0.9,
                'markerLineColor': '#b4b3b3'
            }
        }
    },
    'clearButtonSymbol': [{
        'markerType': 'square',
        'markerFill': '#000',
        'markerLineColor': '#b4b3b3',
        'markerLineWidth': 2,
        'markerWidth': 15,
        'markerHeight': 15,
        'markerDx': 20
    }, {
        'markerType': 'x',
        'markerWidth': 10,
        'markerHeight': 10,
        'markerLineColor': '#fff',
        'markerDx': 20
    }],
    'language': 'en-US'
}

function getStyleFromConf(conf, label) { //style-conf   图上标注label（可以没有）
    debugger
    let style = [];
    let that = this;
    if (!conf) {
        return [];
    }
    if (conf instanceof Array) {
        conf.forEach(function(item) {
            let fillType = item.fillType ? item.fillType : item.type;
            let borderColor = item.borderColor ? item.borderColor : undefined;
            let textColor = item.textColor ? item.textColor : undefined;
            let symbol;
            let borderAlpha = item.borderAlpha ? item.borderAlpha : 0.9;
            let fillAlpha = item.fillAlpha ? item.fillAlpha : 0.9;
            let lineDash = item.lineDash
            if (fillType === 'fill') {
                symbol = getFillSymbol(item.color, borderColor, textColor, false, fillAlpha, borderAlpha, lineDash);
            }
            if (fillType === 'bias') {
                symbol = getPatternSymbol(item.src, item.alpha, false, item.borderColor, item.lineDash);
            }
            style.push({
                'filter': ['==', item.key, item.value],
                'symbol': symbol
            })
        });
    } else {
        let fillType = conf.fillType ? conf.fillType : conf.type;
        let borderColor = conf.borderColor ? conf.borderColor : undefined;
        let textColor = conf.textColor ? conf.textColor : undefined;
        let symbol;
        let borderAlpha = conf.borderAlpha ? conf.borderAlpha : 0.9;
        let fillAlpha = conf.fillAlpha ? conf.fillAlpha : 0.9;
        let num = conf.num ? conf.num : 1;
        let lineDash = conf.lineDash
        if (fillType === 'fill') {
            symbol = getFillSymbol(conf.color, borderColor, textColor, false, fillAlpha, borderAlpha, lineDash);
        }
        if (fillType === 'bias') {
            symbol = getPatternSymbol(conf.src, conf.alpha, false, conf.borderColor, conf.lineDash);
        }
        for (let i = 0; i < num; i++) {
            style.push({ 'symbol': symbol })
        }
    }
    return style;
}

function getPatternSymbol(pattern, fillAlpha, key, lineColor, dash) { //图案base64填充
    let alpha = fillAlpha || 0.8;
    let line = lineColor || '#121212';
    let symbol = [];
    var poly = {
        'polygonOpacity': alpha,
        'polygonPatternFile': pattern,
        lineColor: line,
        'lineWidth': 0.5,
        lineOpacity: 0.8
    };
    if (dash) {
        poly.lineDasharray = dash;
    }
    symbol.push(poly);
    if (key) {
        symbol.push({
            'textName': '{' + key + '}',
            'textSize': 14,
            'textFill': '#fff',
            'textHaloFill': '#101010',
            'textHaloRadius': 2,
            'textHorizontalAlignment': 'middle',
            textWeight: 400,
        });
    }
    return symbol;
}

function getFillSymbol(color, lineColor, textColor, key, fillalpha, borderAlpha, lineDash) {
    let symbol = [];
    borderAlpha = borderAlpha ? borderAlpha : 0.8;
    fillalpha = fillalpha || fillalpha == 0 ? fillalpha : 0.8
    lineColor = lineColor || lineColor == 0 ? lineColor : '#104068';
    textColor = textColor ? textColor : '#FFFFFF';
    lineDash = lineDash ? lineDash : [10, 5, 5]
    symbol.push({
        'polygonFill': color,
        'polygonOpacity': fillalpha,
        'lineColor': lineColor,
        'lineWidth': 0.5,
        lineOpacity: borderAlpha,
        'lineDasharray': lineDash
    });
    if (key && key !== '') {
        symbol.push({
            'textName': '{' + key + '}',
            'textSize': 14,
            'textFill': '#fff',
            'textHaloFill': '#101010',
            'textHaloRadius': 2,
            'textHorizontalAlignment': 'middle',
            textWeight: 400,
        });
    }
    return symbol;
}

function getStyle(type, prop) {
    let conf = legendConf[type];
    let popConf = popupConf[type] || {};
    let label = popConf.label;
    let style = [];
    switch (type) {
        case '功能区划':
        case '生态红线':
        case '海域确权':
        case '保护区':
        case '海洋牧场':
            style = getStyleFromConf(conf, label);
            break;
            // case '海湾线':
            //     conf = other['海湾线'];
            //     style = getLineStyleFromConf(conf);
            //     break;
        case '养殖企业':
        case '苗种企业':
        case '海岛':
        case '涉海企业':
        case '港口':
        case '渔港':
        case '涉海科研机构':
        case '沉积物调查':
        case '水质调查站':
        case '海湾点':
        case '海洋站':
        case '浮标':
        case '兴趣点':
            // case '无人机':
            //     style = getPointStyle(conf);
            //     break;
            // case '入海口卫士':
            // case '渔港卫士':
            // case '岛链卫士':
            // case '岸线卫士':
            //     style = getWSPointStyle(conf, prop);
            break;
        default:
            break;
    }
    return style;
}
// 测量面积工具样式配置
let area = {
    'symbol': {
        'lineColor': '#1bbc9b',
        'lineWidth': 2,
        'polygonFill': '#fff',
        'polygonOpacity': 0.3
    },
    'vertexSymbol': {
        'markerType': 'ellipse',
        'markerFill': '#34495e',
        'markerLineColor': '#1bbc9b',
        'markerLineWidth': 3,
        'markerWidth': 10,
        'markerHeight': 10
    },
    'labelOptions': {
        'textSymbol': {
            'textFaceName': 'monospace',
            'textFill': '#fff',
            'textLineSpacing': 1,
            'textHorizontalAlignment': 'right',
            'textDx': 15
        },
        'boxStyle': {
            'padding': [6, 2],
            'symbol': {
                'markerType': 'square',
                'markerFill': '#000',
                'markerFillOpacity': 0.9,
                'markerLineColor': '#b4b3b3'
            }
        }
    },
    'clearButtonSymbol': [{
        'markerType': 'square',
        'markerFill': '#000',
        'markerLineColor': '#b4b3b3',
        'markerLineWidth': 2,
        'markerWidth': 15,
        'markerHeight': 15,
        'markerDx': 22
    }, {
        'markerType': 'x',
        'markerWidth': 10,
        'markerHeight': 10,
        'markerLineColor': '#fff',
        'markerDx': 22
    }],
    language: ''
}

export { popupConf, other, legendConf, area, distanceToolsymbol, getStyleFromConf, getStyle, getFillSymbol }