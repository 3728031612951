// const baseAdmin = "http://192.168.10.199:6218/ocean-ranch/rest" //测试
// const baseAdmin = "http://192.168.2.176:6218/ocean-ranch/rest" //测试
// const baseAdmin = "http://192.168.137.41:6218/ocean-ranch/rest" //测试
// const baseAdmin = 'http://118.190.202.102:6218/ocean-ranch/rest' // 118服务器
// const baseAdmin = 'http://10.10.10.178:6218/ocean-ranch/rest' // 现场
// const baseAdmin = 'http://localhost:6218/ocean-ranch/rest' // 本地包
const baseAdmin = 'http://ocean-ranch.ytzhhy.cn/ocean-ranch/rest' //汉鑫

const api2 = {
    division_getAll: '/division/getAll' //海区数据
}
const api = {
    picturelayer: baseAdmin + '/pictureLayer/getList',
    loadforce: baseAdmin + '/ranchForce/list', //查询用户关注点
    deleforce: baseAdmin + '/ranchForce/delete', //删除用户关注点

    forcesave: baseAdmin + '/ranchForce/save', //保存用户关注点
    querycompany: baseAdmin + '/economics/picture/company/query', // 按条件查询企业
    fouryear: baseAdmin + '/economics/picture/production/situation', // 主要产业生产情况
    windpower: baseAdmin + '/economics/picture/wind/power', // 风电产业数据
    typeCount: baseAdmin + '/economics/picture/statistics/typeCount', //类型企业数量
    ocean_layout: baseAdmin + '/economics/picture/ocean/layout', // 海洋布局
    layout: baseAdmin + '/economics/picture/economy/layout', // 经济布局

    division_getAll: baseAdmin + "/division/getAll", //海区数据
    getAll_yanTai: baseAdmin + "/division/getAll/yanTai", //市区线和中心点
    shoreline_getAll: baseAdmin + "/shoreline/getAll", //海岸线数据1
    shoreline_getString: baseAdmin + "/shoreline/getString", //海岸线数据2
    requestvideo: baseAdmin + '/vedio/getVedioInfo', //请求海康服务视频
    // requestvideo: baseAdmin + '/vedio/data/list', // 请求本地服务器海康视频列表
    query_enterprise: baseAdmin + '/economics/picture/company/list', // 查询企业信息
    cantonal: baseAdmin + '/economics/picture/statistics/district', //各市区产业产值
    ocean_GDP: baseAdmin + '/economics/picture/statistics/overview', // 海洋经济概况接口
    year_GDP: baseAdmin + '/economics/picture/statistics/output', // 广为产业年度（饼图）
    /*规上企业产值排名，年度经济占比，各区域规上企业数量*/
    enterprise_information: baseAdmin + '/economics/picture/statistics/all',
    //查询港口列表
    fishing_port: baseAdmin + '/economics/picture/port/list',
    // 查询渔船
    fishing_boat: baseAdmin + '/economics/picture/ship/list',
    // 查询企业类型
    enterprise_type: baseAdmin + '/economics/picture/company/types',
    // 单独查询企业列表
    search_enterprise: baseAdmin + '/economics/picture/company/list',
    // 科研机构
    scientific: baseAdmin + '/economics/picture/scientific/list',
    // 广为主要海洋产业产值趋势
    trend: baseAdmin + '/economics/picture/gw/trend',
    videodatalist: baseAdmin + '/vedio/data/list',










    // 用于拼接
    base: baseAdmin,
    // 巡查记录管理
    // 删除巡查记录
    delIslandPatrol: baseAdmin + '/seaIslandPatrol/delete',
    // 添加巡查记录
    addIslandPatrol: baseAdmin + '/seaIslandPatrol/add',
    // 针对巡查记录的分页接口
    SeaIslandPatrol: baseAdmin + '/seaIslandPatrol/page',
    // 巡查记录不分
    law: baseAdmin + '/seaIslandIllegal/page',
    SeaIslandlist: baseAdmin + '/seaIslandPatrol/lists',
    // Island_Controller: baseAdmin + '/rest/ecologicalRedline/all', // 全部生态红线
    island: baseAdmin + '/rest/ecologicalRedline/all',

    // 生态红线
    redline: baseAdmin + '/ecologicalRedline/all',
    indeedRightArea: baseAdmin + '/indeedRightArea/all',
    // 功能区划
    functionarea: baseAdmin + '/rest/functionArea/all',
    // 文件添加返回数据
    addfile: baseAdmin + '/files/add',

    // 静态文件

    HYQQ: './mapjson/HYQQ.json', // 海域确权
    bay: './mapjson/Bay.json', // 海湾点
    baypoint: './mapjson/BayPoint.json', // 海湾点
    baylabel: './mapjson/BayLabel.json', // 海湾点
    coastline: '/mapjson/coastline.json', //禁鱼线
    fishing_area: '/mapjson/fishing-area.json',
    line_json: '/mapjson/line.json',
    ly: '/mapjson/ly.json',
    hy1: '/mapjson/hy1.json', //海洋范围数据线
    agreement_water: '/mapjson/agreement-waters.json',
    LHJX: '/mapjson/LHJX.json', //领海基线
    YQ: '/mapjson/yuqu.json', //渔区
}
export default api
