<!-- 各区域海洋整体数量 -->
<template>
  <div class="aes">
    <!-- <p class="title">各区域规上企业数量</p> -->
    <div id="myChartRB"></div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "aes",
  data() {
    return {
      areaCount: [],
      areaCountvalues:[],
      areaCountKeys:[],
      rankingvalue:[],
      rankingname:[],
    };
  },
  mounted() {
    this.loadAll();
  },
  methods: {
    drawLine() {
      var chartDom = document.getElementById("myChartRB");
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter: "{b}: <br/>{c}家",
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        xAxis: {
          type: "category",
          // offset:-10,

          data: this.areaCountKeys,
          // 文字倾斜角度 // 文字颜色
          axisLabel: {
            interval: 2,
            rotate: 30,
            color: "rgba(255, 255, 255, 1)",
            //  margin: 22,
             
             textStyle: {
                fontSize: 10,
                color: "#fff",
                
              }
          }
        },
        yAxis: {
          name: "家",
          nameTextStyle: {
            color: "#fff",
            fontSize: "12"
          },
          type: "value",
          axisLabel: { color: "rgba(170, 177, 185, 1)" }
        },
        series: [
          {
            name: "2021",
            data: this.areaCountvalues,
            type: "bar",
            barWidth: 8
          },
        ]
      };

      option && myChart.setOption(option);
    },
    loadAll() {
      // this.$axios.post(api.enterprise_information).then(res => {
      //   // console.log(res, "enterpirse_information");
      //   // 各区域规上企业数量
      //   this.areaCount = res.data.result.areaCount;
        
      //   this.areaCount.forEach(item =>{
      //     this.areaCountvalues.push(item.name)
      //     this.areaCountKeys.push(item.value)
      //   })
      //   this.drawLine();
      // });
      const params = new FormData();
      params.append("year", "2020");
      this.$axios.post(api.typeCount).then(res => {
        // this.year_GDP = res.data.result;
        this.areaCountvalues = res.data.result.value;
        this.areaCountKeys = res.data.result.name;

        // console.log(this.formatstring);

        // let arr = this.formatstring;
        // let arr1 = this.formatvalue;
        // this.newArr = arr.map((value, i) => ({ value, name: arr1[i] }));
        // console.log(this.newArr);
        // this.results = res.data.result;
        // 重新组合数据
        // this.formatstring = []
        // console.log(res,'sblaji');
        // res.data.result.forEach(item =>{
        // let formatobj = {}

        //   formatobj['name'] =item.typename
        //   formatobj['value'] =item.value.toString()
        //   // console.log(formatobj);
        //   this.formatstring.push(formatobj)
        // })
        // console.log(this.formatstring);
        this.drawLine();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.aes {
  height: 100%;
  // height: 30%;
  width: 100%;
  // background: url("../../img/titleline.png") no-repeat center;
  // background-size: 93% 100%;
  // height: calc(31% - 85px);
}
#myChartRB {
  width: 100%;
  height: 100%;
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 18px;
  color: #fff;
  margin: 0;
}
</style>
