<template>
  <div class="ocean-gdp">
    <div id="myChartRT">
      <p class="title-a">规上企业列表</p>
      <div class="titlebox">
        <span style="width: 202px;font-size:22px;font-weight:900">企业名称</span>
        <span style="font-size:22px;font-weight:900">所属海洋产业</span>
        <span style="font-size:22px;font-weight:900;padding-left: 9px;">所属区域</span>
      </div>
      <vue-seamless-scroll :data="list" class="seamless-warp">
        <ul class="item">
          <li class="itemli" v-for="(item,index) in list" :key="index">
            
            <span style="font-size:18px;font-weight:900;width:40%" class="title" v-text="item.name"></span>
            <span style="font-size:18px;" class="date" v-text="item.type"></span>
            <span style="width:15%;font-size:18px;" class="date" v-text="item.location"></span>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import api from '@/util/api'
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "ocean-gdp",
  components: {
    //组件
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [
        {
          title: "无缝滚动第一行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第二行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第三行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第四行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第五行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第六行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第七行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第八行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        },
        {
          title: "无缝滚动第九行",
          date: "2017-12-16"
        }
      ],
      list :[],

    };
  },
  mounted() {
    this.loadAll()
  },
  methods: {
    loadAll(){
      this.$axios.post(api.query_enterprise).then(res=>{
        this.list = res.data.result.list 
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ocean-gdp {
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  margin-top: 85px;
  // height: calc(31% - 85px);
  height: 30%;
  width: 100%;
}

  
.seamless-warp {
  height: 214px;
  z-index: 15;
    width: 99%;
    text-align: start;
    padding-left: 10px;
    overflow: hidden;
}
.title-a {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
// .title {
//   // width: calc(100% - 72px);
//   // text-align: start;
//   // color: #fff;
//   // margin: 0;
// }
ul li {
  list-style: none;
}
.titlebox {
  display: flex;
  justify-content: space-around;
  span {
    padding-top: 5px;
    padding-bottom:3px;
    font-size: 18px;
    line-height: 32px;
    height: 32px;
    color: #4795b8;
  }
}
.itemli {
  width: 100%;
  display: flex;
  justify-content: space-around;
  line-height: 40px;
  height: 40px;
  span {
    width: 30%;
    color: #fff;
    overflow: hidden;
    text-emphasis: none;
      line-height: 40px;
  height: 40px;
  }
}
.item{
  padding:0
}
</style>
