<template>
  <div class="chartgather">
    <div class="gathertitle">
      <h3 v-show="changechartshow.one">海洋渔业生产情况</h3>
      <h3 v-show="changechartshow.two">海洋交通运输业生产情况</h3>
      <h3 v-show="changechartshow.three">滨海旅游业服务情况</h3>
    </div>
    <div class="gatherchartbox">
      <div id="seawateryield" class="gaterbox" v-show="changechartshow.one"></div>
      <div id="fishingproduction" class="gaterbox" v-show="changechartshow.one"></div>
      <div id="cargothroughput" class="gaterbox" v-show="changechartshow.two"></div>
      <div id="visitor" class="gaterbox" v-show="changechartshow.three"></div>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "ca-gather",
  data() {
    return {
      // 海水养殖产量
      seawateryield: {},

      //海洋捕捞产量
      fishingproduction: {},

      // 港口货物吞吐量
      cargothroughput: {},

      // 沿海城市接待国内游客人次
      visitor: {},
      // 四个饼图实例
      chartSWY: null,
      chartFP: null,
      chartCTP: null,
      chartV: null,

      // 饼图的options
      options: {},
      changechartshow:{
        one:true,
        two:false,
        three:false
      }
    };
  },
  mounted() {
    this.loadfouryear();
  },
  beforeDestroy() {
    this.$parent.fiveList = 3
  },
  methods: {
    loadfouryear() {
      this.$axios.post(api.fouryear).then(res => {
        this.seawateryield = res.data.result.list[0];
        this.fishingproduction = res.data.result.list[1];
        this.cargothroughput = res.data.result.list[2];
        this.visitor = res.data.result.list[3];
        this.initchart(
          this.seawateryield,
          this.fishingproduction,
          this.cargothroughput,
          this.visitor
        );
      });
    },
    initchart(seaA, seaB, seaC, seaD) {
      console.log(this.seawateryield, "aoligei3");
      this.chartSWY = this.$echarts.init(
        document.getElementById("seawateryield"),
        "light"
      );
      this.chartFP = this.$echarts.init(
        document.getElementById("fishingproduction"),
        "light"
      );
      this.chartCTP = this.$echarts.init(
        document.getElementById("cargothroughput"),
        "light"
      );
      this.chartV = this.$echarts.init(
        document.getElementById("visitor"),
        "light"
      );

      // 定义options
      var options = {
        title: {
          text: "",
          left: 110,
          bottom: 25,
          left: "center",
          textStyle: {
            color: "#fff"
          }
          // textVerticalAlign:'bottom'
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 20
          }
        },
        grid: {
          left: "3%",
          right: "6.1%",
          bottom: "13%",
          top: "13%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            show: true,
            // interval: 0, //显示所有X轴信息
            rotate: 0, //倾斜角度
            textStyle: {
              fontSize: 20,
              color: "#C6E0F2"
            }
          }
        },
        yAxis: {
          name: "亿元",
          nameTextStyle: {
            color: "#fff",
            fontSize: "16"
          },
          type: "value",
          axisLabel: {
            show: true,
            // interval: 0, //显示所有X轴信息
            rotate: 0, //倾斜角度
            textStyle: {
              fontSize: 18,
              color: "#C6E0F2"
            }
          }
        },
        series: [
          {
            name: "海洋产值",
            type: "line",
            stack: "Total",
            symbol: "circle", //拐点设置为实心
            symbolSize: 18,
            lineStyle: {
              color: "#00F6FF",
              width: 2
            },
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  // color: "#fff",
                  fontSize: 18 //大小
                }
              }
            },
            itemStyle: {
              normal: {
                color: "#00F6FF"
                // lineStyle: {
                //   color: "#FBA33F",
                // },
              }
            },
            data: this.value,
            // areaStyle: { color: "#D1EEEE" },
            // smooth: true,//控制是否为曲线
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(24,187,157,.3)"
                    },
                    {
                      offset: 1,
                      color: "rgba(24,187,157, 0)"
                    }
                  ],
                  false
                ),
                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                shadowBlur: this.WidthAdaptive(10) //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            }
          }
        ]
      };
      this.chartSWYop = options;
      this.chartFPop = options;
      this.chartCTPop = options;
      this.chartVop = options;

      this.chartSWYop.yAxis.name = seaA.unit;
      this.chartSWYop.xAxis.data = seaA.year;
      this.chartSWYop.title.text = seaA.name;
      this.chartSWYop.series[0].name = seaA.name;
      this.chartSWYop.series[0].data = seaA.value;
      console.log(seaA.value, "ad");
      this.chartSWY.setOption(this.chartSWYop);

      this.chartFPop.yAxis.name = seaB.unit;
      this.chartFPop.xAxis.data = seaB.year;
      this.chartFPop.title.text = seaB.name;
      this.chartFPop.series[0].name = seaB.name;
      this.chartFPop.series[0].data = seaB.value;
      this.chartFP.setOption(this.chartFPop);

      this.chartCTPop.yAxis.name = seaC.unit;
      this.chartCTPop.xAxis.data = seaC.year;
      this.chartCTPop.title.text = seaC.name;
      this.chartCTPop.series[0].name = seaC.name;
      this.chartCTPop.series[0].data = seaC.value;
      this.chartCTP.setOption(this.chartCTPop);

      this.chartVop.yAxis.name = seaD.unit;
      this.chartVop.xAxis.data = seaD.year;
      this.chartVop.title.text = seaD.name;
      this.chartVop.series[0].name = seaD.name;
      this.chartVop.series[0].data = seaD.value;
      this.chartV.setOption(this.chartVop);
    },
    WidthAdaptive(res) {
      var windth = window.innerWidth;
      let fontSize = windth / 1920;
      return fontSize * res;
    }
  }
};
</script>

<style lang="scss" scoped>
.chartgather {
  position: absolute;
  // width: 2000px;
  // height: 600px;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: -600px auto;
  padding: 0 40px;
  z-index: 16;
  // height: 100%;
  // // height: calc(100% - 30px);
  // width: 100%;
  // background-color: #031D48;
  background: url("../img/chartpg.png");
  background-size: cover;
  // background-size:

  .gathertitle {
    color: #fff;
    height: 3%;
    width: 100%;
    font-size: 28px;
  }
  .gatherchartbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 92%;
    // width: 100%;
    .gaterbox {
      width: 600px;
      padding-right: 20px;
      height: 500px;
    }
  }
}
</style>
