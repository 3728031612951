<template>
  <div class="zhClasss" >
    <transition
      leave-active-class="animate__animated animate__fadeOutRight"
      enter-active-class="animate__animated animate__fadeInRight"
      mode="out-in"
    >
      <div class="refClass" v-show="refreshShow">
        <h2>
          <span>综合查询</span>
          <el-button @click="qxClick()" type="text">X</el-button>
        </h2>
        <!-- <div class="dialogInput">
          <p class="comonstyle">创建时间</p>
          <div class="InputDiv">
            <el-date-picker
              style="width:100%"
              size="small"
              value-format="yyyy-MM-dd"
              v-model="CJTime"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              type="daterange"
              align="right"
            ></el-date-picker>
          </div>
        </div> -->
        <!-- <div class="dialogInput">
            <p>牧场名称</p>
            <div class="InputDiv">
              <el-input style="width:100%" size="small" placeholder="请输入名称" v-model="comprehensive.name"></el-input>
            </div>
          </div> -->
        <div class="dialogInput">
          <p class="comonstyle">所属区域</p>
          <div class="InputDiv">
            <!-- <el-cascader @change="cascaderCheng()" style="width:100%" clearable :options="options" v-model="region" placeholder="请选择区域">
              </el-cascader> -->
            <el-select
              size="small"
              clearable
              style="width: 100%"
              v-model="region"
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
        </div>

        <!-- <div class="dialogInput">
          <p class="comonstyle">缓冲区查询</p>
          <div class="InputDiv">
            <el-select
              size="small"
              clearable
              style="width: 100%"
              v-model="comprehensive.layerId"
              placeholder="请选择图层(图层范围)"
            >
              <el-option
                v-for="item in layerIdList"
                :key="item.layerId"
                :label="item.layerName"
                :value="item.layerId"
              />
            </el-select>
          </div>
        </div> -->
        <div class="dialogInputTow">
          <p class="comonstyle">选择区域</p>
          <div>
            <el-dropdown
              style="width:200px;float:left;"
              type="primary"
              @command="insertArea"
            >
              <el-button style="width:200px" size="mini" type="primary">
                选择区域<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>

              <el-dropdown-menu style="width:200px" slot="dropdown">
                <el-dropdown-item
                  v-for="(item, i) in dropdownList"
                  :command="item.value"
                  :key="i"
                  >{{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              style="width:100%;margin-top:20px;"
              size="small"
              :disabled="true"
              v-model="points"
            ></el-input>
          </div>
        </div>
        <div class="zhBtn">
          <el-button type="primary" @click="refresh()">查询</el-button>
          <el-button @click="changeshow()">取消</el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/util/api";
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";
import { ClusterLayer } from "maptalks.markercluster";
export default {
  data() {
    return {
      refreshShow: false,
      points: "",
      CJTime: "",
      comprehensive: {
        startDay: "",
        endDay: "",
        layerId: "",
        region: "",
        name: "",
        longitudeList: ""
      },
      options: [
        "芝罘区",
        "福山区",
        "莱山区",
        "牟平区",
        "蓬莱区",
        "海阳市",
        "莱阳市",
        "栖霞市",
        "长岛综合实验区",
        "龙口市",
        "招远市",
        "莱州市",
        "开发区",
        "高新区"
      ],
      region: [],
      dropdownList: [
        // {
        //   label: '圆形',
        //   value: 'Circle'
        // },
        {
          label: "矩形",
          value: "Rectangle"
        },
        {
          label: "多边形",
          value: "Polygon"
        }
      ],
      layerIdList: [],
      comLayer: null, //综合管理画圈图层
      clusterLayer: ""
    };
  },
  mounted() {
    this.comLayer = window.$app.comLayer;
  },
  methods: {
    // 综合管理画圈
    insertArea(value) {
      setInterval(() => {
        window.$app.layers.base_comlayer.clear();
      }, 100);
      this.mode = value;
      this.draw();
    },
    draw() {
      const symbol = {
        lineColor: "red",
        lineOpacity: 1,
        lineWidth: 2,
        polygonFill: "white",
        polygonOpacity: 0.3
      };
      let callback = () => {};
      switch (this.mode) {
        // case 'Circle': {
        //   callback = (e) => {
        //     const center = e.geometry.getCenter()
        //     const radius = e.geometry.getRadius()
        //     this.coordinates = [{
        //       r: radius,
        //       x: center.x,
        //       y: center.y
        //     }]
        //     this.params.areaType = '圆形'
        //     this.params.points = JSON.stringify(this.coordinates)

        //     console.log(`回放区域圆心：${center.x},${center.y}，半径：${radius}`)

        //     const geo = this.geometry = window.$app.initGeometry({
        //       className: 'Circle',
        //       params: [
        //         center,
        //         radius,
        //         { symbol }
        //       ]
        //     })

        //     this.layer.clear().addGeometry(geo);
        //     this.$refs['dialog'].zoom('enlarge')
        //   }
        //   break
        // }
        case "Polygon": {
          callback = e => {
            var coordinates = e.geometry.getCoordinates();
            // this.params.areaType = '多边形'
            this.points = JSON.stringify(coordinates[0]);

            console.log(`回放区域坐标点：${this.points}`);

            const geo = (this.geometry = e.geometry.copy());
            window.$app.layers.base_comlayer.clear().addGeometry(geo);
            // this.$refs['dialog'].zoom('enlarge')
          };
          break;
        }
        case "Rectangle": {
          callback = e => {
            var coordinates = e.geometry.getCoordinates();
            // this.params.areaType = '矩形'
            this.points = JSON.stringify(coordinates[0]);
            console.log(`回放区域坐标点：${this.points}`);
            const geo = (this.geometry = e.geometry.copy());
            // this.comLayer.clear().addGeometry(geo);
            // this.$refs['dialog'].zoom('enlarge')
          };
          break;
        }
        default: {
        }
      }
      window.$app.toggleDraw({
        drawend: callback,
        mode: this.mode,
        once: true,
        symbol
      });
    },
    
    changeshow() {
      this.points = "";
      this.refreshShow = !this.refreshShow;
      this.$parent.$children[2].show = !this.$parent.$children[2].show;
      window.$app.map._layers.forEach((item, index) => {
          if (item._id == "cluster") {
            window.$app.map._layers[index].remove();
          }
        });
      // window.$app.getEnterpriseinfomation()
      console.log(this.$parent);
      this.$parent.$children[3].getEnterpriseinfomation()
    },
    qxClick() {
      // this.refreshShow = false;
      // this.$parent.$children[2].show = !this.$parent.$children[2].show;
      // this.$refs.rightshow.show = true;
      this.points = "";
      // this.comLayer.clear();
      this.refreshShow = !this.refreshShow;
      this.$parent.$children[2].show = !this.$parent.$children[2].show;
      window.$app.map._layers.forEach((item, index) => {
          if (item._id == "cluster") {
            window.$app.map._layers[index].remove();
          }
        });
      // window.$app.getEnterpriseinfomation()
      console.log(this.$parent);
      this.$parent.$children[3].getEnterpriseinfomation()
      this.SeClick();
      this.changeshow()
    },
    //综合查询查询条件处理
    refresh() {
      window.$app.layers.base_comlayer.clear();
      let that = this;

      if (this.CJTime) {
        this.comprehensive.startDay = moment(new Date(that.CJTime[0])).format(
          "YYYY-MM-DD"
        );
        this.comprehensive.endDay = moment(new Date(that.CJTime[1])).format(
          "YYYY-MM-DD"
        );
      } else {
        this.comprehensive.startDay = "";
        this.comprehensive.endDay = "";
      }
      this.comprehensive.longitudeList = this.points;

      var param = new FormData();
      // param.append("startDay", this.comprehensive.startDay);
      // param.append("endDay", this.comprehensive.endDay);
      param.append("region", this.region);
      // param.append("name", this.comprehensive.name);
      param.append("longitudeList", this.comprehensive.longitudeList);
      console.log(this.points, "this.points");
      console.log(this.comprehensive.endDay, "this.comprehensive.endDay)");
      console.log(this.comprehensive.endDay, "this.comprehensive.endDay");
      console.log(this.region, "this.comprehensive.region");

      this.$axios.post(api.querycompany , param).then(res => {
        console.log(res);
        window.$app.map._layers.forEach((item, index) => {
          if (item._id == "cluster") {
            window.$app.map._layers[index].remove();
          }
        });
        var epitem = res.data.result.list;
        console.log(epitem, "epitem");
        var markerposition = [];
        epitem.forEach(item => {
          let x = item.longitude;
          let y = item.latitude;
          if (x != null && y != null) {
            let position = {
              latitude: x,
              longitude: y,
              name: item.name,
              type: item.type,
              location: item.location,
              type: item.type,
              operationalActivities: item.operationalActivities
            };
            markerposition.push(position);
          }
        });
        const markers2 = [];
        for (let i = 0; i < markerposition.length; i++) {
          let a = markerposition[i];
          let markerP = new maptalks.Marker([a.latitude, a.longitude], {
            properties: {
              name: a.name,
              type: a.type,
              location: a.location,
              type: a.type,
              longitude: a.longitude,
              latitude: a.latitude,
              operationalActivities: a.operationalActivities
            },
            symbol: [
              {
                markerFile: a.onSale
                  ? require("../middle-dialog/img/Enterprise.png")
                  : require("../middle-dialog/img/Enterprise.png"), //标注点图标????
                markerWidth: 28,
                markerHeight: 40
              },
              {
                textFaceName: "sans-serif",
                textName: a.name, //value from name in geometry's properties
                textWeight: "normal", //'bold', 'bolder'
                textStyle: "normal", //'italic', 'oblique'
                textSize: 16,
                textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
                textFill: "#34495e",
                textOpacity: 1,
                textHaloFill: "#fff",
                textHaloRadius: 2,
                textWrapWidth: null,
                textWrapCharacter: "\n",
                textLineSpacing: 0,
                textDx: 0,
                textDy: 0,
                textHorizontalAlignment: "middle", //left | middle | right | auto
                textVerticalAlignment: "middle", // top | middle | bottom | auto
                textAlign: "center" //left | right | center | auto
              }
            ]
          }).on("mousedown", onClick);
          markers2.push(markerP);
        }
        let clusterLayer = new ClusterLayer("cluster", markers2, {
          noClusterWithOneMarker: true,
          noClusterWithHowMany: 8, //聚合的最小个数
          maxClusterZoom: 15,
          symbol: [
            {
              markerType: "ellipse",
              markerFill: {
                property: "count",
                type: "interval",
                stops: [
                  [0, "rgb(135, 196, 240)"],
                  [9, "#1bbc9b"],
                  [50, "rgb(116, 115, 149)"],
                  [99, "rgb(216, 115, 149)"]
                ]
              },
              markerFillOpacity: 0.7,
              markerLineOpacity: 1,
              markerLineWidth: 1,
              markerLineColor: "#fff",
              markerWidth: {
                property: "count",
                type: "interval",
                stops: [
                  [0, 40],
                  [9, 60],
                  [50, 70],
                  [99, 80]
                ]
              },
              markerHeight: {
                property: "count",
                type: "interval",
                stops: [
                  [0, 40],
                  [9, 60],
                  [50, 70],
                  [99, 80]
                ]
              }
            },
            {
              markerType: "ellipse",
              markerFill: {
                type: "radial",
                colorStops: [
                  [0.0, "rgba(22, 165, 255, 0.7)"],
                  [0.2, "rgba(22, 165, 255, 0.4)"],
                  [0.5, "rgba(22, 165, 255, 0)"],
                  [1.0, "rgba(22, 165, 255, 0)"]
                ]
              },
              markerWidth: 70,
              markerHeight: 70,
              markerLineWidth: 0
            }
          ],

          drawClusterText: true,
          geometryEvents: true,
          single: true
        });
        clusterLayer.config({
          textSymbol: {
            textFaceName: "monospace",
            textSize: 22,
            textSize: 16,
            textFont: null, //same as CanvasRenderingContext2D.font, override textName, textWeight and textStyle
            textFill: "#fff"
          }
        });
        window.$app.map._layers.forEach((item, index) => {
          if (item._id === "cluster") {
            window.$app.map.removeLayer(window.$app.map._layers[index]);
          }
        });
        window.$app.map.addLayer(clusterLayer);
        function onClick(e) {
          e.target.setInfoWindow({
            content: `<div class="popbox" style="text-indent:5px"> <div class='pop_dept_ep'><p><span>企业名称:</span>${e.target.properties.name}</p></div>
                      <div class='pop_dept_ep'><p><span>企业类型:</span>${e.target.properties.type}</p></div>
                      <div class='pop_dept_ep'><p><span>企业位置:</span>${e.target.properties.location}</p></div><div class='pop_dept_ep'><p><span>经营范围:</span>${e.target.properties.operationalActivities}</p><div class='pop_dept_ep'><p><span>企业经度:</span>${e.target.properties.longitude}</p></div><div class='pop_dept_ep'><p><span>企业纬度:</span>${e.target.properties.latitude}</p></div></div></div>`,
            // "" + e.target.properties.name + "",
            width: 150,
            dy: 5,
            autoPan: true,
            custom: false,
            autoOpenOn: "click", //set to null if not to open when clicking on marker
            autoCloseOn: "click"
          });
        }
      });
      // if (this.region.lengt > 0) {
      //   this.comprehensive.region = this.region[this.region.length - 1];
      // } else {
      //   this.comprehensive.region = "";
      // }

      // this.$refs.screenMap.comprehensiveReq();
    },
    SeClick(index, indexTow) {
      // this.bomBtn[index].secondary[indexTow].active =
      //   !this.bomBtn[index].secondary[indexTow].active;
      // var level = [],
      //   type = [];
      // this.bomBtn[3].secondary.forEach((item) => {
      //   if (item.active) {
      //     level.push(item.value);
      //   }
      // });
      // this.bomBtn[2].secondary.forEach((item) => {
      //   if (item.active) {
      //     type.push(item.value);
      //   }
      // });
      this.searchParam.level = this.jbList.join();
      this.searchParam.type = this.lxList.join();
      this.searchParam.region = this.sqList.join();
      this.searchParam.output = this.tzList.join();

      // this.$refs.screenMap.hymcRrq();
    }
  }
};
</script>
<style lang="scss" scoped>
// 综合查询部分
.zhClasss {
  position: absolute;
  top: 90px;
  right: 34px;
  width: 480px;
  height: 930px;
  z-index: 11;
  border-radius: 5px;
  // border: 1px solid red;
  .refClass {
    width: 480px;
    height: 93%;
    background: #0c1a60;
    h2 {
      font-size: 24px;
      height: 80px;
      line-height: 80px;
    }
    .dialogInput {
      width: 90%;
      height: 100px;
      // height: 40px;
      margin-bottom: 10px;
      margin-left: 20px;
      p {
        width: 100%;
        height: 50px;
        text-align: left;
        font-size: 24px;
        // display: inline-block;
      }
      .InputDiv {
        width: 430px;
        height: 50px;
        // display: inline-block;
        position: absolute;
        justify-content: space-around;
      }
    }
    .zhBtn {
      width: 100%;
      height: 50px;
      // display: inline;
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>

<style lang="scss">
// 综合查询部分
.zhClasss {
  position: absolute;
  top: 90px;
  right: 164px;
  width: 480px;
  height: 930px;
  z-index: 999;
  border-radius: 5px;
  // border: 1px solid red;
  .refClass {
    width: 480px;
    height: 95%;
    background: rgba(12, 26, 96, 0.7);
    h2 {
      border-left: 2px solid #ffd200;
      border-bottom: 1px solid #2e4468;
      font-size: 24px;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .dialogInput {
      width: 90%;
      height: 90px;
      // height: 40px;
      margin-bottom: 20px;
      margin-left: 20px;
      padding-left: 10px;
      p {
        width: 100%;
        height: 30px;
        text-align: left;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        // display: inline-block;
      }
      .InputDiv {
        width: 400px;
        height: 50px;
        // display: inline-block;
        position: absolute;
        justify-content: space-around;
      }
    }
    .dialogInputTow {
      width: 90%;
      // height: 80px;
      // height: 40px;
      margin-bottom: 20px;
      margin-left: 20px;
      padding-left: 10px;
      p {
        width: 100%;
        height: 30px;
        text-align: left;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        // display: inline-block;
      }
      .InputDiv {
        width: 400px;
        height: 60px;
        // display: inline-block;
        position: absolute;
        justify-content: space-around;
      }
    }
    .zhBtn {
      width: 100%;
      height: 50px;
      // display: inline;
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
    }
  }
}

// 底部按钮部分
.bottomDiv {
  width: 800px;
  height: 100px;
  // background: #000000;
  background-color: rgba(0, 0, 0, 0.5);
  // opacity: 0.5;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 30px;
  display: flex;
  justify-content: space-around;
  padding: 13px 10px 13px 10px;
  & > div {
    width: 80px;
    height: 100%;
    // padding-top: 5x;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    & > img {
      margin-top: 5px;
      width: 44px;
      height: 44px;
    }
    & > p {
      width: 100%;
      text-align: center;
      height: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
    }
  }
}
.earchIMg {
  width: 123px;
  height: 49px;
  position: absolute;
  left: 840px;
  top: 50px;
  z-index: 800;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.earchIMg:hover {
  transform: scale(1.1);
  transition: all 0.5s ease;
}
// 顶部表头
.TopImg {
  width: 2099px;
  height: 77px;
  background-image: url("/static/img/screen/topBtBg.png");
  background-size: 100% 100%;
  position: absolute;
  margin: auto;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  // line-height: 0px;
  // padding-top: 11px;
  img {
    width: 620px;
    height: 57px;
    position: relative;
    top: -20px;
  }
}
// 右上角按钮
.rightTopDIv {
  width: 300px;
  height: 34px;
  position: absolute;
  right: 319px;
  top: 21px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  div {
    background-image: url("../img/btnBG.png");
    background-size: 100% 100%;
    padding: 2px;
    width: 136px;
    height: 34px;
    line-height: 30px;
    // background: #167fff;
    // opacity: 0.3;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
}
// 综合查询按钮
.zhcxClass {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 680px;
  top: 113px;
  z-index: 999;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}
// 左侧可视化部分
.lfetKsh {
  position: absolute;
  left: 34px;
  top: 86px;
  width: 620px;
  z-index: 999;
}
.RightKsh {
  position: absolute;
  right: 34px;
  top: 86px;
  width: 620px;
  z-index: 999;
}

.redOne {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid red;
}
.greenOne {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid green;
}
.spanredOne {
  color: red;
}
.spangreenOne {
  color: green;
}
.active {
  background-color: rgba(255, 255, 255, 0.2);
  // border: 1px solid #eee;

  p {
    // color: #fff !important;
  }
}
// 二级菜单部分
.secondaryClass {
  width: 90px;
  min-height: 30px;
  // border: 1px solid red;
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: 999;
  background: #ffffff;
  p {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #eee;
    line-height: 30px;
    font-size: 16px;
    color: black !important;
  }
  p:hover {
    background: rgb(189, 214, 252);
    // color: #fff;
  }
  .SeActive {
    background: rgb(116, 169, 250) !important;
    color: #fff !important;
  }
}
.cascaderClass {
  width: 220px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 100px;
  left: 0px;
}
.checkboxDiv {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -10px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;
    .lableClass {
      width: 90px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
    }
    .valueClass {
      width: calc(100% - 110px);
      height: 100%;
      line-height: 30px;
    }
    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassTow {
      width: calc(100% - 200px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.checkboxDivTow {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -360px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;

    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassTow {
      width: calc(100% - 200px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.checkboxDivThere {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -270px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;

    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassTow {
      width: calc(100% - 150px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.checkboxDivFour {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -185px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;

    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassTow {
      width: calc(100% - 150px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.checkboxDivFive {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -100px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;

    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassTow {
      width: calc(100% - 200px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
// 投资
.checkboxDivSix {
  width: 780px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  bottom: 100px;
  left: -445px;
  background-color: rgba(3, 27, 48, 0.9);
  border-radius: 5px;
  //  border: 1px solid #eee;
  box-shadow: rgb(190, 189, 189) 0px 0px 3px 1px; //边框阴影
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 5px;
    justify-content: space-around;

    .lableClassTow {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-right: 5px;
    }
    .valueClassSix {
      width: calc(100% - 200px);
      height: 100%;
      line-height: 30px;
    }
    .jcdlActive {
      background: rgb(55, 117, 252);
      border-radius: 5px;
    }
    .el-checkbox {
      float: left;
      margin-right: 10px;
    }
  }
}
.checkboxClass {
  margin-top: 4px;
}
</style>
<style>
.dialogtitleCheckShow .el-dialog {
  background: #104fa2 !important;
  /* background-image: url("/static/img/screen/bdbj.png");
    background-size: 100% 100%; */
}
.dialogtitleCheckShow .el-dialog__header {
  color: #ffffff !important;
  /* background: #104FA2; */
  background-color: transparent !important;
}
.dialogtitleCheckShow .el-dialog__header .el-dialog__title {
  color: #ffffff !important;
  font-size: 24px !important;
}
.dialogtitleCheckShow .el-dialog__header .el-dialog__close {
  color: #ffffff !important;
  font-size: 36px !important;
}
.dialogtitleCheckShow .el-dialog__body {
  /* background: #104FA2; */
  background-color: transparent !important;
}
.el-dialog__body {
  padding-top: 0px !important;
}
.el-dialog__header {
  background: #f4f5f7 !important;
}
.el-input__inner {
  background: rgba(22, 127, 255, 0.1) !important;
  border: 1px solid #93c0fd !important;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-textarea__inner {
  background: rgba(22, 127, 255, 0.1) !important;
  border: 1px solid #93c0fd !important;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-range-input {
  background: rgba(22, 127, 255, 0.1) !important;
  /* border: 1px solid #93c0fd !important; */
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-select-dropdown__list {
  background: #12306b !important;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-select-dropdown__item.selected {
  /* color: #409EFF; */
  background: #27498f !important;
  font-weight: 700;
  font-size: 13px;
  font-family: Source Han Sans CN;
  color: #ffffff !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #27498f !important;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-select-dropdown {
  background: #12306b !important;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
.el-select-dropdown .popper__arrow {
  border-bottom-color: #12306b !important;
}
.el-picker-panel__body {
  background: #12306b !important;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff !important;
}
</style>
<style lang="scss">
/* 个性化设置 */
.el-popover {
  background: #104fa2 !important ;
  border: none !important;
}
.table th,
.table tr:nth-child(2n) {
  background-color: #113988 !important;
  color: #ffffff !important;
  /* opacity: 0.4; */
  border: none !important;
}
.table th,
.table tr:nth-child(2n + 1) {
  background-color: #104fa2 !important;
  color: #ffffff !important;
  /* opacity: 0.4; */
  border: none !important;
}
// th:hover,
// tr:hover {
//   background-color: #113988;
//   color: #ffffff;
// }
.table tbody tr:hover td {
  background-color: #3e77e9 !important;
  color: #ffffff !important;
}
.table td,
.table th.is-leaf {
  border-bottom: none !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute !important;
  background-color: transparent !important;
  z-index: 1 !important;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.comonstyle {
  color: #fff;
}
</style>

<style>
.el-select-dropdown__item{
  color:#fff !important;
}
</style>

