<!-- 其他海洋经济信息 -->
<template>
  <div class="nom">
    <p class="title">其他海洋经济信息</p>
    <div class="content" >
      <div class="con-list list-1">
        <div class="list-nav">
          <div class="nav-title">海洋经济重点项目</div>
          <div class="nav-text">
            {{proportion[0][0]}}
            <span class="ge">个</span>
          </div>
        </div>
        <div class="list-nav2">
          <div class="nav-title">总投资</div>
          <div class="nav-text">
            {{ proportion[0][1] }}
            <span class="ge">亿元</span>
          </div>
        </div>
        <div class="list-nav3">
          <div class="nav-title">开工项目数</div>
          <div class="nav-text">
            {{ proportion[0][2] }}
            <span class="ge">个</span>
          </div>
        </div>
      </div>
      <div class="con-list list-2">
        <div class="list-nav">
          <div class="nav-title">港口吞吐量</div>
          <div class="nav-text">
            {{ proportion[1][0] }}
            <span class="ge">亿吨</span>
          </div>
        </div>
        <div class="list-nav2">
          <div class="nav-title">集装箱吞吐量</div>
          <div class="nav-text">
            {{ proportion[1][1] }}
            <span class="ge">亿吨</span>
          </div>
        </div>
        <div class="list-nav3">
          <div class="nav-title">科技贡献率</div>
          <div class="nav-text">
            {{ proportion[1][2] }} 
            <span class="ge">%</span>
          </div>
        </div>
      </div>
      <!-- <div class="con-list list-3">
        <div class="list-nav">
          <div class="nav-title">集装箱吞吐量</div>
          <div class="nav-text">
            {{ proportion[2][0] }}
            <span class="ge">个</span>
          </div>
        </div>
        <div class="list-nav2">
          <div class="nav-title">总产值</div>
          <div class="nav-text">
            {{ proportion[2][1] }}
            <span class="ge">亿元</span>
          </div>
        </div>
        <div class="list-nav3">
          <div class="nav-title">同比增加</div>
          <div class="nav-text">
            {{ proportion[2][2] }}
            <span class="ge">%</span>
          </div>
        </div>
      </div> -->
      <div class="con-list list-4">
        <div class="list-nav">
          <div class="nav-title">涉海科研院所和高校</div>
          <div class="nav-text">
            {{ proportion[2][0] }}
            <span class="ge">家</span>
          </div>
        </div>
        <div class="list-nav2">
          <div class="nav-title">海洋类科创平台</div>
          <div class="nav-text">
            {{ proportion[2][1] }}
            <span class="ge">个</span>
          </div>
        </div>
        <div class="list-nav3">
          <div class="nav-title">技术协同创新中心</div>
          <div class="nav-text">
            {{ proportion[2][2] }}
            <span class="ge">个</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";

export default {
  name:'nom',
  data() {
    return {
      // 其他海洋经济信息
      proportion:[[],[],[],[]],
    };
  },
  mounted() {
    this.loadAll();
  },
  methods: {
    loadAll() {
      this.$axios.post(api.enterprise_information).then(res => {
        // 其他海洋经济信息
        this.proportion = res.data.result.proportion;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.nom {
  height: 30%;
  width: 100%;
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  // height: calc(31% - 85px);
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}
.content {
  height: calc(100% - 37px);
  width: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin: 0 auto;
}
.con-list {
  height: 50px;
  width: 100%;
}
.list-1 {
  background: url("./img/firstbg.png") no-repeat;
}
.list-2 {
  background: url("./img/secondbg.png") no-repeat;
}
.list-3 {
  background: url("./img/threebg.png") no-repeat;
}
.list-4 {
  background: url("./img/fourbg.png") no-repeat;
}
.con-list {
  display: flex;
}
.list-nav {
  width: 33.33%;
  height: 100%;
  padding-left: 15px;
}
.list-nav2 {
  width: 30%;
  height: 100%;
  padding-left: 17px;
}
.list-nav3 {
  width: 20%;
  height: 100%;
  white-space: nowrap;
}
.nav-title {
  height: 50%;
  width: 100%;
  font-size: 20px;
  text-align: left;
  color: #fff;
}
.nav-text {
  padding-top: 8px;
  font-size: 22px;
  color: #52befa;
  text-align: left;
}
.ge {
  font-size: 16px;
  color: #52befa;
}
</style>
