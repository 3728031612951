<template>
  <div class="ocean-gdp">
    <p class="title">主要海洋产业产值</p>
    <!-- <div class="option">
      <select name="" id="" v-model="selected">
        <option v-for="(item,index) in datasel" :key="index" class="option_item" :value="item.date"
          >{{item.date}}
        </option>
      </select>
    </div> -->
    <swiper ref="mySwiper"
            :options="swiperOptions"
            class="swiperbox swiper-no-swiping"
            v-if="showSwipper">
      <swiper-slide v-for="(item,index) in resultList"
                    :key="index"
                    class="swiper-slide">
        <div class="gdp-container">
          <div class="container-left">
            <div class="left-pre-image">
              <img src="../../img/pre.png"
                   alt="">
            </div>
            <div class="middle-circle">
              <img src="../../img/numberCircle.png"
                   alt="">
              <div class="middle-circl-title">主要海洋产业总值（亿元）</div>
              <div class="middle-circl-number">
                <div class="middle-circl-number-big">{{item.totalValue.toString().split('.')[0]}}</div>
                <div class="middle-circl-number-small">{{'.'+item.totalValue.toString().split('.')[1]}}</div>
              </div>
            </div>
            <div class="right-pre">
              <img src="../../img/pre.png"
                   alt="">

            </div>
          </div>
          <div class="container-right">
            <div class="container-right-image">
              <img src="../../img/infoContainer.png"
                   alt="">
              <div class="right-info-box">
                <div class="right-info-box-title">
                  海洋经济产值时间
                </div>
                <div class="right-info-box-number">
                  {{year}}
                </div>
                <div class="right-info-box-year">
                  年
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination"
           slot="pagination"></div>
    </swiper>

  </div>
</template>

<script>
import api from "@/util/api";
import { setInterval, clearInterval } from 'timers';
export default {
  name: "ocean-gdp",
  props: {
    year: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
  },

  data () {
    return {
      years: [],
      resultList: [],
      value: "",
      showSwipper: false,

      firstValueshow: true,
      selected: "2020",
      datasel: [
        { date: "2016" },
        { date: "2017" },
        { date: "2018" },
        { date: "2019" },
        { date: "2020" }
      ],
      timer: "",
      timer2: "",
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination"
        },
        loop: false,
        initialSlide: 0,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: true, //可设置数值来指定播放速度
        // speed: 900 // 切换图片速度
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true
        // }
      }
    };
  },
  mounted () {
    this.loadGDP();

  },
  destroyed () {
    window.clearInterval(this.timer, this.timer2);
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper;
    }
  },
  watch: {
    selected (newval, val) {
      if (newval) {
        this.loadGDP();
      }
    },
    index (val) {
      console.log(this.index)
      this.chartLoop()
    },
    year (val) {
      console.log('111')
      this.$refs.mySwiper.swiper.slideTo(this.index)
    }
  },
  methods: {
    chartLoop () {
      this.$refs.mySwiper.swiper.slideTo(this.index)
    },
    formatter: function (num) {
      return num.toFixed(2);
    },
    loadGDP () {
      const params = new FormData();
      params.append("year", "2020");
      this.$axios.post(api.ocean_GDP, params).then(res => {
        const result = res.data.result
        this.resultList = res.data.result
        console.log(this.resultList, 'aaaaaaaaaa')
        console.log(3.14.toString().split('.'))
        this.showSwipper = true
        this.years = this.resultList.map(i => {
          return {
            value: i.year,
            label: i.year
          }
        })
        this.$emit('getYears', this.years)

        this.firstValueshow = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ocean-gdp {
  background: url("../../img/titleline.png") no-repeat center;
  background-size: 93% 100%;
  margin-top: 85px;
  // height: calc(31% - 85px);
  height: 30%;
  width: 100%;
}
.title {
  width: calc(100% - 72px);
  height: 37px;
  line-height: 39px;
  text-align: start;
  padding-left: 72px;
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.option {
  /*用div的样式代替select的样式*/
  position: absolute;
  top: 100px;
  right: 88px;
  width: 85px;
  border: none;
  outline: none;
  background: rgba(5, 24, 74, 0);
  /*border-radius: 5px;*/
  /*盒子阴影修饰作用,自己随意*/
  /* box-shadow: 0 0 5px #ccc;*/
  // border: 1px solid #cccccc;
  border: none;
  cursor: pointer; /*鼠标上移变成小手*/
}
.option select {
  color: #00bcbc;
  background: rgba(5, 24, 74, 0);
  //   /*清除select的边框样式*/
  border: none;
  //   /*清除select聚焦时候的边框颜色*/
  outline: none;
  //   /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 20px;
  line-height: 20px;
  //   /*隐藏select的下拉图标*/
  // appearance: none;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   /*通过padding-left的值让文字居中*/
  padding-left: 20px;
}
/*使用伪类给select添加自己想用的图标*/
.option:after {
  content: "";
  width: 14px;
  height: 8px;
  /*通过定位将图标放在合适的位置*/
  position: absolute;
  right: 20px;
  top: 41%;
  /*给自定义的图标实现点击下来功能*/
  pointer-events: none;
}
.option_item {
  background: rgba(5, 24, 74, 1) !important;
  color: rgb(255, 255, 255) !important;
}
.swiper-container {
  height: 100%;
}
.swiper-pagination {
  position: absolute;
  top: 264px;
}
.gdp-container {
  color: #fff;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 47px;
  padding-right: 47px;
  .container-left {
    width: 45%;
    height: 70%;
    display: flex;
    position: relative;
    .left-pre-image {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .middle-circle {
      width: 70%;
      height: 100%;
      position: absolute;
      z-index: 100;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      img {
        width: 100%;
        height: 90%;
        object-fit: fill;
      }
      .middle-circl-title {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        font-size: 13px;
        color: #d9e6ff;
        opacity: 0.7;
      }
      .middle-circl-number {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 40px;
        transform: translate(-50%, -65%);
        display: flex;
        align-items: center;
        .middle-circl-number-big {
          font-size: 40px;
          font-weight: bold;
        }
        .middle-circl-number-small {
          font-size: 16px;
          font-weight: bold;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 8px;
        }
      }
    }
    .right-pre {
      width: 50%;
      height: 100%;
      transform: rotateY(180deg);
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .container-right {
    width: 50%;
    position: relative;
    .container-right-image {
      width: 100%;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .right-info-box {
      width: 100%;
      position: absolute;
      display: flex;
      position: absolute;
      justify-content: flex-end;
      padding-right: 22px;
      left: 50%;
      top: 50%;
      align-items: center;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      .right-info-box-title {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
      .right-info-box-number {
        font-weight: bold;
        font-size: 26px;
        color: #27d4ff;
        margin-left: 37px;
        margin-right: 6px;
      }
      .right-info-box-year {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        opacity: 0.6;
      }
    }
  }
}
</style>
