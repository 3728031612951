const baseLayer = {
    image: {
        'urlTemplate': 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        'subdomains': ['a', 'b', 'c']
    },
    sea: {
        'urlTemplate': 'http://www.oceanread.com:213/arcgis/rest/services/chart/chartAll/MapServer/tile/{z}/{y}/{x}',
    },
    low: {
        'subdomains': ["0", "1", "2", "3", "4", "5", "6", "7"],
        'urlTemplate': "https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0c005ba20f7255ad23ebd218a6f2acdd",
    },
    deep: {
        'subdomains': ["a", "b", "c", "d"],
        'urlTemplate': "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    }
}

export {
    baseLayer
}
